import React from 'react'

const ReviewProductLeft = ({ title, Image, productName }) => {
    return (
        <div className="product-item-show">
            <div className="card border-0 product product-simple">
                <div className="product-simple-top bg-lighter-primary shadow-none">
                    <div className="mb-4">
                        <h5 className="fw-medium">{title}</h5>
                    </div>
                    <div className="product-item-view" id="product-item-view">
                        <div>
                            <div className="d-flex justify-content-end">
                                <button type="button" className="btn" data-toggle="modal" data-target="#product-item-show-modal">
                                    <span className="sprite-icon icon-full-screen w-30p"></span>
                                </button>
                            </div>
                            <div className="img-holder">
                                <img src={Image} alt="product" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                    <div className="my-4">
                        <p className="fw-medium text-center">{productName}</p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ReviewProductLeft
