import React, { useState } from 'react'
import ReviewPayAcountDetails from './ReviewPayAcountDetails'
import ReviewPayAccountAddress from './ReviewPayAccountAddress'
import ReviewPayAccountPaymentCard from './ReviewPayAccountPaymentCard'
import SelfTransport from '../order/SelfTransport'
import { Link } from 'react-router-dom'

const ReviewPayComponent = ({userAddress, name, address, amount, lastdigit, contact, cardName,setSelfTransport }) => {
    // const {} = userAddress;
    const[isChecked,setIsChecked] = useState(false)
    return (
        <>
            <ReviewPayAcountDetails userAddress={userAddress} name={name} contact={contact} />
            <ReviewPayAccountAddress userAddress={userAddress} name={name} contact={contact} address={address} />
            <SelfTransport isChecked={isChecked}/>
            <ReviewPayAccountPaymentCard lastdigit={lastdigit} cardName={cardName} amount={amount} />

            <div className="modal" id="self-transport-modal" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body px-4 pb-4">
              <div className="top-right">
                <button
                  type="button"
                  className="btn-close size-50px"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="my-3 text-center">
                <h6 className="fw-medium text-light-success my-3">
                  Opt For Self-Transport
                </h6>
                <p>
                {isChecked?"Are you sure, You don't want transportation facilities.":"Are you sure, You want to arrange your own transportation."}
                </p>
              </div>
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button
                    data-bs-dismiss="modal"
                    className="btn btn-light-gray px-3 rounded-0 bg-lighter-gray text-gray border-lighter-gray"
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-auto">
                  <Link
                    to={`/review-pay?addressId=${address.addressId}`}
                    onClick={()=>{
                        setSelfTransport(!isChecked)
                        setIsChecked(!isChecked)}}
                    data-bs-dismiss="modal"
                    className="btn btn-light-success px-3 rounded-0 bg-lighter-success text-success border-lighter-success"
                  >
                    Agree
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}   

export default ReviewPayComponent
