import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import CardComponent from "./cardComponent";
import {
  productListBySubCategory,
  productListBySubCategoryWithoutLoader,
} from "../../store/actions/product/productListAction";
import Loader from "../../components/loader";
import { Link } from "react-router-dom";
import SortLowToHigh from "./sortLowToHigh";
import ReactPaginate from "react-paginate";
import LargeCardComponent from "./LargeCardComponent";
import { addToCartAction } from "../../store/actions/cartAction";
import Swal from "sweetalert2";
import { noImageFound } from "../reuse/notFound";
import { isAuth, isUser } from "../../utils/auth";
import { bookmarkProductAction } from "../../store/actions/product/productAction";
import { useToasts } from "react-toast-notifications";
import ShopByBrand from "../homePage/ShopByBrand";
import ShopByBrandFilter from "../homePage/ShopByBrandFilter";
import ShopBySubCategoryFilter from "../homePage/ShopBySubCategoryFilter";
import { array } from "prop-types";

const COUNT_PER_PAGE = 9;

const ProductListComponent = ({
  selectedBrand,
  selectedSubCatFilter,
  setSelectedBrand,
  priceRange,
  selectedSubCategory,
  selectedSubSubCategory,
  specificFilterList,
  setSpecificFilterList,
  selectedBrandFilter,
  selectedHeaderValue ,
                     
                     selectedHeaderTitle,
}) => {


  const dispatch = useDispatch();
  const location = useLocation();
  const loading = useSelector((state) => state.products.isLoading);
  const [isLoading, setIsLoading] = useState(true);
  const [productList, setProductList] = useState([]);

  // console.log("selectedBrand--------", selectedBrand);
  const products = useSelector((state) => state.products.products);
  const [searchQuery, setSearchQuery] = useState(location.search);
  const query = new URLSearchParams(location.search);
  const categoryId = query.get("category");
  const categoryName = query.get("catname");
  const subCategoryId = query.get("subCategory");
  const sub2CategoryId=query.get("sub2Category")
  const subCategoryName = query.get("subcatname");
  const Sub2catname=query.get("Sub2catname")

  let brandId = query.get("brand");
  const brandName = query.get("brandName");
  const priceStart = query.get("priceStart");
  const priceLimit = query.get("priceLimit");
  const [priceSorter, setPriceSorter] = useState(1);
  const [showLargeComponent, setShowLargeComponent] = useState(false);
  const [showSmallComponent, setShowSmallComponent] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const totalRecords = useSelector((state) => state.products.count);
  const authed = isAuth();
  const { addToast } = useToasts();
  const [brandAllIdList, setBrandAllIdList] = useState([]);
  const [sub1AllIdList, setSub1AllIdList] = useState([]);
  const [sub2AllIdList, setSub2AllIdList] = useState([]);

  useEffect(() => {
    if (specificFilterList) {
      var brandArr = [];
      var sub1Arr = [];
      var sub2Arr = [];
      specificFilterList.map((brand) => {
        brandArr.push(brand.brand?.brandId);
        sub1Arr.push(brand.subcategory?.categoryid);
        sub2Arr.push(brand.sub2category?.categoryid);
      });
      setSub1AllIdList(sub1Arr);
      setBrandAllIdList(brandArr);
      setSub2AllIdList(sub2Arr);
    }
  }, [specificFilterList]);

  const onClickHandler = () => {
    setShowSmallComponent(!showSmallComponent);
    setShowLargeComponent(!showLargeComponent);
  };

  useEffect(() => {
    setIsLoading(loading);
    setProductList(products);
    setTotalPages(Math.ceil(totalRecords / COUNT_PER_PAGE));
    setSearchQuery(location.search);
  }, [loading, products, totalRecords, productList, searchQuery, location]);

  const Alert = (title, icon, path) => {
    Swal.fire({
      title: title,
      text: "",
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Continue",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
      } else {
      }
    });
  };

  const addProductToCart = (product) => {
    // console.log(productVariant)

    const obj = {
      productId: product.productId,
      variantId: product.variants?.[0].variantId,
      quantity: 1,
      productName: product.title,
      productImage: product.imageUrls[0]?.url,
      variant1: product.variants?.[0].variantId,
      skucode: product.variants?.[0].skucode,
      price: product.price,
      slug: "slug",
      mrp: product.price,
    };

    dispatch(addToCartAction(obj)).then(
      (resolve) => {
        Alert("Product added to your cart", "success");
      },
      (reject) => Alert("Something went wrong, please try later", "error")
    );
  };

  const onSortChange = (event) => {
    setPriceSorter(parseInt(event.target.value));
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // useEffect(() => {
  //     setIsLoading(loading)
  //     setProductList(products)
  //     console.log(products)

  // }, [loading, products, categoryId, searchQuery])

  let requestNewObj = {
    count: pageNumber * COUNT_PER_PAGE,
    limit: COUNT_PER_PAGE,
    field: "max",

    filters: {
      "category.categoryid": categoryId,
      "subCategory.categoryid": subCategoryId,
      "brand.brandid": selectedBrand.length > 0 ? selectedBrand : brandId,
      ...(priceRange && {
        range: { price: { min: priceRange[0], max: priceRange[1] } },
      }),
    },
    sortBy: {
      type: "price",
      order: priceSorter,
    },
  };
   // console.log("selectedSubCatFilter==selectedSubCatFilter=============",categoryId===null)
  useEffect(() => {
    // let requestObj = {
    //   count: pageNumber * COUNT_PER_PAGE,
    //   limit: COUNT_PER_PAGE,
    //   field: "max",
    //   filters: {
    //     "category.categoryid": categoryId!=="null"?categoryId:null,
    //      "subCategory.categoryid": selectedSubCategory.length>0?selectedSubCategory:"",
    //     "brand.brandid":  selectedBrand.length>0?selectedBrand:brandId,
    //     ...(priceRange &&
    //         {
    //         range: { price: { min: priceRange[0], max: priceRange[1] } },
    //       }),

    //   },
    //   sortBy: {
    //     type: "price",
    //     order: priceSorter,
    //   },
    // };

    let requestObj = {};
   
    if (categoryName ) {
 
      requestObj = {
        count: pageNumber * COUNT_PER_PAGE,
        limit: COUNT_PER_PAGE,
        field: "max",
        key: "subcategory",

        ...(brandAllIdList &&
          brandAllIdList.length > 0 && { brand: brandAllIdList }),
        ...(selectedBrand &&
          selectedBrand.length > 0 && { brand: selectedBrand }),

        ...(sub1AllIdList &&
          sub1AllIdList.length > 0 && { subCategory: sub1AllIdList }),
        ...(selectedSubCatFilter &&
          selectedSubCatFilter.length > 0 && {
            subCategory: selectedSubCatFilter,
          }),
        ...(selectedSubCategory.length === 0
          ? null
          : { subCategory: selectedSubCategory }),
        ...(selectedBrandFilter &&
          selectedBrandFilter.length > 0 && { brand: selectedBrandFilter }),

        category: categoryId === null ? null : [categoryId],
        price: {
          min: priceRange[0],
          max: priceRange[1],
        },
        sortBy: {
          type: "price",
          order: priceSorter,
        },
      };
    }
     
    if (subCategoryName) {
      
      requestObj = {
        count: pageNumber * COUNT_PER_PAGE,
        limit: COUNT_PER_PAGE,
        field: "max",
        key: "SUbSUb CATEgory",
        ...(sub2AllIdList &&
          sub2AllIdList.length > 0 && { sub2Category: sub2AllIdList }),
          ...(selectedBrand &&
            selectedBrand.length > 0 && { brand: selectedBrand }),
          ...(selectedBrandFilter &&
            selectedBrandFilter.length > 0 && { brand: selectedBrandFilter }),
        // ...(brandAllIdList &&
        //   brandAllIdList.length > 0 && { brand: brandAllIdList }),

       
        ...(selectedSubSubCategory.length === 0
          ? null
          : { sub2Category: selectedSubSubCategory }),
        subCategory: subCategoryId === null ? null : [subCategoryId],

        price: {
          min: priceRange[0],
          max: priceRange[1],
        },
        sortBy: {
          type: "price",
          order: priceSorter,
        },
      };
    }
    // sub2cat-----
    console.log(" selectedHeaderValue===============",selectedHeaderValue)
  console.log(" selectedHeaderTitle===============",selectedHeaderTitle)
    if (Sub2catname) {
      console.log("selected header inside ",selectedHeaderValue)
      requestObj = {
        count: pageNumber * COUNT_PER_PAGE,
        limit: COUNT_PER_PAGE,
        field: "max",
        key: "SUb2 00000000CATEgory",
     
          ...(selectedBrand &&
            selectedBrand.length > 0 && { brand: selectedBrand }),
          ...(selectedBrandFilter &&
            selectedBrandFilter.length > 0 && { brand: selectedBrandFilter }),
        ...(brandAllIdList &&
          brandAllIdList.length > 0 && { brand: brandAllIdList }),

       
       ...(selectedHeaderTitle.length>0 &&{title:selectedHeaderTitle}),
       ...(selectedHeaderValue.length>0 && {value:selectedHeaderValue}),
    
        sub2Category: sub2CategoryId === null ? null : [sub2CategoryId],

        price: {
          min: priceRange[0],
          max: priceRange[1],
        },
        sortBy: {
          type: "price",
          order: priceSorter,
        },
      };
    }


    //   requestObj={
    //   count: pageNumber * COUNT_PER_PAGE,
    //   limit: COUNT_PER_PAGE,
    //   field: "max",
    //   kay:"kkkkkkkkkkkkkkkk",
    //    ...(selectedBrand.length===0?null: {brand: selectedBrand}),
    //   ...(selectedBrandFilter.length===0?null:{brand:selectedBrandFilter}),
    //     // brand: selectedBrand.length===0?null:selectedBrand,
    //     // brand:selectedBrandFilter.length===0?null:selectedBrandFilter,
    //     // brand:brandId===null?null:[brandId],
    //      category:categoryId===null?null: [categoryId],
    //      ...(selectedSubCatFilter.length===0?null:{subCategory:selectedSubCatFilter}),
    //     ...(selectedSubCategory.length===0?null:{subCategory:selectedSubCategory}),

    //     subCategory:subCategoryId===null?null:[subCategoryId],
    //     // subCategory: selectedSubCategory.length===0?null:selectedSubCategory,
    //     // sub2Category: ["2BJ7ZhicpJ5AAvncjU1VLQZKZKOVNWGX"],
    //     ...(selectedSubSubCategory.length===0?null:{sub2Category:selectedSubSubCategory}),
    //     price: {
    //         "min":priceRange[0],
    //         "max": priceRange[1]
    //     },
    //     sortBy: {
    //           type: "price",
    //           order: priceSorter,
    //         },

    // }

    dispatch(productListBySubCategory(requestObj));
  }, [
    dispatch,
    pageNumber,
    categoryId,
    subCategoryId,
    priceStart,
    priceLimit,
    priceSorter,
    brandId,
    selectedBrand,
    priceRange,
    selectedSubCategory,
    selectedBrandFilter,
    selectedSubCatFilter,
    selectedSubSubCategory,
    sub1AllIdList,
    brandAllIdList,
    selectedHeaderValue,
    selectedHeaderTitle,
  ]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="col-xl-9 filter-pro-right">
      <div className="row">
        <div className="col-lg-6 align-items-center">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>

              {categoryName && categoryName !== "null" ? (
                <li className="breadcrumb-item text-capitalize">
                  <Link to="">{categoryName}</Link>
                </li>
              ) : null}

              {subCategoryName && subCategoryName.length > 0 ? (
                <li
                  className="breadcrumb-item active text-capitalize"
                  aria-current="page"
                >
                  {subCategoryName}
                </li>
              ) : null}

              {brandName && brandName.length ? (
                <li
                  className="breadcrumb-item active text-capitalize"
                  aria-current="page"
                >
                  {brandName}
                </li>
              ) : null}
            </ol>
          </nav>
        </div>

        <div className="col-lg-6 justify-content-end d-flex align-self-center">
          <SortLowToHigh
            setShowSmallComponent={setShowSmallComponent}
            setShowLargeComponent={setShowLargeComponent}
            showLargeComponent={showLargeComponent}
            showSmallComponent={showSmallComponent}
            onClickHandler={onClickHandler}
            onSortChange={onSortChange}
            priceSorterValue={priceSorter}
          />
        </div>
      </div>

      <div
        className="row"
        style={{ display: showSmallComponent ? true : "none" }}
      >
        {productList.length === 0 ? (
          <div
            className="text-info mt-5 text-center"
            style={{ fontSize: "1rem" }}
          >
            Sorry, No products found Under your search , try changing your
            Search Query
          </div>
        ) : (
          productList.map((product, index) => {
            function isWishlist() {
              const user = isUser();
              return authed
                ? product.bookmarkedBy?.includes(user.userId)
                : authed;
            }

            function addToWishlist() {
              const wishlistObj = {
                productId: product.productId,
                variantId: product.variants[0].variantId,
              };
              if (authed) {
                dispatch(bookmarkProductAction(wishlistObj)).then((result) => {
                  if (result.message === "bookmarked") {
                    addToast("Prodcut Added in Save for Later", {
                      appearance: "success",
                    });
                  } else {
                    addToast("Prodcut Removed from Save for Later", {
                      appearance: "error",
                    });
                  }
                  dispatch(
                    productListBySubCategoryWithoutLoader(requestNewObj)
                  );
                });
              } else {
                addToast("Please Login", { appearance: "error" });
              }
            }

            return (
              <>
                <CardComponent
                  key={index}
                  Image={
                    product.imageUrls?.length > 0
                      ? product.imageUrls[0].url
                      : noImageFound()
                  }
                  productName={product.title}
                  price={product.variants[0]?.price}
                  rating={4.2}
                  product={product}
                  addProductToCart={addProductToCart}
                  isWishlist={isWishlist}
                  addToWishlist={addToWishlist}
                />
              </>
            );
          })
        )}
      </div>

      {productList.length > 30 ? (
        <ReactPaginate
          onPageChange={changePage}
          pageCount={totalPages}
          containerClassName={"paginationButtons"}
          activeClassName={"paginationActive"}
          forcePage={pageNumber}
        />
      ) : null}

      {showLargeComponent ? (
        <div style={{ display: showLargeComponent ? true : "none" }}>
          {productList.map((product) => (
            <LargeCardComponent
              Image={
                product.imageUrls &&
                product.imageUrls[0] &&
                product.imageUrls[0].url
              }
              productName={product.title}
              price={product.price}
              rating={4.2}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default ProductListComponent;
