import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
// import OrderSummaryItems from "../components/product/OrderSummaryItems";
import OrderSummaryComponent from "../components/product/OrderSummaryComponent";
import * as Yup from "yup";
import Header from "../components/homePage/Header";
import Footer from "../components/homePage/Footer";
let formValidation = Yup.object().shape({
  waresheetName: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Warehouse Name is required"),

  description: Yup.string()
    .min(3, "Too Short!")
    .max(30, "Too Long!")
    .required("Warehouse Name is required"),
  user_name: Yup.string().required("User Name is required"),
  contact: Yup.string().required("Phone Number is required"),
  email: Yup.string().email("Invalid Email").required("Email is required"),
  address: Yup.string()
    .required("Address is required")
    .min(10, "Address is too short"),
  city: Yup.string().required("City is required"),
  district: Yup.string().required("District is required"),
  country: Yup.string().required("Country is required"),
  pincode: Yup.string().required("Pincode is required"),
});
const ShippingAddress = () => {
  return (
    <>
      <Header />

      <Formik
        initialValues={{
          user_name: "",
          contact: "",
          email: "",
          address: "",
          city: "",
          district: "",
          country: "",
          pincode: "",
        }}
        validationSchema={formValidation}
        onSubmit={(fields) => {
          // console.log("fields===>", fields);
        }}
        render={({ errors, status, touched }) => (
          <>
            <main className="page-wrapper">
              <section className="pt-3">
                <div className="container px-sm-5">
                  <div className="row">
                    <div className="col-xl-8 col-lg-7 pt-3">
                      <div className="mb-3">
                        <h5 className="fw-medium">Enter 111Shipping Details</h5>
                      </div>
                      <Form className="card-shipping">
                        <div className="form-group mb-3">
                          <label
                            htmlFor="my-input"
                            className="mb-1 text-light-gray"
                          >
                            Full Name*
                          </label>
                          {/* <input id="my-input" className="form-control" type="text" name="user_name" /> */}
                          <Field
                            name="user_name"
                            type="text"
                            className={
                              "form-control" +
                              (errors.user_name && touched.user_name
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="user_name"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="my-input"
                                className="mb-1 text-light-gray"
                              >
                                Phone Number*
                              </label>
                              {/* <input id="my-input" className="form-control" type="tel" name="contact" /> */}
                              <Field
                                name="contact"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.contact && touched.contact
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="contact"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="my-input"
                                className="mb-1 text-light-gray"
                              >
                                Email Address*
                              </label>

                              <Field
                                name="email"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label
                            htmlFor="my-input"
                            className="mb-1 text-light-gray"
                          >
                            Address*
                          </label>

                          <Field
                            name="address"
                            type="text"
                            className={
                              "form-control" +
                              (errors.address && touched.address
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="my-input"
                                className="mb-1 text-light-gray"
                              >
                                City*
                              </label>

                              <Field
                                name="city"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.city && touched.city
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="city"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="my-input"
                                className="mb-1 text-light-gray"
                              >
                                District*
                              </label>
                              <Field
                                name="district"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.district && touched.district
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="district"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="my-input"
                                className="mb-1 text-light-gray"
                              >
                                Country*
                              </label>

                              <Field
                                name="country"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.country && touched.country
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="country"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="my-input"
                                className="mb-1 text-light-gray"
                              >
                                Pincode*
                              </label>

                              <Field
                                name="pincode"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.pincode && touched.pincode
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="pincode"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-check common-checkbox">
                          <input
                            className="form-check-input common-checkbox-input"
                            type="checkbox"
                            value=""
                            id="flexCheckChecked"
                            hidden=""
                            checked
                            name="billing_and_shipping"
                          />
                          <label
                            className="form-check-label common-checkbox-label ps-2"
                            htmlFor="flexCheckChecked"
                          >
                            My Billing Address is same as Shipping Address
                          </label>
                        </div>
                      </Form>
                    </div>
                    <div className="col-xl-4 col-lg-5 pt-3">
                      <div className="card shadow border-0  cart-box py-4 px-2">
                        <div className="card-body">
                          <h6 className="fw-medium">Order Summary</h6>

                          <OrderSummaryComponent products={[1, 2]} />

                          <div className="d-grid">
                            {/* <button
                            className="btn btn-light-success py-2 rounded-0"
                            type="submit"
                            data-bs-toggle="modal" data-bs-target="#order-placed-success-modal"
                          >
                            Continue
                          </button> */}
                            <Link
                              to="/review-pay"
                              className="btn btn-light-success py-2 rounded-0"
                            >
                              Continue
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>

            <div
              className="modal"
              id="order-placed-success-modal"
              tabindex="-1"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body px-4 pb-4">
                    <div className="close">
                      <button
                        type="button"
                        className="btn-close size-50px"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="my-3 pt-3 text-center">
                      <div className="d-flex justify-content-center">
                        <div className="img-holder size-80px">
                          <img
                            className="img-fluid w-100"
                            src="images/icons/checked-circle.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <h4 className="fw-medium text-light-success my-3">
                        Yayy! Order Placed.
                      </h4>
                      <p>
                        Lorem ipsum, or lipsum as it is sometimes known, is
                        dummy text used in laying out print, graphic or web
                        designs Lorem ipsum, or lipsum as it is sometimes known,
                        is Click Here to explore more products .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      />

      <Footer />
    </>
  );
};

export default ShippingAddress;
