import React, { useEffect, useState } from "react";
import ReactStars from "react-stars";
import Swal from "sweetalert2";
import axiosInstance from "../../api/axiosInstance";
import AmountFormat from "../amountFormat";
import { noImageFound } from "../reuse/notFound";

const CartBox = ({ order, product }) => {
  // console.log("order-->", order, product);
  const [reviewHeading, setReviewHeading] = useState("");
  const [reviewContent, setReviewContent] = useState("");
  const [rating, setRating] = useState(0);
  const [userId, setUserId] = useState("");

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setUserId(userData._id);
    }
  }, []);
  // console.log("userData",userId)

  const onSubmit = async () => {
    const reqObj = {
      userId: userId,
      productId: product._id,
      orderId: order._id,
      rating: rating,
      reviewHeading: reviewHeading,
      reviewContent: reviewContent,
      status: true,
    };
    try {
      const response = await axiosInstance.post("/review", reqObj);
      const result = JSON.parse(response.data);
      // console.log("result=>",result)
      if (result.code === 200) {
        setReviewHeading("");
        setReviewContent("");
        setRating();
        Swal.fire({
          title: "Review Created",
          text: "Thank you for taking the time to write us a review.",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Continue",
          confirmButtonColor: "#0CDA98",
          dangerMode: true,
        }).then((isConfirm) => {
          if (isConfirm.isConfirmed) {
            // console.log("success")
          } else {
          }
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      <div className="cart-box ps-lg-0">
        <div className="row">
          <div className="col-lg-2 col-sm-2 text-cart">
            <img
              className="mx-auto d-flex img-fluid"
              src={
                product.imageUrl?.length > 0 ? product.imageUrl : noImageFound()
              }
              alt="order img"
              style={{ height: 120 }}
            />
          </div>
          <div className="col-lg-10 col-sm-10 ps-lg-0 align-self-center my-lg-0 my-3">
            <div className="accordion-item">
              <div className="accordion-header" id="flush-headingOne">
                <div
                  className="accordion-button collapsed pb-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#flush-collapseOne${order.orderId}`}
                  aria-expanded="false"
                  aria-controls={`flush-collapseOne${order.orderId}`}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="">
                        <h3 className="pro-title mb-1"> {product.title}</h3>
                        <p className="pro-sub-title">
                          Order ID: {order.orderId}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <h3 className="pro-price mb-1">
                          {" "}
                          <AmountFormat value={product.price} />{" "}
                        </h3>
                        {/* <p className="pro-sub-title">Paid by: {order.type}</p> */}
                      </div>
                    </div>

                    <div className="col-12 pt-3 px-0">
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="ps-2 align-self-center  text-cart">
                            {product.trackStatus === "delivered" ? (
                              <>
                                <span className="pe-2">
                                  <a href="/" className="text-success fw-bold">
                                    Download Invoice
                                  </a>
                                </span>
                                <span className="border-start ps-2">
                                  {" "}
                                  <a href="/" className="text-success fw-bold">
                                    Review Order
                                  </a>{" "}
                                </span>
                              </>
                            ) : (
                              <span className="border-start ps-2">
                                {" "}
                                <a href="/" className="text-success fw-bold">
                                  Track Order
                                </a>{" "}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="text-cart text-end">
                          <span className="pe-2">
                            <a
                              href="/"
                              className="text-success fw-bold text-capitalize"
                            >
                              {product.trackStatus}
                            </a>
                          </span>
                          {/* <p className="pro-sub-title mb-0">
                            Delivered on April 29
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id={`flush-collapseOne${order.orderId}`}
                className="accordion-collapse collapse pt-5"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="row">
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-md-6">
                        <h3 className="pro-title mb-1">Delivered Address</h3>
                        <p className="pro-sub-title">
                          {order?.address?.firstName +
                            " " +
                            order?.address?.lastName}
                        </p>
                        <p className="pro-sub-title">
                          {order?.address?.area} {order?.address?.city} -{" "}
                          {order?.address?.pincode}
                        </p>

                        <p className="pro-sub-title fw-bold">
                          {order?.address?.phoneNumber}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <h3 className="pro-title mb-1">Payment Method Used</h3>
                        <p className="pro-sub-title">
                          {order.type}
                          {/* Amazon Pay balance **** 6729 */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <h4 className="pro-title mb-1">Order Summary</h4>
                    <div className="d-flex justify-content-between py-1 left-text">
                      <div className="title">Subtotal</div>
                      <div className="title-value">
                        <AmountFormat value={order.total} />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-1 left-text">
                      <div className="title">Discount</div>
                      <div className="title-value">
                        - <AmountFormat value={order.totaldiscount} />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-1 left-text">
                      <div className="title">Shipping</div>
                      <div className="title-value">
                        <AmountFormat value={order.deliveryCharges} />
                      </div>
                    </div>
                    {/* <div className="d-flex justify-content-between py-1 left-text">
                      <div className="title">Promocode Discount</div>
                      <div className="title-value">- ₹ 719</div>
                    </div> */}
                    <div className="d-flex justify-content-between py-1 left-text">
                      <div className="title">Order Total</div>
                      <div className="title-value">
                        <AmountFormat value={order.grandTotal} />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Review */}
                {product.trackStatus === "delivered" && (
                  <div className="row">
                    <div class="col-lg-12 py-4 bg-light px-0">
                      <div class="row">
                        <div class="d-flex justify-content-center ">
                          <div class="title pb-3 ">
                            <h4 class="fw-bold fs-20px">Write a Review</h4>
                          </div>
                        </div>
                      </div>
                      <div class="pt-0 px-3">
                        <div class="col">
                          <div class="row gx-5 ">
                            <div class="col-lg-4">
                              <div class="h5">Rate this product</div>
                              <ReactStars
                                count={5}
                                onChange={ratingChanged}
                                size={24}
                                color2={"#ffd700"}
                                isHalf={false}
                              />
                              {/* <ReactStars
                                count={5}
                                onChange={ratingChanged}
                                size={24}
                                isHalf={false}
                                emptyIcon={<i className="far fa-star"></i>}
                                // halfIcon={
                                //   <i className="fa fa-star-half-alt"></i>
                                // }
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#FFD700"
                              /> */}
                            </div>
                            <div class="col-lg-8  col-md-12 pt-0">
                              <input
                                type="text"
                                class="form-control w-100 mb-2"
                                placeholder="Review Heading.."
                                value={reviewHeading}
                                onChange={(event) =>
                                  setReviewHeading(event.target.value)
                                }
                              />
                              <textarea
                                class="form-control w-100"
                                rows="2"
                                placeholder="Write review.."
                                id="floatingTextarea2"
                                value={reviewContent}
                                onChange={(event) =>
                                  setReviewContent(event.target.value)
                                }
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="row gy-3 pt-4 justify-content-end">
                          <div class="col-auto">
                            <button
                              class="btn btn-success text-white px-5 w-100 "
                              onClick={onSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartBox;
