import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCategoryList } from "../../store/actions/category/categoryAction";
import Loader from "../../components/loader";
// import "../../assets/css/new.css"
import "../../assets/scss/sidebar.scss";
function ShopByCatagory() {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.data);
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const loading = useSelector((state) => state.category.isLoading);

  useEffect(() => {
    setIsLoading(loading);
    setCategoryList(categories);
  }, [loading, categories]);

  useEffect(() => {
    dispatch(fetchCategoryList());
  }, [dispatch]);

  const box = document.querySelectorAll('.box');
//   box.style.position = 'absolute';
  
  document.addEventListener('mouseover', function handleClick(event) {
    // box.style.top = event.clientY - 50 + 'px';
    // box.style.left = event.clientX - 50 + 'px';
    console.log('y', this.offsetTop);
  });
  
  return (
    <>
      {isLoading ? <Loader /> : ""}

      {/* <div className="card">

                <div className="card-body py-0">
                    <ol className="list-group list-unstyled">
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            Shop By Categoryqqq
                        </li>
                        <div className="accordion accordion-flush border" id="accordionFlushExample">  
                            {
                                categoryList?.length>0 && categoryList.map((categoryItem, index) => (

                                    <div key={index} className="accordion-item border-bottom-0">
                                        <h2 className="accordion-header" id={`flush-headingOne${index}`}>
                                            <div
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#flush-collapseOne${index}`}
                                                aria-expanded="false"
                                                aria-controls="flush-collapseOne"
                                            >
                                                <li className="">
                                                    <div className="text-decoration-none text-light-gray d-flex justify-content-between align-items-start w-100">
                                                        <div className="d-flex">
                                                            <div className="size-16px sprite-icon">
                                                                <img className="img-fluid w-100" src="images/icons/power-switch.png" alt="" />
                                                            </div>
                                                            <div className="px-3">{categoryItem.categoryname}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                            
                                        </h2>
                                        <div id={`flush-collapseOne${index}`}
                                         className="accordion-collapse collapse" 
                                        aria-labelledby={`flush-headingOne${index}`} data-bs-parent="#accordionFlushExample">
                                            <ul className="list-unstyled ps-0">
                                               
                                                <li className='list-item'>
                                                    <div className="accordion accordion-flush " id="subaccordionFlushExample">
                                                        {
                                                            categoryItem.subcategory?.length>0 && categoryItem.subcategory.map((subCategory, index) => (

                                                                <div key={index} className="accordion-item border-bottom-0">
                                                                     <h2 className="accordion-header" id={`subflush-headingOne${index}`}>
                                                                        <div
                                                                            className="accordion-button collapsed"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#subflush-collapseOne${index}`}
                                                                            aria-expanded="false"
                                                                            aria-controls="subflush-collapseOne"
                                                                        >
                                                                             <li className="">
                                                                                <div className="text-decoration-none text-light-gray d-flex justify-content-between align-items-start w-100">
                                                                                    <div className="d-flex">
                                                                                        <div className="size-16px sprite-icon">
                                                                                            <img className="img-fluid w-100" src="images/icons/power-switch.png" alt="" />
                                                                                        </div>
                                                                                        <div className="px-3"> {subCategory.categoryname}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </div>
                                                                    </h2>
                                                                    <div id={`subflush-collapseOne${index}`}
                                                                    className="accordion-collapse collapse" 
                                                                    aria-labelledby={`subflush-headingOne${index}`} data-bs-parent="#subaccordionFlushExample">
                                                                         <ul className="list-unstyled ps-0">
                                                                          {
                                                                                subCategory.sub2category?.map(subSubCategory => (
                                                                                    <li key={categoryItem.categoryid}>
                                                                                     
                                                                                         
                                                                                        <div className="text-decoration-none text-light-gray d-flex justify-content-between align-items-start w-100">
                                                                                    <div className="d-flex">
                                                                                        <div className="size-16px sprite-icon">
                                                                                            <img className="img-fluid w-100 ps-3" src="images/icons/power-switch.png" alt="" />
                                                                                        </div>
                                                                                        <div className="px-3">{subSubCategory?.categoryname}</div>
                                                                                    </div>
                                                                                </div>
                                                                                    </li>
                                                                                ))
                                                                            }
                                                           
                                                                        
                                                                        </ul>
                                                                    </div>
                                                                </div>


                                                            ))
                                                        }
                                                    </div>
                                                </li>
                                               
                                            </ul>
                                        </div>
                                    </div>


                                ))
                            } 
                            </div>
                            


                    </ol>
                </div>
            </div>    */}

      <div class="card category-Card-box">
        <div class="card-header card-head card-top text-success fs-22px card-height">
          <p className="pt-2 mx-3">Shop By Category</p>
        </div>

        <div class="card-body border">
          <nav>
            <ul>
              {categoryList?.length > 0 &&
                categoryList.map((categoryItem, index) => (
                  <li key={index} className="dropdown">
                    <Link
                      key={index}
                      className="text-dark dropdown-hover"
                      to={{
                        pathname: "/product",
                        search: `?category=${categoryItem.categoryid}&catU=${categoryItem._id}&catname=${categoryItem.categoryname}`,

                        state: { from: "category" },
                      }}
                    >
                     <span>
                     <img
                        className="img-fluid mx-3"
                        src="images/icons/power-switch.png"
                        alt=""
                      />
                      {categoryItem.categoryname}
                     </span>
                      {/* {categoryItem.subcategory?.length>0?   <span className="mx-auto list-arrow">&rsaquo;</span> :""} */}
                      <div>
                        <span className="mx-auto list-arrow">&rsaquo;</span>
                      </div>
                    </Link>

                    <ul className="dropdown-card px-1">
                      {categoryItem.subcategory?.length > 0 &&
                        categoryItem.subcategory.map((subCategory, index) => (
                          <li key={index} className="dropdown">
                            <Link
                              key={index}
                              className="text-dark dropdown-hover"
                              to={{
                                pathname: "/product",
                                search: `?subCategory=${subCategory.categoryid}&subCatU=${subCategory._id}&subcatname=${subCategory.categoryname}`,

                                state: { from: "SubCategory" },
                              }}
                            >
                              {subCategory.categoryname}
                              <span className="mx-auto list-arrow">
                                &rsaquo;
                              </span>
                            </Link>

                            <ul className="sub-category">
                              {subCategory.sub2category?.map(
                                (subSubCategory, index) => (
                                  <li key={index} className="dropdown">
                                    <Link
                                      key={index}
                                      className="text-dark dropdown-hover"
                                      to={{
                                        pathname: "/product",
                                        search: `?sub2Category=${subSubCategory.categoryid}&sub2CatU=${subSubCategory._id}&Sub2catname=${subSubCategory.categoryname}`,

                                        state: { from: "SubsubCategory" },
                                      }}
                                    >
                                      {subSubCategory.categoryname}
                                      <span className="mx-auto list-arrow">
                                        &rsaquo;
                                      </span>
                                    </Link>
                                  </li>
                                )
                              )}
                            </ul>
                          </li>
                        ))}
                    </ul>
                  </li>
                ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default ShopByCatagory;
