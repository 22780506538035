import {
    FETCH_SUB_CATAGORYLIST_REQUEST,
    FETCH_SUB_CATAGORYLIST_SUCCESS,
    FETCH_SUB_CATAGORYLIST_FAILURE,

} from "../../type";
// import axiosInstance from "src/api/axiosInstance";
// import { ADD_SUBCATAGORY_URL, FETCH_SUBCATAGORY_URL, REMOVE_CATAGORY, UPDATE_SUBCATAGORY_URL } from "src/api/urls";
// import { FETCH_SUBCATAGORY_URL } from "src/api/urls";
import axiosInstance from "../../../api/axiosInstance";
import { FETCH_SUBCATAGORY_URL } from "../../../api/urls";

export const fetchSubCatagoryList = () => {
    return (dispatch) => {
        dispatch(fetchSubCatagoryListRequest());

        try {
            axiosInstance
                .post(FETCH_SUBCATAGORY_URL)
                .then((response) => {
                    let res = JSON.parse(response.data);

                    if (res.code === 200) {
                         dispatch(fetchSubCatagoryListSucess(res.result));
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                    dispatch(fetchSubCatagoryListFailure(error.message));
                });
        } catch (e) { }
    };
};


// export const getSubCategoryByCategoryId = (categoryId) => {
//     return (dispatch) =>
//         new Promise((resolve, reject) => {
//             dispatch(getSubCategoryByCategoryIdRequest());
//             try {
//                 axiosInstance
//                     .post(GET_SUBCATEGORY_BY_CATEGORYID, categoryId, {
//                         headers: {
//                             'Content-Type': 'application/json',
//                             'Authorization': 'token ' + localStorage.getItem('accessToken')
//                         }
//                     }
//                     )
//                     .then((response) => {
//                         let res = JSON.parse(response.data);

//                         if (response.status === 200) {
//                             dispatch(getSubCategoryByCategoryIdSuccess(res.result[0].subcategory));
//                             resolve();
//                         } else {
//                             reject()
//                         }
//                     })
//                     .catch((error) => {

//                         dispatch(getSubCategoryByCategoryIdFailure(error));
//                     });
//             } catch (e) { }
//             // alert(error)
//         });
// };



/* ******************************************************************************************
                                                                                            *
    Actions functions are defined below for CATAGORY Operations/Actions                    *
                                                                                            *
*********************************************************************************************
 
*/

const fetchSubCatagoryListRequest = () => {
    return {
        type: FETCH_SUB_CATAGORYLIST_REQUEST,
    };
};

const fetchSubCatagoryListSucess = (data) => {
    return {
        type: FETCH_SUB_CATAGORYLIST_SUCCESS,
        payload: data,
    };
};

const fetchSubCatagoryListFailure = (error) => {
    return {
        type: FETCH_SUB_CATAGORYLIST_FAILURE,
    };
};



// const getSubCategoryByCategoryIdRequest = () => {
//     return {
//         type: GET_SUBCATEGORY_BY_CATEGORYID_REQUEST
//     }
// }

// const getSubCategoryByCategoryIdSuccess = (subCategoryList) => {
//     return {
//         type: GET_SUBCATEGORY_BY_CATEGORYID_SUCCESS,
//         payload: subCategoryList
//     }
// }

// const getSubCategoryByCategoryIdFailure = (error) => {
//     return {
//         type: GET_SUBCATEGORY_BY_CATEGORYID_FAILURE,
//         payload: error
//     }
// }
