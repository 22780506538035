import React from "react";
import AmountFormat from "../amountFormat";
import { noImageFound } from "../reuse/notFound";

const OrderSummaryItems = ({ products }) => {
  return products.map((product) => (
    <div className="d-flex align-items-center">
      <div className="size-80px p-3">
        <img
          className="img-fluid w-100"
          src={
            product.imageUrls?.length > 0 ? product.imageUrls : noImageFound()
          }
          alt="Item"
        />
      </div>
      <div className="px-2">
        <div className="mb-1">
          <span>{product.title}</span> x <span>{product.quantity}</span>{" "}
        </div>
        <div className="fw-medium">
          <AmountFormat value={product.price} />
        </div>
      </div>
    </div>
  ));
};

export default OrderSummaryItems;
