import {
  FETCH_CART_REQUEST,
  FETCH_CART_SUCCESS,
  FETCH_CART_FAILURE,
  FETCH_CART_PRODUCT_ID,
  REMOVE_CART_ITEM_REQUEST,
  REMOVE_CART_ITEM_SUCCESS,
  REMOVE_CART_ITEM_FAILURE,
  INCREMENT_CART_ITEMCOUNT_REQUEST,
  INCREMENT_CART_ITEMCOUNT_SUCCESS,
  INCREMENT_CART_ITEMCOUNT_FAILURE,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  BUY_NOW_REQUEST,
  BUY_NOW_SUCCESS,
  BUY_NOW_FAILURE,
  GET_CART_DETAILS_REQUEST,
  GET_CART_DETAILS_SUCCESS,
  GET_CART_DETAILS_FAILURE,
} from "../type";
import axiosInstance from "../../api/axiosInstance";
import {
  ADD_TO_CART_URL,
  GET_CART_URL,
  GET_CART_PRICE_URL,
  REMOVE_ITEM_FROM_CART_URL,
  UPDATE_CART_URL,
} from "../../api/urls";

export const fetchCartActionV2 = () => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      try {
        axiosInstance
          .post(GET_CART_URL)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(fetchCartSuccess(res));
              resolve();
            } else {
              dispatch(fetchCartFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            dispatch(fetchCartFailure(error.message));
          });
      } catch (e) {}
    });
};

export const fetchCartAction = () => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(fetchCartRequest());
      try {
        axiosInstance
          .post(GET_CART_URL)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(fetchCartSuccess(res));
              resolve();
            } else {
              dispatch(fetchCartFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            dispatch(fetchCartFailure(error.message));
          });
      } catch (e) {}
    });
};

export const fetchCartProductId = () => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      try {
        axiosInstance
          .post(GET_CART_URL)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch({
                type:FETCH_CART_PRODUCT_ID,
                payload:res.map((item)=>item.productId)
              });
              resolve();
            } else {
              dispatch(fetchCartFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            dispatch(fetchCartFailure(error.message));
          });
      } catch (e) {}
    });
};


export const removeCartItem = (product) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      // dispatch(removeCartItemRequest());
      try {
        axiosInstance
          .post(REMOVE_ITEM_FROM_CART_URL, product)
          .then((response) => {
            let res = JSON.parse(response.data);
            if (res.message === "success") {
              dispatch(fetchCartActionV2());
              dispatch(fetchCartProductId());
              dispatch(
                getCartPriceAction({
                  type: "cod",
                  voucherCode: "",
                })
              );
              // dispatch(removeCartItemSuccess(res));
              resolve();
            } else {
              // dispatch(removeCartItemFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            dispatch(removeCartItemFailure(error.message));
          });
      } catch (e) {}
    });
};

export const updateCartItemCount = (product) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      //   dispatch(incrementCartItemCountRequest());
      try {
        axiosInstance
          .post(UPDATE_CART_URL, product)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(incrementCartItemCountSuccess(res));
              dispatch(fetchCartProductId());
              resolve();
            } else {
              dispatch(incrementCartItemFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            dispatch(incrementCartItemFailure(error.message));
          });
      } catch (e) {}
    });
};

export const addToCartAction = (product) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(addToCartRequest());
      try {
        axiosInstance
          .post(ADD_TO_CART_URL, product)
          .then((response) => {
            let res = JSON.parse(response.data);
            if (response.status === 200) {
              dispatch(addToCartSuccess(res));
              dispatch(fetchCartProductId());
              resolve("Success");
            } else {
              dispatch(addToCartFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            dispatch(addToCartFailure(error.message));
          });
      } catch (e) {}
    });
};

// This gives details of cart (like price summary)
export const getCartPriceAction = (requestObj) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      //   dispatch(getCartDetailsRequest());
      try {
        axiosInstance
          .post(GET_CART_PRICE_URL, requestObj)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(getCartDetailsSuccess(res.result));
              resolve();
            } else {
              dispatch(getCartDetailsFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            dispatch(getCartDetailsFailure(error.message));
          });
      } catch (e) {}
    });
};

// Buy Now

export const buyNow = () => {
  return (dispatch) => {
    dispatch(buyNowRequest());
    // api call to buy item on the go

    const cartData = ""; //cart data response from api call

    dispatch(buyNowSuccess(cartData));
  };
};

const fetchCartRequest = () => {
  return {
    type: FETCH_CART_REQUEST,
  };
};

const fetchCartSuccess = (cartData) => {
  return {
    type: FETCH_CART_SUCCESS,
    payload: cartData,
  };
};

const fetchCartFailure = (error) => {
  return {
    type: FETCH_CART_FAILURE,
    payload: error,
  };
};

// eslint-disable-next-line
const removeCartItemRequest = () => {
  return {
    type: REMOVE_CART_ITEM_REQUEST,
  };
};

// eslint-disable-next-line
const removeCartItemSuccess = (newCartData) => {
  return {
    type: REMOVE_CART_ITEM_SUCCESS,
    payload: newCartData,
  };
};

const removeCartItemFailure = (error) => {
  return {
    type: REMOVE_CART_ITEM_FAILURE,
    payload: error,
  };
};

// eslint-disable-next-line
const incrementCartItemCountRequest = () => {
  return {
    type: INCREMENT_CART_ITEMCOUNT_REQUEST,
  };
};

const incrementCartItemCountSuccess = (newCartData) => {
  return {
    type: INCREMENT_CART_ITEMCOUNT_SUCCESS,
    payload: newCartData,
  };
};

const incrementCartItemFailure = (error) => {
  return {
    type: INCREMENT_CART_ITEMCOUNT_FAILURE,
    payload: error,
  };
};

const addToCartRequest = () => {
  return {
    type: ADD_TO_CART_REQUEST,
  };
};

const addToCartSuccess = (cartData) => {
  return {
    type: ADD_TO_CART_SUCCESS,
    payload: cartData,
  };
};

const addToCartFailure = (error) => {
  return {
    type: ADD_TO_CART_FAILURE,
    payload: error,
  };
};

const buyNowRequest = () => {
  return {
    type: BUY_NOW_REQUEST,
  };
};

const buyNowSuccess = (cartData) => {
  return {
    type: BUY_NOW_SUCCESS,
    payload: cartData,
  };
};

// eslint-disable-next-line
const buyNowFailure = (error) => {
  return {
    type: BUY_NOW_FAILURE,
    payload: error,
  };
};

// eslint-disable-next-line
const getCartDetailsRequest = () => {
  return {
    type: GET_CART_DETAILS_REQUEST,
  };
};

const getCartDetailsSuccess = (cartData) => {
  return {
    type: GET_CART_DETAILS_SUCCESS,
    payload: cartData,
  };
};

const getCartDetailsFailure = (error) => {
  return {
    type: GET_CART_DETAILS_FAILURE,
    payload: error,
  };
};
