import React from 'react'
import Footer from '../components/homePage/Footer'
import Header from '../components/homePage/Header'

const Layout = ({children}) => {
    return (
        <div>
            <Header />
            {children}
            <Footer/>   
        </div>
    )
}

export default Layout
