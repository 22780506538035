import React, { useState } from "react";
import { useDispatch } from "react-redux";
import AmountFormat from "../amountFormat";
import { applyPromocode } from "../../store/actions/order/orderAction";

const PriceSummary = ({ cartDetails, completeCart }) => {
   const [promocode, setPromocode] = useState("");
  const dispatch = useDispatch();
  const applyOrderPromocode = () => {
    let categories = [];
    let products = [];
    for (let i = 0; i < completeCart.length; i++) {
      categories.push(completeCart[i].category.id);
      products.push(completeCart[i]._id);
    }
    const requestObj = {
      voucherTitle: promocode,
      category: categories,
      product: products,
      amount:cartDetails.total,
    };
    // eslint-disable-next-line no-lone-blocks
    {promocode.length>1 &&  
      dispatch(applyPromocode(requestObj)).then((resolve) => {
      console.log("success-------",resolve);
      // alert(resolve.message)
    });}
   
  };


  // console.log(cartDetails)
  return (
    <>
      <div className="card-body">
        <h6 className="fw-medium">Price Summary</h6>
        <div className="small">

        <div className="d-flex justify-content-between py-2 left-text">
            <div className="title">MRP</div>
            <div className="title-value">
              {/* <AmountFormat value={cartDetails?.total} /> */}
              {cartDetails?.MRP}
            </div>
          </div>

          <div className="d-flex justify-content-between py-2 left-text">
            <div className="title">Discount</div>
            <div className="title-value text-success">
              {/* - <AmountFormat value={cartDetails?.totaldiscount} /> */}
              - ₹ {((((cartDetails?.MRP))*((((cartDetails?.MRP)-(cartDetails?.total))/(cartDetails?.MRP))*100))/100).toFixed(2)}
            </div>
          </div>

          <div className="d-flex justify-content-between py-2 left-text">
            <div className="title">Coupon Discount</div>
            <div className="title-value text-success">
              {/* - <AmountFormat value={cartDetails?.totaldiscount} /> */}
              - ₹ {cartDetails?.couponDiscount} 

           
            </div>
          </div>

          <div className="d-flex justify-content-between py-2 left-text">
            <div className="title">Shipping</div>
            <div className="title-value">
              <AmountFormat value={cartDetails?.deliveryCharges} />
            </div>
          </div>

         

         
          {/* <div className="d-flex justify-content-between py-2 left-text">
            <div className="title">Tax</div>
            <div className="title-value text-success">
              - <AmountFormat value={cartDetails?.totaltax} />
            </div>
          </div> */}
          
          <div className="d-flex justify-content-between py-2 left-text">
            <div className="title">
              <input
                type="text"
                name="promocode"
                value={promocode}
                onChange={(e) => setPromocode(e.target.value)}
              />
              <div
                className="text-success small"
                style={{ cursor: "pointer" }}
                onClick={applyOrderPromocode}
              >
                Apply Promocode
              </div>
            </div>
          </div>



          {/* <div className="d-flex justify-content-between py-2 left-text">
            <div className="title">Subtotal</div>
            <div className="title-value">
              <AmountFormat value={cartDetails?.total} />
            </div>
          </div> */}

        </div>
        <div className="d-flex h6 fw-medium justify-content-between py-2 left-text">
          <div className="totel-price">Order Total</div>
          <div className="title-value">
            {/* <AmountFormat value={cartDetails?.grandTotal} /> */}
            ₹ {cartDetails?.total}
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceSummary;
