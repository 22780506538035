export const BASE_URL = "https://bulkbackend.escalesolutions.com/"

// moni escale
// export const BASE_URL = "http://192.168.1.15:7080/"

//local
// export const BASE_URL = "http://192.168.1.7:7080/";
// export const BASE_URL = "http://192.168.1.191:7080/"

export const SIGNUP_URL = "signup/registerUser"
export const LOGIN_URL = "login/login"
export const SEND_OTP_URL = "signup/tempMobile"
export const VERIFY_OTP = "signup/verifyMobileTemp";

export const SEND_PASSWORD_RESET_LINK_URL = "forgotpassword/sendLink";
export const RESET_PASSWORD_URL = "forgotpassword/passwordReset"

export const VERIFY_EMAIL_URL = "commonroutes/activateEmail";

export const FETCH_CATAGORY_URL = "category/getMegaMenu";

export const FETCH_SUBCATAGORY_URL = "category/getsubcategory";

// export const FETCH_PRODUCT_BY_SUBCATEGORY = "commonroutes/loadProducts";
export const FETCH_PRODUCT_BY_SUBCATEGORY = "commonroutes/filterForHome";

export const GET_PRODUCT_DETAILS = "admindash/getProductById";

export const GET_BRANDLIST_URL = "brand/getBrand";

export const FETCH_FEATURED_PRODUCT_URL = "commonroutes/loadProducts";

export const FET_PROFILE_DATA_URL = "profile/getProfileData";

export const ADD_SHIPPING_ADDRESS_URL = "profile/addAddress";

export const GET_SAVED_ADDRESS_URL = "profile/getAddress";

export const GET_ADDRESS_BY_ID_URL = "profile/getAddressById";

export const UPDATE_ADDRESS_URL = "profile/UpdateAddress";

export const REMOVE_ADDRESS_URL = "profile/removeAddress";

export const UPDATE_PROFILE_IMAGE_URL = "profile/upload-pic";

export const UPDATE_PROFILE = "profile/updateProfileData";

export const UPDATE_USER_EMAIL_URL = "profile/change-email";

export const BOOKMARK_PRODUCT_URL = "userdash/bookmarkProduct";

export const GET_BOOKMARK_PRODUCTS_URL = "userdash/loadbookmarkProduct";

export const ADD_TO_CART_URL = "userdash/addToCart";

export const GET_CART_URL = "userdash/loadCart";

export const UPDATE_CART_URL = "userdash/updateCart";

export const GET_CART_PRICE_URL = "userdash/cartPrice";

export const REMOVE_ITEM_FROM_CART_URL = "userdash/removeFromCart";

export const ADD_TO_QUOTATION_URL = "userdash/addToQuotation";

export const GET_QUOTATION_LIST_URL = "userdash/loadQuotation";

export const GET_QUOTATION_PRICE_URL = "userdash/quotationPrice";

export const PLACE_ORDER_URL = "userdash/orderNow";

export const VALIDATE_VOUCHER_URL = "vouchers/validate-voucher";

export const FETCH_ORDER_LIST_URL = "myorders/loadMyOrders";

export const CMS_URL = "/cms/getcms";

export const FETCH_SUB_SUB_CAT_URL="category/getsub2category"