import React, { useEffect, useState } from "react";
import Header from "../components/homePage/Header";
// import ItemDisplay from "../components/order/ItemDisplay";
// import PriceSummary from "../components/order/PriceSummary";
// import SelfTransport from "../components/order/SelfTransport";
import { Link } from "react-router-dom";
import Footer from "../components/homePage/Footer";
import {
  getQuotationList,
  getQuotationPrice,
} from "../store/actions/quotation/quotationAction";
import QuotationDisplay from "../components/order/quotationDIspay";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/loader";
import PriceSummaryQuotation from "../components/order/PriceSummaryQuotation";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { fetchCategoryList } from "../store/actions/category/categoryAction";
import { getBrandList } from "../store/actions/brandAction";
// import { getBrandList } from "../../store/actions/brandAction";
import makeAnimated from "react-select/animated";
import axiosInstance from "../api/axiosInstance";
import axios from "axios";
import { BASE_URL } from "../api/urls";

const MyQuotationScreen = () => {
  const quotationList = useSelector(
    (state) => state.quotation.quotationProductList
  );
  const animatedComponents = makeAnimated();
  const loading = useSelector((state) => state.quotation.isLoading);
  const [isLoading, setIsLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const dispatch = useDispatch();
  const quotationPriceSummary = useSelector(
    (state) => state.quotation.quotationPrice
  );
  useEffect(() => {
    setIsLoading(loading);
    setProductList(quotationList);
  }, [loading, quotationList, quotationPriceSummary]);

  useEffect(() => {
    dispatch(getQuotationList());
    dispatch(
      getQuotationPrice({
        type: "cod",
        voucherCode: "",
      })
    );
  }, [dispatch]);

  const [cId, setCId] = useState("");
  const [bId, setBId] = useState("");
  const [pList, setPList] = useState([]);

  const brands = useSelector((state) => state.brands.brands);
  const categories = useSelector((state) => state.category.data);
  // console.log("==>", brands);
  // console.log("categories==>", categories);
  const brandList = [];
  if (brands.length > 0) {
    brands.forEach((brand) => {
      brandList.push({
        label: brand.name,
        value: brand.brandId,
        brandId: brand.brandId,
      });
    });
    // console.log("brandList", brandList);
  }

  const categoryList = [];
  if (categories.length > 0) {
    categories.forEach((category) => {
      categoryList.push({
        label: category.categoryname,
        value: category.categoryid,
        categoryId: category.categoryid,
      });
    });
    // console.log("brandList", brandList);
  }

  let prodList = [];
  if (pList.length > 0) {
    pList.forEach((product) => {
      prodList.push({
        label: product.title,
        productId: product.productId,
      });
    });
  } else {
    prodList.length = 0;
  }

  useEffect(() => {
    dispatch(fetchCategoryList());
    dispatch(getBrandList());
  }, [dispatch]);

  // useEffect(async () => {
  //   let requestObj = {
  //     // count: pageNumber * COUNT?_PER_PAGE,
  //     // limit: COUNT_PER_PAGE,
  //     field: "max",
  //     filters: {
  //       "category.categoryid": cId?cId:null,
  //       "subCategory.categoryid": null,
  //       "brand.brandid": bId?bId:null,
  //     },
  //   };

  //   if(bId || cId){
  //     try {
  //       const result = await axios.post(
  //         `${BASE_URL}commonroutes/loadProducts`,
  //         requestObj
  //       );
  //       console.log("result=>", result.data.data);

  //       console.log("result=>", result.data.message);
  //       if(result.data.success){
  //         setPList(result.data.data.products)
  //       }
  //       if(result.data.message==="none"){
  //         setPList([])
  //       }

  //     } catch (e) {
  //       console.log(e.message);
  //     }
  //   }

  //   // console.log("pList==>",prodList)

  // }, [dispatch, bId, cId]);
  const [price, setPrice] = useState(0);
  const [priceTotal, setPriceTotal] = useState(0);
  const [discountTotal, setDiscountPriceTotal] = useState(0);
  
  const [list, setList] = useState([
    {
      brandName: "",
      brandId: "",
      categoryName: "",
      categoryId: "",
      productName: "",
      productPrice: "",
      productOptionList: [],
      quantity: 0,
      price: 0,
      mrp: 0,
      discount:0,
      totalPrice: 0,
    },
  ]);

  const quotationSchema = {
    brandName: "",
    brandId: "",
    categoryName: "",
    categoryId: "",
    productName: "",
    productPrice: "",
    productOptionList: [],
    quantity: 0,
    price: 0,
    mrp: 0,
    discount:0,
    totalPrice: 0,
  };

  //Add New Product
  const handleAddClick = () => {
    const updatedList = [...list];
    updatedList.push(quotationSchema);
    setList(updatedList);
  };

  //Remove Product
  const removeProduct = (i) => {
    console.log(list[i].totalPrice);
    setPriceTotal(priceTotal - list[i].totalPrice);
    setDiscountPriceTotal(discountTotal - list[i].discount);
    const newList = [...list];
    newList.splice(i, 1);
    setList(newList);

    // console.log(newList);
  };

  const [load, setLoad] = useState(true);

  //Brand Change
  const handleBrandChange = async (index, value) => {
    setLoad(true);
    // console.log("brand", index, JSON.parse(value));
    const selectedValue = JSON.parse(value);

    setBId(selectedValue.brandId);
    if (list.length > 0) {
      setPriceTotal(priceTotal - list[index].totalPrice);
      setDiscountPriceTotal(discountTotal - list[index].discount);
    }
      list[index].brandId = selectedValue.brandId;
      list[index].brandName = selectedValue.name;
      list[index].price = 0;
      list[index].mrp = 0;
      list[index].quantity = 0;
      list[index].discount = 0;
      list[index].totalPrice = 0;
      list[index].variants=[]
      setList(list);
      let requestObj = {
        // count: pageNumber * COUNT?_PER_PAGE,
        // limit: COUNT_PER_PAGE,
        field: "max",
        filters: {
          "category.categoryid": list[index].categoryId
          ? list[index].categoryId
          : null,
          "subCategory.categoryid": null,
          "brand.brandid": selectedValue.brandId ? selectedValue.brandId : null,
        },
      };
      const result = await axios.post(
        `${BASE_URL}commonroutes/loadProducts`,
        requestObj
        );
        if (result.data.message === "none") {
          list[index].productOptionList.length = 0;
          setLoad(false);
        }
        if (result.data.success) {
          list[index].productOptionList = result.data.data?.products;
          setLoad(false);
        }
        setList(list);
        console.log("list", list);
        // setBId("")
      };
      
      const handleCategoryChange = async (index, value) => {
        setLoad(true);
        const selectedValue = JSON.parse(value);
        
        setCId(selectedValue.categoryid);
        if (list.length > 0) {
          setPriceTotal(priceTotal - list[index].totalPrice);
          setDiscountPriceTotal(discountTotal - list[index].discount);
          list[index].categoryId = selectedValue.categoryid;
          list[index].categoryName = selectedValue.categoryname;
          list[index].price = 0;
          list[index].mrp = 0;
          list[index].quantity = 0;
          list[index].discount = 0;
          list[index].totalPrice = 0;
          list[index].variants=[]
    setList(list);
    // setLoad(false);
  }
  let requestObj = {
    // count: pageNumber * COUNT?_PER_PAGE,
    // limit: COUNT_PER_PAGE,
    field: "max",
    filters: {
      "category.categoryid": selectedValue.categoryid
      ? selectedValue.categoryid
      : null,
      "subCategory.categoryid": null,
      "brand.brandid": list[index].brandId ? list[index].brandId : null,
    },
  };
  const result = await axios.post(
    `${BASE_URL}commonroutes/loadProducts`,
    requestObj
    );
    if (result.data.message === "none") {
      list[index].productOptionList.length = 0;
      setLoad(false);
      setList(list);
    }
    if (result.data.success) {
      list[index].productOptionList = result.data.data?.products;
      setLoad(false);
      setList(list);
    }
    
    // setBId("")
    setList(list);
    // console.log("list", list);
  };
  
  const handleProductChange = (index, value) => {
    setPriceTotal(priceTotal - list[index].totalPrice);
  setDiscountPriceTotal(discountTotal - list[index].discount);
    list[index].price = 0;
    const selectedValue = JSON.parse(value);
    // list[index].price = selectedValue.price;
    list[index].productName = selectedValue.title;
    list[index].productId = selectedValue.productId;
    list[index].variants = selectedValue.variants;
    list[index].quantity = 0;
    list[index].discount = 0;
    list[index].totalPrice = 0;

    setList(list);
  };

  let totalValue = 0;
  let totalDiscount = 0;
  const handleQuntity = (index, value) => {

    let rangeArray = list[index]?.variants?.map((variant, i, self) => {

      let rangeArray =
        variant.variant1 &&
        variant.variant1.name.split("-").map((ele) => parseInt(ele));
      let kk = self[i];
      if (kk) {
        let priceRangeArray =
          kk.variant1 &&
          kk.variant1.name.split("-").map((ele) => parseInt(ele));

        // console.log("priceRangeArray==>", priceRangeArray);

        if (rangeArray && rangeArray.length) {
          let smallerLimit = rangeArray[0];
          let largerLimit = rangeArray[1];
          console.log(smallerLimit, largerLimit);
          console.log(variant, largerLimit);

          if (value >= smallerLimit && value <= largerLimit) {
            console.log("variant.price", variant);
            list[index].price = variant.price;
            list[index].mrp = variant.mrp;
            list[index].discount =
              (variant.mrp - variant.price) *  (value ? parseInt(value) : 0);
          } else {
            if (value >= largerLimit) {
              console.log("varit==>", variant);
              list[index].price = variant.price;
              list[index].mrp = variant.mrp;
              list[index].discount =
                (variant.mrp - variant.price) *  (value ? parseInt(value) : 0);
            }
          }
        } else {
          list[index].price = variant.price;
          list[index].mrp = variant.mrp;
          list[index].discount =
            (variant.mrp - variant.price) * (value ? parseInt(value) : 0);
        }
      }
    });

    list[index].quantity = value ? parseInt(value):0 ;
    list[index].totalPrice = list[index].quantity * list[index].price;
    list[index].discount =(list[index].mrp - list[index].price) * (value ? parseInt(value) : 0) ;
    setList(list);
    if (value !== "") {
      setPrice(parseInt(value) * list[index].price);
    } else {
      setPrice(0);
    }

    //Total Price of all Product
    list.forEach((lst) => {
      totalValue += lst.totalPrice;
      totalDiscount += lst.discount;
    });
    setPriceTotal(totalValue);
    setDiscountPriceTotal(totalDiscount);
  };
  console.log('list==>',list)

  return (
    <>
      <Header />

      {/* {
        isLoading ? <Loader /> :
          productList.length === 0 ? <div className="text-success">Nothing here, start adding products to your get your quotations</div> :

            <main className="page-wrapper">
              <div className="cart">
                <div className="container px-xxl-5 px-xl-4">
                  <div class="col-lg-8">
                    <div class="d-flex justify-content-between pe-2">
                      <div class="h4 fw-medium pt-3">My Quotation</div>
                      <div class="align-self-end">

                        <img src="images/print-img.png" alt="" />

                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="cart-box my-4">
                        {
                          productList.map((product) => (


                            <QuotationDisplay product={product} title={product.title} price={product.price}

                              imageUrl={product.imageUrls} />
                          ))
                        }
                      </div>
                    </div>

                    <div className="col-lg-4 pt-4 mb-4">
                      <div className="card shadow border-0 cart-box py-4 px-2">

                        <PriceSummaryQuotation cartDetails={quotationPriceSummary?.order} />
                        <div className="d-grid">
                          <Link
                            to="/product"
                            className="btn btn-light-success py-2 rounded-0"
                          >
                            Add more products
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
      } */}
      <main className="page-wrapper">
        <div className="cart">
          <div className="container px-xxl-5 px-xl-4">
            <div class="col-lg-12">
              <div class="d-flex justify-content-between pe-2">
                {/* <div className="h4 fw-medium pt-3">My Quotation</div> */}
                <div class="h4 fw-medium pt-3">My Quotation</div>
                <div class="align-self-end">
                  <img src="images/print-img.png" alt="" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 ">
                <div className="cart-box my-4 w-100 ">
                  <table class="table custom-input-width">
                    <thead>
                      <tr>
                        <th scope="col" className="col-1">
                          Index
                        </th>
                        <th scope="col" className="col-3">
                          Category
                        </th>
                        <th scope="col" className="col-3">
                          Brand
                        </th>
                        <th scope="col" className="col-3">
                          Product
                        </th>
                        <th scope="col" className="col-auto">
                          Qunatity
                        </th>
                        <th scope="col" className="col-auto">
                          Price
                        </th>
                        <th scope="col" className="col-auto">
                          Discount
                        </th>
                        <th scope="col" className="col-auto"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {list?.map((item, index) => (
                        <tr>
                          <th scope="row" className="pt-3">
                            {index + 1}
                          </th>

                          <td>
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                handleCategoryChange(index, e.target.value)
                              }
                            >
                              <option
                                selected={item.categoryName !== "" && true}
                                hidden
                              >
                                Select Category..
                              </option>
                              {categories?.map((category, i) => (
                                <option
                                  key={i}
                                  value={JSON.stringify(category)}
                                  selected={
                                    item.categoryName === category.categoryname
                                  }
                                >
                                  {category.categoryname}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                handleBrandChange(index, e.target.value)
                              }
                            >
                              <option
                                selected={item.brandName !== "" && true}
                                hidden
                              >
                                Select Brand..
                              </option>
                              {brands?.map((brand, i) => (
                                <option
                                  key={i}
                                  value={JSON.stringify(brand)}
                                  selected={item.brandName === brand.name}
                                >
                                  {brand.name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                handleProductChange(index, e.target.value)
                              }
                            >
                              <option
                                selected={item.productName !== "" && true}
                                hidden
                              >
                                Select Product..
                              </option>
                              {!load && item?.productOptionList?.length > 0 ? (
                                item.productOptionList?.map((product, i) => (
                                  <option
                                    key={i}
                                    value={JSON.stringify(product)}
                                    selected={
                                      item.productName === product.title
                                    }
                                  >
                                    {product.title}
                                  </option>
                                ))
                              ) : (
                                <option disabled>Product not found</option>
                              )}
                            </select>
                          </td>
                          <td className="">
                            <input
                              type="number"
                              className=""
                              // value={item.quantity}
                              min="0"
                              style={{ width: "60px", height: "35px" }}
                              onChange={(e) =>
                                handleQuntity(index, e.target.value)
                              }
                            />
                          </td>
                          <td className="pt-3">
                            {" "}
                            {item.quantity > 0 ? item.quantity * item.price : 0}
                          </td>
                          <td className="pt-3">
                            {/* {item.quantity > 0
                              ? item.quantity * item.mrp -
                                item.quantity * item.price
                              : 0} */}
                            {item.quantity > 0 ? item?.discount : 0}
                          </td>
                          <td className="pt-3">
                            {list.length > 1 && (
                              <i
                                className="fa fa-times text-muted mx-2"
                                aria-hidden="true"
                                style={{
                                  marginTop: "0px",
                                  cursor: "pointer",
                                }}
                                onClick={() => removeProduct(index)}
                              ></i>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="p-1 px-3 fw-bold">TOTAL</td>
                      <td className="p-1 px-2 fw-bold">{priceTotal}</td>
                      <td className="p-1 px-2 fw-bold">{discountTotal}</td>
                    </tfoot>
                  </table>

                  <div className="text-end m-3">
                    <button
                      type="button"
                      className="ps-2 pe-2 btn btn-success"
                      onClick={handleAddClick}
                    >
                      Add More Product
                    </button>
                    {/* <button
                      type="button"
                      className="ps-2 pe-2 mx-2 btn btn-success"
                      // onClick={handleAddClick}
                    >
                      Add to cart
                    </button> */}
                  </div>

                  {productList.map((product) => (
                    <QuotationDisplay
                      product={product}
                      title={product.title}
                      price={product.price}
                      imageUrl={product.imageUrls}
                    />
                  ))}
                </div>
              </div>

              {/* <div className="col-lg-4 pt-4 mb-4">
                <div className="card shadow border-0 cart-box py-4 px-2">
                  <PriceSummaryQuotation
                    cartDetails={quotationPriceSummary?.order}
                  />
                  <div className="d-grid">
                    <Link
                      to="/product"
                      className="btn btn-light-success py-2 rounded-0"
                    >
                      Add more products
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default MyQuotationScreen;
