import {
    GET_PRODUCTLIST_BY_BRAND_REQUEST,
    GET_PRODUCTLIST_BY_BRAND_SUCCESS,
    GET_PRODUCTLIST_BY_BRAND_FAILURE,
    // GET_PRODUCTLIST_BY_CATEGORY_REQUEST,
    // GET_PRODUCTLIST_BY_CATEGORY_SUCCESS,
    // GET_PRODUCTLIST_BY_CATEGORY_FAILURE,
    GET_PRODUCTLIST_BY_SUBCATEGORY_REQUEST,
    GET_PRODUCTLIST_BY_SUBCATEGORY_SUCCESS,
    GET_PRODUCTLIST_BY_SUBCATEGORY_FAILURE,
    GET_BOOKMARK_PRODUCT_LIST_REQUEST,
    GET_BOOKMARK_PRODUCT_LIST_SUCCESS,
    GET_BOOKMARK_PRODUCT_LIST_FAILURE
}

    from '../../type'



const initialState = {
    isLoading: false,
    products: [],
    error: '',
    message: '',
    count: ""

}
const productListReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_PRODUCTLIST_BY_BRAND_REQUEST:
            return {
                ...state,
                isLoading: true,
                products: []
            }

        case GET_PRODUCTLIST_BY_BRAND_SUCCESS:
            return {
                ...state,
                isLoading: false,
                products: action.payload.products,
                count: action.payload.count,
                message: action.payload.message
            }

        case GET_PRODUCTLIST_BY_BRAND_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case GET_PRODUCTLIST_BY_SUBCATEGORY_REQUEST:
            return {
                ...state,
                isLoading: true,
                products: []
            }

        case GET_PRODUCTLIST_BY_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                products: action.payload.products,
                message: action.payload.message,
                count: action.payload.count
            }

        case GET_PRODUCTLIST_BY_SUBCATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case GET_BOOKMARK_PRODUCT_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                products: []
            }

        case GET_BOOKMARK_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                products: action.payload,
                message: action.payload.message,

            }

        case GET_BOOKMARK_PRODUCT_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }




        default:
            return state
    }

}

export default productListReducer