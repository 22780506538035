import {
    CMS_URL
  } from "../../api/urls";
  import axiosInstance from "../../api/axiosInstance";

export const fetchCMSList = (cmsid,setCmsState) => {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        try {
          axiosInstance
            .post(CMS_URL, cmsid)
            .then((response) => {
              let res = JSON.parse(response.data);
  
              if (response.status === 200) {
                setCmsState(res)
                resolve();
              }
              
            })
            .catch((error) => {
              reject(error.message)
            });
        } catch (e) {}
      });
  };