import * as Yup from "yup";
const countryCodeRegex = /^[+][0-9]{2,3}$/;
const numbersOnlyRegex = /^[0-9]*$/;
const gstRegex =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[1-9A-Z]{1}[0-9A-Z]{1}$/;
const mobileNumberRegex =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// const mobileNumberRegex = /(\+91\ )[6-9]{1}[0-9 ]{4}[0-9 ]{4}[0-9]{3}/;

export const signupValidation = Yup.object({
  fullName: Yup.string().required("Name is Required"),
  email: Yup.string().email().required("Email is Required"),
  countryCode: Yup.string()
    .required("Country Code is Required")
    .matches(countryCodeRegex, "Invalid"),
  mobile: Yup.string()
    .required("Mobile Number is Required")
    .matches(mobileNumberRegex, "Invalid Number")
    .test(
      "len",
      "Must be exactly 10 characters",
      (val) => val && val.length === 10
    ),
  password: Yup.string().required("Password is Required"),
  // userType: Yup.string().required(
  //   "Please Select Either of Individual Or Business"
  // ),
  userType: Yup.string().test(
    "is-no",
    "Please Select Either of Individual Or Business",
    (value) => value
  ),
  // validId: Yup.string()
  //   .required("Valid Id is Required")
  //   .test(
  //     "len",
  //     "Must be exactly 12 characters",
  //     (val) => val && val.length === 12
  //   ),

  aadhar: Yup.string().when("userType", (userType, schema) =>
    userType === "individual"
      ? Yup.string()
          .required()
          .matches(numbersOnlyRegex, "Invalid Aadhar Number")
          .test(
            "len",
            "Must be exactly 12 characters",
            (val) => val && val.length === 12
          )
      : schema
  ),
  gst: Yup.string().when("userType", (userType, schema) =>
    userType === "business"
      ? Yup.string()
          .required()
          .matches(gstRegex, "Invalid GST Number")
          .test(
            "len",
            "Must be exactly 15 characters",
            (val) => val && val.length === 15
          )
      : schema
  ),
  // aadhar: Yup.string()
  // .required("Valid Id is Required")
  // .matches(numbersOnlyRegex, "Invalid Aadhar Number")
  // .test(
  //   "len",
  //   "Must be exactly 12 characters",
  //   (val) => val && val.length === 12
  // ),
  // gst: Yup.string()
  //   .required("Required")
  //   .matches(gstRegex, "Invalid GST Number")
  //   .test(
  //     "len",
  //     "Must be exactly 15 characters",
  //     (val) => val && val.length === 15
  //   ),
});

export const loginValidation = Yup.object().shape({
  loginEmail: Yup.string().required("Email or Mobile Number is Required"),
  loginPassword: Yup.string().required("Password is Required"),
});
