import React from 'react'

import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Reviews = () => {
    var settings = {
        slidesToShow: 1,
        arrows: true,
        dots: false,
        infinite: true,
      speed: 300,
        swipeToSlide: true,
        margin:30,
        responsive: [{
            breakpoint: 2500,
            settings: {
                slidesToShow: 4
            }
        },{
            breakpoint: 1900,
            settings: {
                slidesToShow: 4
            }
        }, {
            breakpoint: 1600,
            settings: {
                slidesToShow: 3
            }
        }, {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3
            }
        }, {
            breakpoint: 992,
            settings: {
                slidesToShow: 2
            }
        }, {
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        }, {
            breakpoint: 576,
            settings: {
                arrows: false,
                slidesToShow: 1
            }
        }, {
            breakpoint: 450,
            settings: {
                arrows: false,
                slidesToShow: 1
            }
        }, {
            breakpoint: 300,
            settings: {
                arrows: false,
                slidesToShow: 1
            }
        }]
      };
    return (
        <>
              <div className="container my-3">
                <div className="row section-heading text-center">
                    <div className="col-12 main-heading">
                        <h3 className="fw-bold">Here is what our customers say about us.</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="testimonial-carousel">
                            <Slider {...settings}>
                            <div className="m-3 py-1">
                                <div className="card border-0 mb-3 shadow">
                                    <div className="card-body">
                                        <div className="text-light-gray mt-3">
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                        <div className="card-img mt-3">
                                            <p className="fw-bold">Best Quality</p>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                        <div>
                                            <p className="fw-bold">Gaurav Kumar</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="m-3 py-1">
                                <div className="card border-0 mb-3 shadow">
                                    <div className="card-body">
                                        <div className="text-light-gray mt-3">
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                        <div className="card-img mt-3">
                                            <p className="fw-bold">Best Quality</p>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                        <div>
                                            <p className="fw-bold">Gaurav Kumar</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="m-3 py-1">
                                <div className="card border-0 mb-3 shadow">
                                    <div className="card-body">
                                        <div className="text-light-gray mt-3">
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                        <div className="card-img mt-3">
                                            <p className="fw-bold">Best Quality</p>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                        <div>
                                            <p className="fw-bold">Gaurav Kumar</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="m-3 py-1">
                                <div className="card border-0 mb-3 shadow">
                                    <div className="card-body">
                                        <div className="text-light-gray mt-3">
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star text-success"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                        <div className="card-img mt-3">
                                            <p className="fw-bold">Best Quality</p>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                        <div>
                                            <p className="fw-bold">Gaurav Kumar</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                      </Slider>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container px-xl-4">
                <div className="row">
                    <div className="col-xl-10 col-lg-11 mx-auto">
                        <div className="row">
                            <div className="col-md-6 my-3 d-flex">
                                <div className="card bg-lighter-success text-center border-success w-100">
                                    <div className="card-body p-xl-5 p-4">
                                        <h5 className="fw-bold">Need help with choosing products or ordering?</h5>
                                        <div className="row">
                                            <div className="col-sm-6 my-2">
                                                <a href="tel:919876543210" className="text-dark text-center text-decoration-none">
                                                    <div className="d-flex justify-content-center text-success">
                                                        <span className="mx-2"><i className="fas fa-phone-alt"></i></span>
                                                        <span className="mx-2"><i className="fab fa-whatsapp-square"></i></span>
                                                    </div>
                                                    <div className="text-dark">
                                                        +91 9876543210
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-sm-6 my-2">
                                                <a href="tel:919876543210" className="text-dark text-center text-decoration-none">
                                                    <div className="d-flex justify-content-center text-success">
                                                        <span className="mx-2"><i className="fas fa-envelope"></i></span>
                                                    </div>
                                                    <div className="text-dark">
                                                        abc@xyz.com
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3 d-flex">
                                <div className="card bg-lighter-success border-success w-100">
                                    <div className="card-body p-xl-5 p-4">
                                        <h5 className="fw-bold  text-center">What we offer?</h5>
                                        <div className="text-left col-auto mx-auto">
                                            <ul className="list-unstyled">
                                                <li><span className="text-success mx-2"><i className="fas fa-check-circle"></i></span><span>Top customer service. All 7 Days</span></li>
                                                <li><span className="text-success mx-2"><i className="fas fa-check-circle"></i></span><span>Free SHIPPING</span></li>
                                                <li><span className="text-success mx-2"><i className="fas fa-check-circle"></i></span><span>Fast Delivery</span></li>
                                                <li><span className="text-success mx-2"><i className="fas fa-check-circle"></i></span><span>Easy Exchange</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Reviews
