import {
  ADD_ADDRESS_REQUEST,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAILURE,
  GET_SAVED_ADDRESS_REQUEST,
  GET_SAVED_ADDRESS_SUCCESS,
  GET_SAVED_ADDRESS_FAILURE,
  GET_ADDRESSBY_ID_REQUEST,
  GET_ADDRESSBY_ID_SUCCESS,
  GET_ADDRESSBY_ID_FAILURE,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAILURE,
  REMOVE_ADDRESS_REQUEST,
  REMOVE_ADDRESS_SUCCESS,
  REMOVE_ADDRESS_FAILURE,
} from "../../../store/type";

import {
  ADD_SHIPPING_ADDRESS_URL,
  GET_SAVED_ADDRESS_URL,
  GET_ADDRESS_BY_ID_URL,
  UPDATE_ADDRESS_URL,
  REMOVE_ADDRESS_URL,
} from "../../../api/urls";

import axiosInstance from "../../../api/axiosInstance";

export const addShippingAddressAction = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(addShippingAddressRequest());
      try {
        axiosInstance
          .post(ADD_SHIPPING_ADDRESS_URL, data)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(addShippingAddressRequest(res));
              resolve();
            } else {
              dispatch(addShippingAddressSuccess(res.message));
              reject();
            }
          })
          .catch((error) => {
            console.log(error.message);
            dispatch(addShippingAddressFailure(error.message));
          });
      } catch (e) {}

      // alert(error)
    });
};

export const getSavedAddress = () => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(getSavedAddressRequest());
      try {
        axiosInstance
          .post(GET_SAVED_ADDRESS_URL)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(getSavedAddressSuccess(res.info));
              resolve();
            } else {
              dispatch(getSavedAddressFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            dispatch(getSavedAddressFailure(error.message));
          });
      } catch (e) {}

      // alert(error)
    });
};

export const getAddressById = (addressId) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(getAddressByIdRequest());
      try {
        axiosInstance
          .post(GET_ADDRESS_BY_ID_URL, addressId)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(getAddressByIdSuccess(res.address[0]));
              resolve();
            } else {
              dispatch(getAddressByIdFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            dispatch(getAddressByIdFailure(error.message));
          });
      } catch (e) {}

      // alert(error)
    });
};

export const updateAddress = (address) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(updateAddressRequest());
      try {
        axiosInstance
          .post(UPDATE_ADDRESS_URL, address)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(updateAddressSuccess(res.info));
              resolve();
            } else {
              dispatch(updateAddressFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            dispatch(updateAddressFailure(error.message));
          });
      } catch (e) {}

      // alert(error)
    });
};

export const removeAddress = (addressId) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(removeAddressRequest());
      try {
        axiosInstance
          .post(REMOVE_ADDRESS_URL, addressId)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(removeAddressSuccess(res));
              resolve();
            } else {
              dispatch(removeAddressFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            dispatch(removeAddressFailure(error.message));
          });
      } catch (e) {}

      // alert(error)
    });
};

const addShippingAddressRequest = () => {
  return {
    type: ADD_ADDRESS_REQUEST,
  };
};

const addShippingAddressSuccess = (success) => {
  return {
    type: ADD_ADDRESS_SUCCESS,
    payload: success,
  };
};

const addShippingAddressFailure = (error) => {
  return {
    type: ADD_ADDRESS_FAILURE,
    payload: error,
  };
};

const getSavedAddressRequest = () => {
  return {
    type: GET_SAVED_ADDRESS_REQUEST,
  };
};

const getSavedAddressSuccess = (address) => {
  return {
    type: GET_SAVED_ADDRESS_SUCCESS,
    payload: address,
  };
};

const getSavedAddressFailure = (error) => {
  return {
    type: GET_SAVED_ADDRESS_FAILURE,
    payload: error,
  };
};

const getAddressByIdRequest = () => {
  return {
    type: GET_ADDRESSBY_ID_REQUEST,
  };
};

const getAddressByIdSuccess = (address) => {
  return {
    type: GET_ADDRESSBY_ID_SUCCESS,
    payload: address,
  };
};

const getAddressByIdFailure = (error) => {
  return {
    type: GET_ADDRESSBY_ID_FAILURE,
    payload: error,
  };
};

const updateAddressRequest = () => {
  return {
    type: UPDATE_ADDRESS_REQUEST,
  };
};

const updateAddressSuccess = (address) => {
  return {
    type: UPDATE_ADDRESS_SUCCESS,
    payload: address,
  };
};

const updateAddressFailure = (error) => {
  return {
    type: UPDATE_ADDRESS_FAILURE,
    payload: error,
  };
};

const removeAddressRequest = () => {
  return {
    type: REMOVE_ADDRESS_REQUEST,
  };
};

const removeAddressSuccess = (address) => {
  return {
    type: REMOVE_ADDRESS_SUCCESS,
    payload: address,
  };
};

const removeAddressFailure = (error) => {
  return {
    type: REMOVE_ADDRESS_FAILURE,
    payload: error,
  };
};
