import React from 'react'

const ProductRateStarRating = () => {
    return (
        <div class="d-flex">
            <div class="pro-rating-icon h5 mt-3 text-light-gray">
                <ul class="d-flex small list-unstyled">
                    <li class="pe-1"><i class="fa fa-star text-warning"></i></li>
                    <li class="pe-1"><i class="fa fa-star text-warning"></i></li>
                    <li class="pe-1"><i class="fa fa-star text-warning"></i></li>
                    <li class="pe-1"><i class="fa fa-star text-warning"></i></li>
                    <li class="pe-1"><i class="fa fa-star"></i></li>
                </ul>
            </div>
        </div>
    )
}

export default ProductRateStarRating
