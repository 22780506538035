import React, { useEffect } from "react";
import BestSellingProducts from "../components/homePage/BestSellingProducts";
import FeaturedProducts from "../components/homePage/FeaturedProducts";
import Footer from "../components/homePage/Footer";
import Header from "../components/homePage/Header";
import InfoBar from "../components/homePage/InfoBar";
import ProductBanners from "../components/homePage/ProductBanners";
import ProductCarousel from "../components/homePage/ProductCarousel";
import Reviews from "../components/homePage/Reviews";
import ShopByBrand from "../components/homePage/ShopByBrand";
import ShopByCatagory from "../components/homePage/ShopByCatagory";
import Banner2 from "../components/homePage/Banner2";
import Benefits from "../components/homePage/Benefits";
import Banner from "../components/homePage/Banner";
import { useDispatch } from "react-redux";

import { fetchCartProductId } from "../store/actions/cartAction";
import { isAuth } from "../utils/auth";
import { Link } from "react-router-dom";

function HomeScreen() {
  const dispatch = useDispatch();
  const authed = isAuth();

  useEffect(() => {
    if (authed) {
      dispatch(fetchCartProductId());
    }
  }, [dispatch, authed]);

  return (
    <div>
      <Header />
      <main className="page-wrapper">
        <div className="banner">
          <div className="container-fluid px-xxl-5 px-xl-4">
            <div className="row">
              <div className="col-xl-3 d-xl-block d-none">
                <ShopByCatagory />
              </div>

              <div className="col-xl-9">
                <Banner />
              </div>
            </div>
          </div>
        </div>
        <section>
          <ShopByBrand />
        </section>
        <section className="featured-product">
          <FeaturedProducts />
        </section>
        <section className="bg-success">
          {/* <InfoBar /> */}
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center mx-auto">
                <h3 className="text-white mb-3"> Make your own quotation</h3>
                <Link className="text-center btn btn-outline-white px-5" to="/my-quotation">
                  Click here
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="">
          <ProductCarousel type="Best Deals" title="Best Deals" />
        </section>
        <section className="best-product">
          <BestSellingProducts />
        </section>
        <section className="product-banners">
          <ProductBanners />
        </section>
        <section className="bg-lighter-gray">
          <Benefits />
        </section>
        <section className="">
          <ProductCarousel
            // type={`Household Need`}
            type="Household Need"
            title="Shop By Household Need"
          />
        </section>
        <section className="py-0">
          <ProductCarousel type="Medical Need" title="Shop By Medical Need" />
        </section>
        <section className="">
          <ProductCarousel type="Office Need" title="Shop By Office Need" />
        </section>
        <section className="bg-lighter-gray">
          <ProductCarousel title="Recently Viewed" />
        </section>
        <section className="bg-success">
          <InfoBar />
        </section>
        <section className="bg-lighter-gray">
          <Banner2 />
        </section>
        <section className="testimonial">
          <Reviews />
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default HomeScreen;
