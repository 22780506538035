import React from 'react';
// import filterIcon1 from '../../assets/images/icons/filter-icon-1.png'
// import filterIcon2 from '../../assets/images/icons/filter-icon-2.png'
import '../../assets/css/product-custom.css';
const SortLowToHigh = ({ setShowLargeComponent, setShowSmallComponent, showSmallComponent, showLargeComponent, onClickHandler, onSortChange, priceSorterValue }) => {

    return (

        <div className="d-flex">
            <div className="d-inline">
                <div className="input-group input-group-sm flex-nowrap">
                    <span className="input-group-text bg-white " id="addon-wrapping1">Sort By:</span>
                    <select className="form-select form-select-sm" aria-label=".form-select-sm example" onChange={onSortChange}
                        value={priceSorterValue}
                    >
                        {/* <option value="" disabled>select</option> */}
                        <option value="1">Low to High</option>
                        <option value="-1">High to Low</option>
                    </select>
                </div>
            </div>
            {/* <div className="px-4 d-inline filter-icon1">
                <img src={filterIcon1} alt="filter-icon-1" onClick={onClickHandler} />
            </div>
            <div className="d-inline left-menu filter-icon2" >
                <img src={filterIcon2} alt="filter-icon-2" className="filter-icon2" />
            </div> */}
        </div>

    )
}
export default SortLowToHigh