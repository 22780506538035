import React, { useEffect, useState } from "react";
import Footer from "../components/homePage/Footer";
import Header from "../components/homePage/Header";
import Address from "../components/order/Address";
import PriceSummary from "../components/order/PriceSummary";
import { Link } from "react-router-dom";
// import CheckoutBtnPriceSummery from "../components/order/CheckoutBtnPriceSummery";
import { getCartPriceAction } from "../store/actions/cartAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/loader";
import { getSavedAddress } from "../store/actions/address/addAddressAction";

const ShippingScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  const cartDetails = useSelector((state) => state.cart.cartDetails);
  const [cartOrderSummary, setCartOrderSummary] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.cart.isLoading);
  const addressLoading = useSelector((state) => state.address.isLoading);
  const address = useSelector((state) => state.address.address);
  const [activeAddress, setActiveAddress] = useState(null);

  useEffect(() => {
    setCartOrderSummary(cartDetails);
    setIsLoading(loading || addressLoading);
    setAddressList(address);
  }, [cartDetails, loading, addressLoading, address]);

  useEffect(() => {
    dispatch(
      getCartPriceAction({
        type: "cod",
        voucherCode: "",
      })
    );
    dispatch(getSavedAddress());
  }, [dispatch]);

  const proceBlur = {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255,255,255, 0.5)",
    zIndex: 99,
    cursor: "pointer",
  };

  return (
    <div>
      <Header />
      {isLoading ? (
        <Loader />
      ) : (
        <main className="page-wrapper">
          <section className="pt-3">
            <div className="container px-sm-5">
              <div className="row">
                <div className="col-xl-8 col-lg-7 pt-3">
                  <div className="mb-3">
                    <h5 className="fw-medium">Select Address</h5>
                  </div>
                  {addressList &&
                    addressList.map((address, index) => (
                      <div
                        className="card card-shipping border-0 shadow mb-3"
                        key={index}
                      >
                        <Address
                          title="Home"
                          id="home-address"
                          name={address.firstName + " " + address.lastName}
                          address={address.area}
                          city={address.city}
                          state={address.state}
                          contact={address.phoneNumber}
                          addressType="Home"
                          addressId={address.addressId}
                          completeAddress={address}
                          pincode={address.pincode}
                          setActiveAddress={setActiveAddress}
                        />
                      </div>
                    ))}

                  <div className="d-grid btn-holder">
                    <Link
                      // to="/add-address"
                      to={`/add-new-address?fromOrder=true`}
                      className="btn btn-outline-light-success"
                    >
                      <i className="fas fa-plus"></i> Add a new address
                    </Link>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-5 pt-3 position-relative">
                  {activeAddress === null ? (
                    <div style={proceBlur}></div>
                  ) : null}
                  <div className="card shadow border-0 cart-box py-4 px-2">
                    <PriceSummary cartDetails={cartOrderSummary?.order} />
                    <div className="d-grid">
                      <Link
                        to={`/review-pay?addressId=${activeAddress}`}
                        className="btn btn-light-success py-2 rounded-0"
                      >
                        Proceed to Checkout
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
      <Footer />
    </div>
  );
};

export default ShippingScreen;
