import {
  GET_BRANDLIST_REQUEST,
  GET_BRANDLIST_SUCCESS,
  GET_BRANDLIST_FAILURE,
} from "../type";

import { GET_BRANDLIST_URL } from "../../api/urls";

import axiosInstance from "../../api/axiosInstance";


const getBrandListRequest = () => {
    return {
      type: GET_BRANDLIST_REQUEST,
    };
  };
  
  const getBrandListSuccess = (brandList) => {
    return {
      type: GET_BRANDLIST_SUCCESS,
      payload: brandList,
    };
  };
  
  const getBrandListFailure = (error) => {
    return {
      type: GET_BRANDLIST_FAILURE,
      payload: error,
    };
  };
  

export const getBrandList = () => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(getBrandListRequest());
      try {
        axiosInstance
          .get(GET_BRANDLIST_URL)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(getBrandListSuccess(res.data.brands));
              resolve();
            } else {
              dispatch(getBrandListFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            console.log(error.message);
            dispatch(getBrandListFailure(error.message));
          });
      } catch (e) {}

      // alert(error)
    });
};
