import {
    WRITE_PRODUCT_REVIEW_REQUEST,
    WRITE_PRODUCT_REVIEW_SUCCESS,
    WRITE_PRODUCT_REVIEW_FAILURE
}
    from '../../type'



const initialState = {
    isLoading: true,
    reviews: [],
    error: '',
    message: ''

}
const productReview = (state = initialState, action) => {
    switch (action.type) {

        case WRITE_PRODUCT_REVIEW_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case WRITE_PRODUCT_REVIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                order: action.payload,
                message: action.payload.message
            }

        case WRITE_PRODUCT_REVIEW_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        default:
            return state
    }

}

export default productReview