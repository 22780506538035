import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { slickConfig } from "../../utils/settings";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../assets/css/new.css";

// import "../../assets/css/new.css"
function ShopByCategoryFilter({
  specificCatFilter,
  setSpecificCatFilter,
  specificFilterList,
  setSpecificFilterList,
}) {
  console.log("shopbyCategory----------", specificFilterList);
  const [active, setActive] = useState(false);

  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,

    slidesToScroll: 1,
    // prevArrow: <SamplePrevArrow/>,

    // nextArrow: <SampleNextArrow/>,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  const filterProduct = (catId) => {
    // console.log("clcic",catId)
    setSpecificCatFilter([catId]);
    // setSelectedBrand(brandId)
    setActive(catId);
    // setActive(true)
  };

  // const setBrand = (event, brand) => {
  //   console.log(event.target.value)

  //   if(selectedBrand.includes(brand.brandId)){
  //         setSelectedBrand(selectedBrand.filter(item=>item!==brand.brandId))
  //       }
  //   if(!selectedBrand.includes(brand.brandId)){
  //         setSelectedBrand([...selectedBrand,brand.brandId])
  //       }
  //   // const path = location.path;
  //   // console.log(brand);
  //   // history.push({
  //   //   pathname: path,
  //   //   search: `?category=${categoryId}&catname=${categoryName}&brand=${brand.brandId}&brandName=${brand.name}`,
  //   // });
  // };

  return (
    <>
      <div className="container four-products-btn mb-3 ">
        <div className="row section-heading text-center">
          <div className="col-12 main-heading">
            <h6 className="fw-bold">Shop By Category</h6>
          </div>
        </div>

        <div className="four-products-carousel  ">
          {/* <Slider {...slickConfig(state.brands?.length > 2)}> */}
          <div className="slider-custom-space">
            <Slider {...settings}>
              {specificFilterList?.length > 0 &&
                specificFilterList.map((cat, index) => (
                  <div
                    key={index}
                    onClick={() => filterProduct(cat.category?.categoryid)}
                    className={`mt-2 p-3 rounded-3 shadow-sm ${
                      active === cat.category?.categoryid ? "activeK" : ""
                    }`}
                  >
                    <img
                      src={cat.category?.bannerImages?.[0]}
                      alt={cat.name}
                      style={{ height: 100 }}
                      className="mx-auto img-fluid"
                    />
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShopByCategoryFilter;
