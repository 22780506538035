import React from 'react'
import AmountFormat from '../amountFormat'

const ReviewPayAccountPaymentCard = ({ lastdigit, cardName, amount }) => {
    return (
        <div class="card card-review-pay border-0 shadow mb-3">
            <div class="card-header bg-white d-flex align-items-center justify-content-between">
                <div class="fw-medium">Payment</div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm">
                        <div class="text-light-gray mb-1">
                            <span class="small">
                                <i class="fas fa-circle"></i>
                                <i class="fas fa-circle"></i>
                                <i class="fas fa-circle"></i>
                                <i class="fas fa-circle"></i>
                                <span class="px-2"></span>
                                <i class="fas fa-circle"></i>
                                <i class="fas fa-circle"></i>
                                <i class="fas fa-circle"></i>
                                <i class="fas fa-circle"></i>
                                <span class="px-2"></span>
                                <i class="fas fa-circle"></i>
                                <i class="fas fa-circle"></i>
                                <i class="fas fa-circle"></i>
                                <i class="fas fa-circle"></i>
                            </span>
                            <span class="px-2"></span>
                            <span class="text-dark">{lastdigit}</span>
                        </div>
                        <div class="text-light-gray">Master Credit Card</div>
                    </div>
                    <div class="col-auto">
                        <div class="mb-1 text-light-gray">You'll Pay</div>
                        <div class="mb-1 text-dark"><AmountFormat value={amount} /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewPayAccountPaymentCard
