import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import { Formik, Field, Form, ErrorMessage } from "formik";
// eslint-disable-next-line
import { Link, Redirect, useHistory } from "react-router-dom";
// import OrderSummaryItems from "../components/product/OrderSummaryItems";
// import OrderSummaryComponent from "../components/product/OrderSummaryComponent";
import * as Yup from "yup";
import Header from "../components/homePage/Header";
import Footer from "../components/homePage/Footer";
import { addShippingAddressAction } from "../store/actions/address/addAddressAction";
import Swal from "sweetalert2";
// import Loader from "../components/loader";
import { CForm } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";

let formValidation = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  countryCode: Yup.string().required("Country Code is required and has + icon").matches(/^\+(\d{1}\-)?(\d{1,3})$/),
  contact: Yup.string().required("Phone Number is required"),
  // email: Yup.string().email("Invalid Email").required("Email is required"),
  address: Yup.string()
    .required("Address is required")
    .min(10, "Address is too short"),
  city: Yup.string().required("City is required"),
  // district: Yup.string().required("District is required"),
  country: Yup.string().required("Country is required"),

  // pincode: Yup.string().required("Pincode is required"),
  pincode: Yup.string()
    .required("This field is Required")
    // .matches(/^(\s*\d{6}\s*)(,\s*\d{6}\s*)*,?\s*$/, "Invalid PinNumber")
    .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, "Invalid Pin Number")
    .min(6, "Must be 6 digits" ),
  state: Yup.string().required("State is required"),
});

const AddAdressScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const loading = useSelector((state) => state.address.isLoading);

  const query = new URLSearchParams(window.location.search);
  const fromOrder = query.get("fromOrder");

  const Alert = (title, icon, path) => {
    Swal.fire({
      title: title,
      text: "",
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Continue",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        if (fromOrder === "true") {
          history.push("/shipping");
        } else {
          history.push(path);
        }
      } else {
        if (fromOrder === "true") {
          history.push("/shipping");
        } else {
          history.push(path);
        }
      }
    });
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const handleFormSubmit = (data) => {
    const requestObj = {
      firstName: data.firstName,
      lastName: data.lastName,
      area: data.address,
      city: data.city,
      state: data.state,
      pincode: data.pincode,
      country: data.country,
      phoneNumber: data.contact,
      email: data.email,
      countryCode:data.countryCode,
    };
console.log("dtaat------",data)

    dispatch(addShippingAddressAction(requestObj)).then(
      (resolve) => {
        Alert("Address Successfully Added", "success", "/my-addresses");
      },
      (reject) => {
        Alert(
          "Something went Wrong, Please try later",
          "error",
          "/my-addresses"
        );
      }
    );
  };


  const onlyCherectorAllow = (e) => {
    const re = /^[a-zA-Z]+$/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
    const onlyNumberAllow1 =(e)=> {
      const re = /[0-9,+]+/g;
      if (!re.test(e.key)) {
        e.preventDefault();
      }
    }
  return (
    <>
      <Header />

      {/* {
                isLoading ? <Loader /> : */}

      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          countryCode:"",
          contact: "",
          email: "",
          address: "",
          city: "",
          country: "",

          pincode: "",
          state: "",
        }}
        validationSchema={formValidation}
        onSubmit={(fields) => {
          console.log("fields===>", fields);
          handleFormSubmit(fields);
        }}
        render={(formik) => (
          <>
            <main className="page-wrapper">
              <section className="pt-3">
                <div className="container px-sm-5">
                  <div className="row">
                    <div className="col-xl-8 col-lg-7 pt-3">
                      <div className="mb-3">
                        <h5 className="fw-medium">Enter Shippingqqqqqqqq  Details</h5>
                      </div>
                      <CForm
                        className="card-shipping"
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                      >
                        {/* <div className="form-group col-md-6 mb-3">
                                                    <label htmlFor="my-input" className="mb-1 text-light-gray">
                                                        Full Name*
                                                    </label>
                                                  
                                                    <Field
                                                        name="user_name"
                                                        type="text"
                                                        className={
                                                            "form-control" +
                                                            (formik.errors.user_name && formik.touched.user_name
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        name="user_name"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div> */}

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="my-input"
                                className="mb-1 text-light-gray"
                              >
                                First Name*
                              </label>
                              {/* <input id="my-input" className="form-control" type="tel" name="contact" /> */}
                              <Field
                                name="firstName"
                                type="text"
                                autoComplete="off"
                                className={
                                  "form-control" +
                                  (formik.errors.firstName &&
                                  formik.touched.firstName
                                    ? " is-invalid"
                                    : "")
                                }
                                onKeyPress={(e)=>{onlyCherectorAllow(e)}}
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="my-input"
                                className="mb-1 text-light-gray"
                              >
                                Last Name*
                              </label>

                              <Field
                                name="lastName"
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.errors.lastName &&
                                  formik.touched.lastName
                                    ? " is-invalid"
                                    : "")
                                }
                                onKeyPress={(e)=>{onlyCherectorAllow(e)}}
                              />
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                                  htmlFor="my-input"
                                  className="mb-1 text-light-gray"
                                >
                                  Phone Number*
                                </label>
                                <div className="d-flex row">
                                  <div className="col-auto">
                                    <input
                                      type="text"
                                      maxLength={3}
                                      className="form-control w-50px"
                                      id="exampleFormControlInput1"
                                      placeholder="+91"
                                      name="countryCode"
                                      onBlur={formik.handleBlur}
                                      onChange={formik.handleChange}
                                      value={formik.values.countryCode}
                                      onKeyPress={(e) => onlyNumberAllow1(e)}
                                    />
                                    <ErrorMessage
                                      name="countryCode"
                                      component="small"
                                      style={{
                                        color: "tomato",
                                        fontSize: ".6rem",
                                      }}
                                    />
                                  </div>
                                  <div className="col ps-0">
                                    {/* <input
                                      type="text"
                                      min="10"
                                      maxlength="10"
                                      className="form-control"
                                      id="exampleFormControlInput1"
                                      placeholder="Mobile Number"
                                      name="mobile"
                                      onBlur={formik.handleBlur}
                                      onChange={formik.handleChange}
                                      value={formik.values.mobile}
                                     /> */}

<Field
                                  name="contact"
                                  type="text"
                                  min="10"
                                  maxlength="10"
                                  className={
                                    "form-control" +
                                    (formik.errors.contact &&
                                    formik.touched.contact
                                      ? " is-invalid"
                                      : "")
                                  }
                                  onKeyPress={(e) => onlyNumberAllow1(e)}
                                />
                                    <ErrorMessage
                                      name="contact"
                                      component="small"
                                      style={{
                                        color: "tomato",
                                        fontSize: ".6rem",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>


                        {/* <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="my-input"
                                className="mb-1 text-light-gray"
                              >
                                Phone Number*
                              </label>
                               <Field
                                name="contact"
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.errors.contact &&
                                  formik.touched.contact
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="contact"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="my-input"
                                className="mb-1 text-light-gray"
                              >
                                Email Address*
                              </label>

                              <Field
                                name="email"
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.errors.email && formik.touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="form-group mb-3">
                          <label
                            htmlFor="my-input"
                            className="mb-1 text-light-gray"
                          >
                            Address*
                          </label>

                          <Field
                            name="address"
                            type="text"
                            className={
                              "form-control" +
                              (formik.errors.address && formik.touched.address
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="my-input"
                                className="mb-1 text-light-gray"
                              >
                                State*
                              </label>

                              <Field
                                name="state"
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.errors.state && formik.touched.state
                                    ? " is-invalid"
                                    : "")
                                }
                                onKeyPress={(e)=>{onlyCherectorAllow(e)}}
                              />
                              <ErrorMessage
                                name="state"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="my-input"
                                className="mb-1 text-light-gray"
                              >
                                City*
                              </label>

                              <Field
                                name="city"
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.errors.city && formik.touched.city
                                    ? " is-invalid"
                                    : "")
                                }
                                onKeyPress={(e)=>{onlyCherectorAllow(e)}}
                              />
                              <ErrorMessage
                                name="city"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          {/* distict is commented */}
                          {/* <div className="col-md-6">
                                                                <div className="form-group mb-3">
                                                                    <label
                                                                        htmlFor="my-input"
                                                                        className="mb-1 text-light-gray"
                                                                    >
                                                                        District*
                                                                    </label>
                                                                    <Field
                                                                        name="district"
                                                                        type="text"
                                                                        className={
                                                                            "form-control" +
                                                                            (formik.errors.district && formik.touched.district
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="district"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </div> */}
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="my-input"
                                className="mb-1 text-light-gray"
                              >
                                Country*
                              </label>

                              <Field
                                name="country"
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.errors.country &&
                                  formik.touched.country
                                    ? " is-invalid"
                                    : "")
                                }
                                onKeyPress={(e)=>{onlyCherectorAllow(e)}}
                              />
                              <ErrorMessage
                                name="country"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="my-input"
                                className="mb-1 text-light-gray"
                              >
                                Pincode*
                              </label>

                              <Field
                                name="pincode"
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.errors.pincode &&
                                  formik.touched.pincode
                                    ? " is-invalid"
                                    : "")
                                }
                                onKeyPress={(e) => onlyNumberAllow1(e)}
                              />
                              <ErrorMessage
                                name="pincode"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group">
                            <button
                              className="btn "
                              type="submit"
                              style={{
                                backgroundColor: "#67AC65",
                                color: "white",
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </CForm>
                    </div>
                  </div>
                </div>
              </section>
            </main>

            <div
              className="modal"
              id="order-placed-success-modal"
              tabindex="-1"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body px-4 pb-4">
                    <div className="close">
                      <button
                        type="button"
                        className="btn-close size-50px"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="my-3 pt-3 text-center">
                      <div className="d-flex justify-content-center">
                        <div className="img-holder size-80px">
                          <img
                            className="img-fluid w-100"
                            src="images/icons/checked-circle.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <h4 className="fw-medium text-light-success my-3">
                        Yayy! Order Placed.
                      </h4>
                      <p>
                        Lorem ipsum, or lipsum as it is sometimes known, is
                        dummy text used in laying out print, graphic or web
                        designs Lorem ipsum, or lipsum as it is sometimes known,
                        is Click Here to explore more products .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      />

      <Footer />
    </>
  );
};

export default AddAdressScreen;
