import React, { useEffect } from "react";
import CartIcon from "../../assets/images/icons/add-to-cart.png";
import { Link } from "react-router-dom";
import AmountFormat from "../amountFormat";
import Slider from "react-slick";
import ShopByBrand from "../homePage/ShopByBrand";
import "../../../src/assets/scss/card.scss";
import {
  addToCartAction,
  removeCartItem,
} from "../../store/actions/cartAction";
import { useDispatch, useSelector } from "react-redux";
import { isAuth } from "../../utils/auth";
import { useToasts } from "react-toast-notifications";
import { useState } from "react";

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      // className="'h4 btn-light bg-white shadow text-danger py-3 px-2 slick-prev slick-arrow position-absolute top-50 start-0 translate-middle'"

      className="h4 btn-light bg-white shadow text-danger py-3 px-2   slick-arrow position-absolute top-50 start-0 translate-middle"
      onClick={onClick}
    >
      <i className="bi bi-chevron-left"></i>
    </div>
  );
}
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="h4 btn-light bg-white shadow text-danger py-3 px-2  slick-arrow position-absolute top-50 start-100 translate-middle"
      onClick={onClick}
    >
      <i className="bi bi-chevron-right"></i>
    </div>
  );
}
function CardComponent({
  Image,
  productName,
  price,
  rating,
  product,
  addProductToCart,
  addToWishlist,
  isWishlist,
}) {
  console.log("======product======", product);
  // console.log("======product======", product.brand?.id?.name);

  // var settings = {
  //   dots: false,
  //   arrows: true,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 5,

  //   slidesToScroll: 1,
  //   prevArrow: <SamplePrevArrow />,

  //   nextArrow: <SampleNextArrow />,
  //   responsive: [
  //     {
  //       breakpoint: 1600,
  //       settings: {
  //         slidesToShow: 6,
  //       },
  //     },
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         slidesToShow: 5,
  //       },
  //     },
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 5,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 5,
  //       },
  //     },
  //     {
  //       breakpoint: 576,
  //       settings: {
  //         slidesToShow: 4,
  //       },
  //     },
  //     {
  //       breakpoint: 430,
  //       settings: {
  //         slidesToShow: 4,
  //       },
  //     },
  //     {
  //       breakpoint: 320,
  //       settings: {
  //         slidesToShow: 3,
  //       },
  //     },
  //   ],
  // };

  const cart = useSelector((state) => state.cart);
  const authed = isAuth();
  const dispatch = useDispatch();
  const [maxQuantity, setMaxQuantity] = useState([]);
  // console.log("maxQunayitu===", maxQuantity[maxQuantity.length-1]);
  const quantityIntoApi=maxQuantity[maxQuantity.length-1]

  useEffect(() => {
    let arr = [];
    product.variants.map((items) => {
      if (items) {
        arr.push(items?.name?.split("-")[1]);
      }
      setMaxQuantity(arr)
    });
  }, [product]);

  const { addToast } = useToasts();
  const addToCart = (product) => {
    const requestObj = {
      productId: product.productId,
      variantId: product?.variants?.length
        ? product?.variants[0].variantId
        : null,
      quantity: quantityIntoApi,
    };

    if (authed && product?.variants?.length > 0) {
      if (cart?.cartIds?.includes(product.productId)) {
        dispatch(removeCartItem(requestObj)).then((result) => {
          addToast("Prodcut Removed in Cart", {
            appearance: "error",
          });
        });
      } else {
        dispatch(addToCartAction(requestObj)).then((result) => {
          addToast("Prodcut Added to Cart", {
            appearance: "success",
          });
        });
      }
    } else {
      addToast("Please Login", { appearance: "error" });
    }
  };



  // const  products
  return (
    <>
      {/* <div className="col-lg-4 col-md-6 col-sm-6 my-2">
        <div className="products-box">
          <div className="m-3 position-relative">
            <Link
              className="products-box"
              to={{
                pathname: "/product-details",
                search: `?product=${product?.productId}&category=${
                  product?.category && product?.category?.categoryid
                }&catname=${
                  product?.category && product?.category?.id?.categoryname
                }&subcategory=${
                  product?.subCategory && product?.subCategory?.categoryid
                }&subcatname=${
                  product?.subCategory && product?.subCategory?.id?.categoryname
                }`,

                state: { fromDashboard: true },
              }}
            >
              <img
                src={Image}
                alt="pro-img"
                className="w-100 shadow p-3"
                style={{ height: "18rem" }}
              />
            </Link>
            <div className="heart-icon">
              {isWishlist() ? (
                <i
                  onClick={addToWishlist}
                  className="text-success  fas fa-heart sprite-icon icon-full-screen w-30p cursorPointer"
                ></i>
              ) : (
                <i
                  onClick={addToWishlist}
                  className="far fa-heart cursorPointer"
                ></i>
              )}
            </div>
          </div>

          <Link
            className="products-box"
            to={{
              pathname: "/product-details",
              search: `?product=${product?.productId}&category=${
                product?.category && product?.category?.categoryid
              }&catname=${
                product?.category && product?.category?.id?.categoryname
              }&subcategory=${
                product?.subCategory && product?.subCategory?.categoryid
              }&subcatname=${
                product?.subCategory && product?.subCategory?.id?.categoryname
              }`,

              state: { fromDashboard: true },
            }}
          >
            <div className="products-text px-3 d-flex justify-content-between">
              <div>
                <div className="pro-title">{productName}</div>
                <div className="pro-price">
                  {" "}
                  <AmountFormat value={price} />
                </div>
                <div className="pro-rating-icon d-flex justify-content-between">
                  <ul className="d-flex list-unstyled">
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                    </li>
                    <li>
                      <i className="fas fa-star-half-alt"></i>
                    </li>
                    <li className="px-2 text-light-gray">{rating}</li>
                  </ul>
                </div>
              </div>
              <div className="align-self-center">
                <img src={CartIcon} alt="add-to-cart" />
                {/* {/* On click on this will add product to the cart 
           {/* <img src={CartIcon} alt="add-to-cart" onClick={() => addProductToCart(product)} />  
              </div>
            </div>
          </Link>
        </div>
      </div> */}
      {/* {product.variants.map((items)=>{
       
       
          setMaxQuantity(items?.name?.split("-")[1])
    
  })
} */}
      <div className="col-lg-4 mb-3 px-2 ">
        <div
          className="card border px-3 py-0 card-shadow"
          // style={{ width: "15rem" }}
        >
          <div className="col">
            <div className="row">
              <div className="col-12 d-flex">
                <div className="col">
                  <div className="btn btn-success rounded m-0 text-white fw-bold cost-font w-25 p-1 m-1">
                    <span className="img-text">{product?.discount}% OFF</span>
                  </div>
                </div>

                <div className="heart-icon">
                  {isWishlist() ? (
                    <i
                      onClick={addToWishlist}
                      className="text-success  fas fa-heart sprite-icon icon-full-screen w-30p cursorPointer"
                    ></i>
                  ) : (
                    <i
                      onClick={addToWishlist}
                      className="far fa-heart cursorPointer"
                    ></i>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <Link
              className="products-box"
              to={{
                pathname: "/product-details",
                search: `?product=${product?.productId}&category=${
                  product?.category && product?.category?.categoryid
                }&catname=${
                  product?.category && product?.category?.id?.categoryname
                }&subcategory=${
                  product?.subCategory && product?.subCategory?.categoryid
                }&subcatname=${
                  product?.subCategory && product?.subCategory?.id?.categoryname
                }`,

                state: { fromDashboard: true },
              }}
            >
              <img
                src={Image}
                className="card-img-top img-fluid shadow-sm object-fit-cover mx-auto pb-0 d-flex justify-content-center w-100"
                alt="..."
                // style={{ width: "240px" }}
              />
            </Link>
          </div>
          <div className="card-body pt-1">
            <div className="card-text">
              <p className="para-font fw-bold mb-1 text-truncate">
                Ref No: {product.productCode}
                <br />
                <span className="text-truncate2 ">
                  {" "}
                  Brand Name: {product?.brand?.id?.name}
                </span>
              </p>
              <div
                className="text-truncate2 fw-bold mb-1 mt-0 text-truncate"
                style={{ fontSize: "large" }}
              >
                {" "}
                {product.title}
              </div>
            </div>
            <div className="col">
              <div className="row">
                <div className="col-md-12">
                  <div className="row pb-0 pt-0">
                    <div class="d-flex bd-highlight">
                      <div class=" flex-grow-1    ">
                        <b>Price:-</b>
                        <span>
                          ₹
                          {/* {product.price -
                            (product.price * product?.discount) / 100} */}
                            {product.price}
                        </span>
                      </div>
                      <div class="bd-highlight">
                        <b>MRP:- </b>{" "}
                        <span className="para-font">
                          <del>₹{product.MRP}</del>
                        </span>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div
                        onClick={() => addToCart(product)}
                        className="btn btn-success rounded-0 m-0 w-100 text-white p-1"
                      >
                        {cart?.cartIds?.includes(product.productId)
                          ? "Already in Cart"
                          : " Add to Cart"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardComponent;
