import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuth } from "../utils/auth";

const PrivateRoute = ({ LoadComponent, ...rest }) => {
  const authed = isAuth();
  return (
    <React.Fragment>
      <Route
        render={(props) =>
          authed ? (
            <LoadComponent {...props} />
          ) : (
            <Redirect
              to={{
                from: "*",
                pathname: "/",
              }}
            />
          )
        }
      />
    </React.Fragment>
  );
};
export default PrivateRoute;
