import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { isAuth, isUser } from "../../utils/auth";
import "react-image-gallery/styles/css/image-gallery.css";
import ReactImageGallery from "react-image-gallery";

const Item = ({ baseImage, imageArray, productId, addBookmark, product }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  // console.log("imageArray", imageArray, baseImage);
  function isWishlist() {
    const user = isUser();
    return isAuth() ? product.bookmarkedBy?.includes(user.userId) : isAuth();
  }

  function duplicateImage() {
    if (imageArray.length >= 4) {
      return imageArray;
    } else {
      let ig = [];
      for (let i = imageArray.length; i <= 5; i++) {
        ig.push(imageArray[0]);
      }
      return imageArray.concat(ig);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let slider1 = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let slider2 = [];

  // useEffect(() => {
  //   setNav1(slider1);
  //   setNav2(slider2);
  // }, [slider1, slider2]);

  var imgSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    swipeToSlide: true,
    // autoplay:true
  };
  var settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 300,
    slidesToShow: 1,
    swipeToSlide: true,
    // autoplay:true,

    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 450,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 300,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
    ],
  };

  const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];

  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    let arr = [];
    if (imageArray.length > 0) {
      imageArray.forEach((img) => {
        // console.log(img);
        arr.push({
          original: img.url,
          thumbnail: img.url,
        })
      })
      setImageList(arr)
    }
  }, [imageArray]);

  // console.log(imageList)

  return (
    <div className="product-item-show">
      <div className="card border-0 product product-simple">
        <div className="product-simple-top bg-lighter-primary shadow-none">
          <div className="product-item-view" id="product-item-view">
            <div>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn"
                  data-toggle="modal"
                  data-target="#product-item-show-modal"
                >
                  {isWishlist() ? (
                    <span
                      className="text-success  fas fa-heart sprite-icon icon-full-screen w-30p"
                      onClick={() => addBookmark()}
                    ></span>
                  ) : (
                    <span
                      className=" text-light-gray  fas fa-heart sprite-icon icon-full-screen w-30p"
                      onClick={() => addBookmark()}
                    ></span>
                  )}
                </button>
              </div>

              <div className="product-item-view justify-content-center align-items-center">
                <ReactImageGallery items={imageList} showPlayButton={false} />
                {/* <Slider
                  asNavFor={nav2}
                  ref={(slider) => (slider1 = slider)}
                  {...imgSettings}
                >
                  <div className="img-holder justify-content-center d-flex ">
                    <Zoom>
                      <img
                        src={baseImage}
                        alt="image1"
                        className="img-fluid w-100 p-5"

                        // height="250px"
                        // zoomWidth="500px"
                        // zoomSrc = "images/products/product5.png"
                      />
                    </Zoom>
                  </div>

                  {duplicateImage().map((image) => (
                    <div className="img-holder justify-content-center d-flex">
                      <Zoom>
                        <img
                          src={image.url}
                          alt="image1"
                          className="img-fluid w-100 p-5"
                          width="500"
                          // height="250px"
                          // zoomWidth="500px"
                          // zoomSrc = "images/products/product5.png"
                        />
                      </Zoom>
                    </div>
                  ))}

                 
                </Slider> */}
              </div>
            </div>
          </div>

          <div className="product-item-images mx-4">
            {/* <Slider
              asNavFor={nav1}
              ref={(slider) => (slider2 = slider)}
              {...settings}
            >
              {duplicateImage().map((image) => (
                <div
                  className="product-item-images-holder my-3 d-flex"
                  data-slick-index="0"
                  aria-hidden="false"
                  style={{ width: "107px;" }}
                  tabindex="0"
                >
                  <img
                    src={image.url}
                    alt="image1"
                    className="img-fluid w-100"
                  />
                </div>
              ))}
            </Slider> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
