import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { fetchCMSList } from "../store/actions/cmsAction";
import { useDispatch } from "react-redux";
import ReactHtmlParser from 'react-html-parser';

const TermsOfUse = () => {
  const dispatch = useDispatch();
  const [cmsStat, setCmsState] = useState(null)

  useEffect(() => {
    dispatch(fetchCMSList({cmsid: "useOfTerms"},setCmsState));
  }, [dispatch]);

  return (
    <Layout>
      <main className="page-wrapper">
        <div className="container px-xxl-5 px-xl-4 mt-4">
            { ReactHtmlParser(cmsStat?.html) }
        </div>
      </main>
    </Layout>
  );
};

export default TermsOfUse;
