
// Setting For sliders

export const slickConfig=(isInfinite = false)=>{
  return {
    dots: false,
    infinite: isInfinite,
    arrows: true,
    speed: 300,
    slidesToShow: 1,
    swipeToSlide: true,

    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 300,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
}


// export const settings =()=> {
//   return{
//     dots: false,
//     arrows: true,
//     infinite: true,
//     speed: 100,
//     slidesToShow: 5,
//     slidesToScroll: 2,
//     //  prevArrow: <SamplePrevArrow/>,
//     //  nextArrow: <SampleNextArrow/>,
//     // variableWidth: true,
//     responsive: [
//       {
//         breakpoint: 1400,
//         settings: {
//           slidesToShow: 4,
//         },
//       },
//       {
//         breakpoint: 1200,
//         settings: {
//           slidesToShow: 4,
//         },
//       },
//       {
//         breakpoint: 992,
//         settings: {
//           slidesToShow: 3,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 3,
//         },
//       },
//       {
//         breakpoint: 576,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 430,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 320,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   }
  
// };

export const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 100,
  slidesToShow: 5,

  slidesToScroll: 2,
  //  prevArrow: <SamplePrevArrow/>,

  //  nextArrow: <SampleNextArrow/>,
  // variableWidth: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

