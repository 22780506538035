import React from "react";
import OrderSummaryItems from "../product/OrderSummaryItems";
import OrderSummaryPriceComponent from "../product/OrderSummaryPriceComponent";

const OrderSummaryComponent = ({ products, cartSummary }) => {
  return (
    <div class="box-card small">
      {products?.length > 0 && <OrderSummaryItems products={products} />}

      <OrderSummaryPriceComponent priceSummary={cartSummary} />
    </div>
  );
};

export default OrderSummaryComponent;
