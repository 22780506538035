import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { slickConfig } from "../../utils/settings";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// import "../../assets/css/new.css"
function ShopByBrandFilter({
  selectedBrandFilter,
  setSelectedBrandFilter,
  specificFilterList,
}) {
  const state = useSelector((state) => state.brands);
  const [active, setActive] = useState([]);
  // const[active,setActive]=useState(false)
 const itemBox = specificFilterList.length;
//  console.log(itemBox, "tesglkajslkjfsaldk fjslkdj")
{console.log("specificFilterList?????????>>>>>>>>",specificFilterList)}

  const settings = {
    dots: false,
    arrows: true,
    infinite: itemBox>6?true:false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    // prevArrow: <SamplePrevArrow/>,

    // nextArrow: <SampleNextArrow/>,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  const filterProduct = (brandId) => {
    console.log("clcic", brandId);
    setSelectedBrandFilter([brandId]);
    // setSelectedBrand(brandId)
    setActive(brandId);
  };

  // const setBrand = (event, brand) => {
  // console.log("specificFilterList----------",specificFilterList)

  //   if(selectedBrand.includes(brand.brandId)){
  //         setSelectedBrand(selectedBrand.filter(item=>item!==brand.brandId))
  //       }
  //   if(!selectedBrand.includes(brand.brandId)){
  //         setSelectedBrand([...selectedBrand,brand.brandId])
  //       }
  //   // const path = location.path;
  //   // console.log(brand);
  //   // history.push({
  //   //   pathname: path,
  //   //   search: `?category=${categoryId}&catname=${categoryName}&brand=${brand.brandId}&brandName=${brand.name}`,
  //   // });
  // };

  return (
    <>
      <div className="container four-products-btn mb-3 ">
        <div className="row section-heading text-center">
          <div className="col-12 main-heading">
            <h6 className="">Shop By qqqqqBrands</h6>
          </div>
        </div>

        <div className="four-products-carousel ">
          {/* <Slider {...slickConfig(state.brands?.length > 2)}> */} 
          <div className="slider-custom-space">
          <Slider {...settings}>
            {specificFilterList.length > 0 &&
              specificFilterList.map((brand, index) => (
                
                <div
                  key={index}
                  className={`mt-2 p-3 rounded-3 shadow-sm ${
                    active === brand?.brand.brandId ? "activeK" : ""
                  }`}
                  onClick={() => filterProduct(brand?.brand.brandId)}
                >
                  {/* <Link
                    to={`/brand?brand=${brand.brandId}&brandName=${brand.name}`}
                  > */}
                  <img
                    // className={`${active===true?"active":""}`}
                    src={brand?.brand.brandImage}
                    alt={brand.name}
                    style={{ height: 100 }}
                    className="mx-auto img-fluid"
                  />

                  {/* <input
                        className="form-check-input"
                        type="checkbox"
                        value={brand.brandid}
                        id="flexCheckDefault"
                        // checked={brand.brandid === brandId}
                        onChange={(event) => setBrand(event, brand)}
                      /> */}
                  {/* </Link> */}
                </div>
              ))}
          </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShopByBrandFilter;
