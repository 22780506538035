import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import OrderConfirmScreen from "./screens/OrderConfirmScreen";
import OrderScreen from "./screens/OrderScreen";
import ProductDetailsScreen from "./screens/ProductDetailsScreen";
import SaveForLaterScreen from "./screens/SaveForLaterScreen";
import ProductScreen from "./screens/ProductScreen";
import ShippingAddress from "./screens/ShippingAddress";
import EditProfileScreen from "./screens/EditProfileScreen";
import CartScreen from "./screens/CartScreen";
import ShippingScreen from "./screens/ShippingScreen";
import ReviewPay from "./screens/ReviewPay";
import ReviewRateProduct from "./screens/ReviewRateProduct";
import MyAddress from "./screens/MyAddress";
import MyQuotationScreen from "./screens/MyQuotationScreen";
import BrandProductScreen from "./screens/brandProductScreen";
import ProductListComponent from "./components/product/ProductListComponent";
import AddAdressScreen from "./screens/AddAddressScreen";
import EditAdressScreen from "./screens/EditAdressScreen";
import PrivateRoute from "./route/privateRoute";

import { ToastProvider } from "react-toast-notifications";
import TermsAndCondition from "./screens/TermsAndCondition";
import TermsOfUse from "./screens/TermsOfUse";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Return from "./screens/Return";
import ContactUs from "./screens/ContactUs";
import News from "./screens/News";
import Faq from "./screens/Faq";
import ResetPassword from "./screens/ResetPassword";
function App() {
  return (
    <ToastProvider
      autoDismiss
      autoDismissTimeout={6000}
      placement="bottom-right"
    >
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            name="Home"
            render={(props) => <HomeScreen {...props} />}
          />
          <Route
            exact
            path="/product"
            name="Product"
            render={(props) => <ProductScreen {...props} />}
          />
          <Route
            exact
            path="/brand"
            name="Brand"
            render={(props) => <BrandProductScreen {...props} />}
          />
          <PrivateRoute
            exact
            path="/add-address"
            name="Product Shipping Address"
            LoadComponent={ShippingAddress}
          />
          <PrivateRoute
            exact
            path="/add-new-address"
            name="Add new Shipping Address"
            LoadComponent={AddAdressScreen}
          />
          <PrivateRoute
            exact
            path="/edit-address"
            name="Edit Address"
            LoadComponent={EditAdressScreen}
          />
          <Route
            exact
            path="/product-details"
            render={(props) => <ProductDetailsScreen {...props} />}
          />
         
          <PrivateRoute exact path="/my-orders" LoadComponent={OrderScreen} />
          <PrivateRoute
            exact
            path="/order-confirm"
            LoadComponent={OrderConfirmScreen}
          />
          <PrivateRoute
            exact
            path="/save-for-later"
            LoadComponent={SaveForLaterScreen}
          />
          <PrivateRoute
            exact
            path="/cart"
            name="Cart Screen"
            LoadComponent={CartScreen}
          />
          <PrivateRoute
            exact
            path="/edit-profile"
            name="Edit Profile"
            LoadComponent={EditProfileScreen}
          />
          <PrivateRoute exact path="/shipping" LoadComponent={ShippingScreen} />
          <Route
            exact
            path="/my-quotation"
            render={(props) => <MyQuotationScreen {...props} />}
          />
          <PrivateRoute exact path="/review-pay" LoadComponent={ReviewPay} />
          <Route
            exact
            path="/review-rate"
            render={(props) => <ReviewRateProduct {...props} />}
          />
          <Route
            exact
            path="/my-addresses"
            render={(props) => <MyAddress {...props} />}
          />
          <Route
            exact
            path="/component"
            render={(props) => <ProductListComponent {...props} />}
          />

          <Route
            exact
            path="/terms-and-condition"
            render={(props) => <TermsAndCondition {...props} />}
          />

          <Route
            exact
            path="/terms-of-use"
            render={(props) => <TermsOfUse {...props} />}
          />

          <Route
            exact
            path="/privacy-policy"
            render={(props) => <PrivacyPolicy {...props} />}
          />

          <Route
            exact
            path="/return"
            render={(props) => <Return {...props} />}
          />

          <Route
            exact
            path="/contact-us"
            render={(props) => <ContactUs {...props} />}
          />

          <Route exact path="/news" render={(props) => <News {...props} />} />

          <Route exact path="/faq" render={(props) => <Faq {...props} />} />
          <Route
          exact
          path="/reset-password"
          render={(props) => <ResetPassword {...props} />}
           />
        </Switch>
      </Router>
    </ToastProvider>
  );
}

export default App;
