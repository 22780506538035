import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
 import "slick-carousel/slick/slick.css";
import { useRef } from "react";
import { getBanner } from "../../store/actions/banners.js/banner.action";

const Banner2 = () => {
  const slickRef = useRef();
 

  const play = () => {
      slickRef.current?.slickPlay();
    };
    const pause = () => {
      slickRef.current?.slickPause();
    };
  const [topBanner,SetTopBanner]=useState([])
  const[leftBanner,SetLeftBanner]=useState([])
  const[rightBanner,setRightBanner]=useState([])
  const[bottomBanner,setBottomBanner]=useState([])
   useEffect(()=>{
    let obj={
        "filters":{
            "title":""
        }
    }
     getBanner(obj,SetTopBanner,SetLeftBanner,setRightBanner,setBottomBanner)
} ,[]);
var settings = {
  arrows: false,
  infinite: true,
  speed: 900,
  dots: true,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,

  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
  return (
    <>
    <div className="container">
      <div className="row section-heading text-center">
        <div className="col-12 main-heading">
          <h3 className="fw-bold">
            Get the best deals on our best selling products
          </h3>
        </div>
        <div className="col-12">
          <div className="btn-holder border-success my-3">
            <Link to="/product" className="btn btn-light-success">
              Explore Now
            </Link>
          </div>
        </div>
      </div>
    
   
        

        <div>
                <div className="wide ps-0 pt-0 pe-0">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div
                      onMouseOver={() => play()}
                      onMouseLeave={() => pause()}
                      className="img-holder hover-images-pro"
                    >
                      <Slider {...settings}>
                        {bottomBanner?.map((item, i) => (
                     <div key={i}>
                          
                           <a href={item?.link} target="_blank"  rel="noreferrer">
                          
                            <div className="img-holder" >
                               {/* <div
                               className='wide-img-height'
                                style={{
                                  backgroundImage: `url(${item?.image})`,
                                  // height: "400px",
                                  width: "100%",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                }}
                              ></div> */}
                              <img className="img-fluid w-100" style={{height:"400px" }} src={item?.image} alt="" /> 
                            </div>
                          </a>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
               
    </div>
    </>
  );
};

export default Banner2;
