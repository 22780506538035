import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  fetchFeaturedProductCategoryList,
  fetchFeaturedProductList,
} from "../../store/actions/product/featureProductListAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { isAuth } from "../../utils/auth";
import { useToasts } from "react-toast-notifications";
import {
  addToCartAction,
  removeCartItem,
} from "../../store/actions/cartAction";
// import TravelCard from "./TravelCard";

const TabView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const loading = useSelector((state) => state.featuredProducts.isLoading);
  const featuredProducts = useSelector(
    (state) => state.featuredProducts.products
  );
  // const [isLoading, setIsLoading] = useState(true)
  const [featuredProductList, setFeaturedProductList] = useState([]);
  const [featuredProductsCategory, setFeaturedProductsCategory] = useState([]);
  const featuredCat = useSelector(
    (state) => state.featuredProducts.featuredProductsCategory
  );
  const cart = useSelector((state) => state.cart);
  const authed = isAuth();

  const { addToast } = useToasts();
  useEffect(() => {
    if (featuredCat && featuredCat.length) {
      getFeaturedProductByCategory(featuredCat[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuredCat]);

  useEffect(() => {
    // setIsLoading(loading)
    setFeaturedProductList(featuredProducts);
    setFeaturedProductsCategory(featuredCat);

    let filtered = [];
    for (var i = 0; i < featuredCat.length; i++) {
      if (filtered.length === 0) {
        filtered.push(featuredCat[i].category);
      } else {
        var isPresent = false;
        for (var j = 0; j < filtered.length; j++) {
          if (filtered[j].categoryid !== featuredCat[i].category.categoryid) {
            isPresent = true;
            break;
          }
        }
        if (isPresent === false) filtered.push(featuredCat[i].category);
      }

      localStorage.setItem("category", filtered);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuredProducts, featuredCat]);

  useEffect(() => {
    const requestObj = {
      count: 0,
      limit: 9,
      field: "max",
      filters: {
        type: "feature",
      },
      sortBy: {
        type: "postDate",
        order: -1,
      },
    };
    dispatch(fetchFeaturedProductCategoryList(requestObj));
  }, [dispatch]);

  const getFeaturedProductByCategory = ({ categoryid }) => {
    const requestObj = {
      count: 0,
      limit: 9,
      field: "max",
      filters: {
        type: "feature",
        "category.categoryid": categoryid,
      },
      sortBy: {
        type: "postDate",
        order: -1,
      },
    };
    dispatch(fetchFeaturedProductList(requestObj));
  };
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="slick-arrow slick-next">
        <button
          type="button"
          onClick={onClick}
          role="presentation"
          class="btn rounded-pill border px-3 slider-navigation-button"
        >
          <span aria-label="Next">›</span>
        </button>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div class="slick-arrow slick-prev">
        <button
          type="button"
          onClick={onClick}
          role="presentation"
          class="btn rounded-pill border px-3 slider-navigation-button"
        >
          <span aria-label="Previous">‹</span>
        </button>
      </div>
    );
  }

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 100,
    slidesToShow: 5,

    slidesToScroll: 2,
    //  prevArrow: <SamplePrevArrow/>,

    //  nextArrow: <SampleNextArrow/>,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  // const[value,setValue]=useState([])
  // console.log("ooooooooo",value)
  const [maxQuantity, setMaxQuantity] = useState([]);
  
  let quantityIntoApi=maxQuantity[maxQuantity.length-1]
  // console.log("ooooooooo",quantityIntoApi)
  // useEffect(() => {
  //   let arr = [];
  //   featuredProductsCategory?.map((items) =>
  //     items?.variants?.map((values) => {
  //       if (values) {
  //         arr.push(values?.name?.split("-")[1]);
  //       }
  //       // console.log(values.name?.split("-"))
        
  //       // eslint-disable-next-line no-unused-expressions
  //       setMaxQuantity[arr];
  //     })
  //   );
  // }, [featuredProductsCategory]);

  const addToCart = (product) => {
    // console.log(product)

    // let arr = [];
    // product.variants.map((items) => {
    //   if (items) {
    //     arr.push(items?.name?.split("-")[1]);
    //   }
    //   setMaxQuantity(arr)
    // });
    const requestObj = {
      productId: product.productId,
      variantId: product?.variants?.length
        ? product?.variants[0].variantId
        : null,
      quantity: 1,
    };
console.log(requestObj)
    if (authed && product?.variants?.length > 10) {
      if (cart?.cartIds?.includes(product.productId)) {
        dispatch(removeCartItem(requestObj)).then((result) => {
          addToast("Prodcut Removed in Cart", {
            appearance: "error",
          });
        });
      } else {
        dispatch(addToCartAction(requestObj)).then((result) => {

          console.log("api hit")
          addToast("Prodcut Added to Cart", {
            appearance: "success",
          });
        });
      }
    } else {
      addToast("Please Login", { appearance: "error" });
    }
  };

  return (
    <div className="container-fluid four-products-btn">
      <div className="">
        <Slider {...settings}>
          {featuredProductsCategory?.length > 0 &&
            featuredProductsCategory.map((product, index) => (
              <div key={index} className="py-1 w-100 px-2">
                <div className="mx-2 w-100">
                  {/* {console.log("product=====",product?.variants[product?.variants.length-1].variant1?.name
)} */}
                  {/* {console.log("product variemt=====",product?.variants)} */}

                  <div className="card border p-0 card-shadow w-100 ">
                    <div className="btn btn-success rounded m-0 text-white fw-bold w-25 cost-font p-1 m-1">
                      <span className="img-text">{product?.discount}% OFF</span>
                    </div>
                    <div>
                      <Link
                        to={`/product-details?product=${product?.productId}&category=${product.category?.categoryid}&catname=${product?.category.id?.categoryname}&subcategory=${product?.subCategory?.categoryid}&subcatname=${product?.subCategory?.id?.categoryname}}`}
                      >
                        <img
                          src={product?.imageUrls?.[0]?.url}
                          className="card-img-top  img-fluid shadow-sm object-fit-cover mx-auto   pb-o"
                          alt="..."
                          style={{ width: "235px" }}
                        />
                      </Link>
                    </div>
                    <div className="card-body pt-0">
                      <div className="card-text  ">
                        <p className="para-font fw-bold mb-1 text-truncate">
                          Ref No.:{product.productCode}
                          <br />
                          <span className="text-truncate2 ">
                            {" "}
                            Brand Name: {product?.brand?.id?.name}
                          </span>
                        </p>
                        <div
                          className="text-truncate2 fw-bold mb-1 mt-0 text-truncate"
                          style={{ fontSize: "large" }}
                        >
                          {" "}
                          {product.title}---
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row pb-0 pt-0  ">
                              <div class="d-flex bd-highlight">
                                <div class=" flex-grow-1    ">
                                  <b>Price :-</b>
                                  <span>
                                    ₹
                                    {product.price -
                                      (product.price * product?.discount) / 100}
                                  </span>
                                </div>
                                <div class=" bd-highlight ">
                                  <b>MRP:- </b>{" "}
                                  <span className="para-font">
                                    <del>₹{product.price}</del>
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-12 mt-3">
                                <div
                                  onClick={() => addToCart(product)}
                                  className="btn btn-success rounded-0 m-0 w-100 text-white p-1"
                                >
                                  {cart?.cartIds?.includes(product.productId)
                                    ? "Already in Cart"
                                    : " Add to Cart"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};

export default TabView;
