import React from 'react'

const DateRange = () => {
    return (
        <div>
               <form>
                            <div className="row ">
                                <div className="mb-3 col-6">
                                    <label htmlFor="exampleInputEmail1" className="form-label">From</label>
                                    <input type="date" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />

                                </div>
                                <div className="mb-3 col-6">
                                    <label htmlFor="exampleInputPassword1" className="form-label">To</label>
                                    <input type="date" className="form-control" id="exampleInputPassword1" />
                                </div>
                            </div>

                        </form>
        </div>
    )
}

export default DateRange
