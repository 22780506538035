import {
    FORGOT_PASSWORD_SENDLINK_REQUEST,
    FORGOT_PASSWORD_SENDLINK_SUCCESS,
    FORGOT_PASSWORD_SENDLINK_FAILURE,
    FORGOT_PASSWORD_RESET_REQUEST,
    FORGOT_PASSWORD_RESET_SUCCESS,
    FORGOT_PASSWORD_RESET_FAILURE

} from '../../type'



const initialState = {
    isLoading: true,
    linkSendResponse: {},
    resetPasswordResponse: {},
    error: '',
    message: ''

}
const forgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {

        case FORGOT_PASSWORD_SENDLINK_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case FORGOT_PASSWORD_SENDLINK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                linkSendResponse: action.payload,
                message: action.payload.message
            }

        case FORGOT_PASSWORD_SENDLINK_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case FORGOT_PASSWORD_RESET_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case FORGOT_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                resetPasswordResponse: action.payload,
                message: action.payload.message
            }

        case FORGOT_PASSWORD_RESET_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }




        default:
            return state
    }

}

export default forgotPasswordReducer