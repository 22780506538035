import {
    GET_PRODUCT_DETAILS_REQUEST,
    GET_PRODUCT_DETAILS_SUCCESS,
    GET_PRODUCT_DETAILS_FAILURE,
    BOOKMARK_PRODUCT_REQUEST,
    BOOKMARK_PRODUCT_SUCCESS,
    BOOKMARK_PRODUCT_FAILURE

}
    from '../../type'



const initialState = {
    isLoading: false,
    productDetails: {},
    error: '',
    message: '',
    success: ''

}
const productDetails = (state = initialState, action) => {
    switch (action.type) {

        case GET_PRODUCT_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case GET_PRODUCT_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                productDetails: action.payload,
                message: action.payload.message
            }

        case GET_PRODUCT_DETAILS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case BOOKMARK_PRODUCT_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case BOOKMARK_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: action.payload,
                message: action.payload.message
            }

        case BOOKMARK_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        default:
            return state
    }

}

export default productDetails