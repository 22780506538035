export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const FETCH_BEST_DEALS_REQUEST = "FETCH_BEST_DEALS_REQUEST";
export const FETCH_BEST_DEALS_SUCCESS = "FETCH_BEST_DEALS_SUCCESS";
export const FETCH_BEST_DEALS_FAILURE = "FETCH_BEST_DEALS_FAILURE";

export const FETCH_BEST_SELLING_PRODUCTS_REQUEST = "FETCH_BEST_SELLING_PRODUCTS_REQUEST";
export const FETCH_BEST_SELLING_PRODUCTS_SUCCESS = "FETCH_BEST_SELLING_PRODUCTS_SUCCESS";
export const FETCH_BEST_SELLING_PRODUCTS_FAILURE = "FETCH_BEST_SELLING_PRODUCTS_FAILURE";

export const SEND_PASSWORD_RESET_LINK = "SEND_PASSWORD_RESET_LINK";

export const RESET_PASSWORD = 'RESET_PASSWORD';

export const FETCH_ORDER_REQUEST = "FETCH_ORDER_REQUEST";
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_FAILURE = "FETCH_ORDER_FAILURE";


export const FETCH_CART_REQUEST = "FETCH_CART_REQUEST";
export const FETCH_CART_SUCCESS = "FETCH_CART_SUCCESS";
export const FETCH_CART_FAILURE = "FETCH_CART_FAILURE";
export const FETCH_CART_PRODUCT_ID = "FETCH_CART_PRODUCT_ID";

export const REMOVE_CART_ITEM_REQUEST = "REMOVE_CART_ITEM_REQUEST";
export const REMOVE_CART_ITEM_SUCCESS = "REMOVE_CART_ITEM_SUCCESS";
export const REMOVE_CART_ITEM_FAILURE = "REMOVE_CART_ITEM_FAILURE";

export const INCREMENT_CART_ITEMCOUNT_REQUEST = "INCREMENT_CART_ITEMCOUNT_REQUEST";
export const INCREMENT_CART_ITEMCOUNT_SUCCESS = "INCREMENT_CART_ITEMCOUNT_SUCCESS";
export const INCREMENT_CART_ITEMCOUNT_FAILURE = "INCREMENT_CART_ITEMCOUNT_FAILURE";

export const APPLY_PROMOCODE_REQUEST = "APPLY_PROMOCODE_REQUEST";
export const APPLY_PROMOCODE_SUCCESS = "APPLY_PROMOCODE_SUCCESS";
export const APPLY_PROMOCODE_FAILURE = "APPLY_PROMOCODE_FAILURE";

export const REMOVE_PROMOCODE_REQUEST = "REMOVE_PROMOCODE_REQUEST";
export const REMOVE_PROMOCODE_SUCCESS = "REMOVE_PROMOCODE_SUCCESS";
export const REMOVE_PROMOCODE_FAILURE = "REMOVE_PROMOCODE_FAILURE";

export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";

export const GET_PRODUCT_DETAILS_REQUEST = "GET_PRODUCT_DETAILS_REQUEST";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_FAILURE = "GET_PRODUCT_DETAILS_FAILURE";

export const BUY_NOW_REQUEST = "BUY_NOW_REQUEST";
export const BUY_NOW_SUCCESS = "BUY_NOW_SUCCESS";
export const BUY_NOW_FAILURE = "BUY_NOW_FAILURE";

export const WRITE_PRODUCT_REVIEW_REQUEST = "WRITE_REVIEWS_REQUEST";
export const WRITE_PRODUCT_REVIEW_SUCCESS = "WRITE_REVIEWS_SUCCESS";
export const WRITE_PRODUCT_REVIEW_FAILURE = "WRITE_REVIEWS_FAILURE";

export const GET_PRODUCTLIST_BY_BRAND_REQUEST = "GET_PRODUCTLIST_BY_BRAND_REQUEST";
export const GET_PRODUCTLIST_BY_BRAND_SUCCESS = "GET_PRODUCTLIST_BY_BRAND_SUCCESS";
export const GET_PRODUCTLIST_BY_BRAND_FAILURE = "GET_PRODUCTLIST_BY_BRAND_FAILURE";

export const GET_PRODUCTLIST_BY_CATEGORY_REQUEST = "GET_PRODUCTLIST_BY_CATEGORY_REQUEST";
export const GET_PRODUCTLIST_BY_CATEGORY_SUCCESS = "GET_PRODUCTLIST_BY_CATEGORY_SUCCESS";
export const GET_PRODUCTLIST_BY_CATEGORY_FAILURE = "GET_PRODUCTLIST_BY_CATEGORY_FAILURE";


export const GET_QUOTATION_LIST_REQUEST = "GET_QUOTATION_LIST_REQUEST";
export const GET_QUOTATION_LIST_SUCCESS = "GET_QUOTATION_LIST_SUCCESS";
export const GET_QUOTATION_LIST_FAILURE = "GET_QUOTATION_LIST_FAILURE";

export const ADD_PRODUCT_TO_QUOTATION_REQUEST = "ADD_PRODUCT_TO_QUOTATION_REQUEST";
export const ADD_PRODUCT_TO_QUOTATION_SUCCESS = "ADD_PRODUCT_TO_QUOTATION_SUCCESS";
export const ADD_PRODUCT_TO_QUOTATION_FAILURE = "ADD_PRODUCT_TO_QUOTATION_FAILURE";

export const REMOVE_PRODUCT_FROM_QUOTATION_REQUEST = "REMOVE_PRODUCT_FROM_QUOTATION_REQUEST";
export const REMOVE_PRODUCT_FROM_QUOTATION_SUCCESS = "REMOVE_PRODUCT_FROM_QUOTATION_SUCCESS";
export const REMOVE_PRODUCT_FROM_QUOTATION_FAILURE = "REMOVE_PRODUCT_FROM_QUOTATION_FAILURE";


export const INCREMENET_PRODUCT_COUNT_QUOTATION_REQUEST = "INCREMENET_PRODUCT_COUNT_QUOTATION_REQUEST";
export const INCREMENET_PRODUCT_COUNT_QUOTATION_SUCCESS = "INCREMENET_PRODUCT_COUNT_QUOTATION_SUCCESS";
export const INCREMENET_PRODUCT_COUNT_QUOTATION_FAILURE = "INCREMENET_PRODUCT_COUNT_QUOTATION_FAILURE";


export const DECREMENT_PRODUCT_COUNT_QUOTATION_REQUEST = "DECREMENT_PRODUCT_COUNT_QUOTATION_REQUEST";
export const DECREMENT_PRODUCT_COUNT_QUOTATION_SUCCESS = "DECREMENT_PRODUCT_COUNT_QUOTATION_SUCCESS";
export const DECREMENT_PRODUCT_COUNT_QUOTATION_FAILURE = "DECREMENT_PRODUCT_COUNT_QUOTATION_FAILURE";

export const GET_PRODUCTLIST_BY_NEEDTYPE_REQUEST = "GET_PRODUCTLIST_BY_NEEDTYPE_REQUEST";
export const GET_PRODUCTLIST_BY_NEEDTYPE_SUCCESS = "GET_PRODUCTLIST_BY_NEEDTYPE_SUCCESS";
export const GET_PRODUCTLIST_BY_NEEDTYPE_FAILURE = "GET_PRODUCTLIST_BY_NEEDTYPE_FAILURE";

export const FORGOT_PASSWORD_SENDLINK_REQUEST = "FORGOT_PASSWORD_SENDLINK_REQUEST";
export const FORGOT_PASSWORD_SENDLINK_SUCCESS = "FORGOT_PASSWORD_SENDLINK_SUCCESS";
export const FORGOT_PASSWORD_SENDLINK_FAILURE = "FORGOT_PASSWORD_SENDLINK_FAILURE";

export const FORGOT_PASSWORD_RESET_REQUEST = "FORGOT_PASSWORD_RESET_REQUEST";
export const FORGOT_PASSWORD_RESET_SUCCESS = "FORGOT_PASSWORD_RESET_SUCCESS";
export const FORGOT_PASSWORD_RESET_FAILURE = "FORGOT_PASSWORD_RESET_FAILURE";


export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";


export const VALIDATE_OTP_REQUEST = "VALIDATE_OTP_REQUEST";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_FAILURE = "VALIDATE_OTP_FAILURE";

export const FETCH_CATAGORYLIST_REQUEST = "FETCH_CATAGORYLIST_REQUEST";
export const FETCH_CATAGORYLIST_SUCCESS = "FETCH_CATAGORYLIST_SUCCESS";
export const FETCH_CATAGORYLIST_FAILURE = "FETCH_CATAGORYLIST_FAILURE";

export const GET_SUBCATEGORYLIST_REQUEST = "GET_SUBCATEGORYLIST_REQUEST";
export const GET_SUBCATEGORYLIST_SUCCESS = "GET_SUBCATEGORYLIST_SUCCESS";
export const GET_SUBCATEGORYLIST_FAILURE = "GET_SUBCATEGORYLIST_FAILURE";


export const FETCH_SUB_CATAGORYLIST_REQUEST = "FETCH_SUB_CATAGORYLIST_REQUEST";
export const FETCH_SUB_CATAGORYLIST_SUCCESS = "FETCH_SUB_CATAGORYLIST_SUCCESS";
export const FETCH_SUB_CATAGORYLIST_FAILURE = "FETCH_SUB_CATAGORYLIST_FAILURE";


export const GET_SUBCATEGORY_BY_CATEGORYID_REQUEST = "GET_SUBCATEGORY_BY_CATEGORYID_REQUEST";
export const GET_SUBCATEGORY_BY_CATEGORYID_SUCCESS = "GET_SUBCATEGORY_BY_CATEGORYID_SUCCESS";
export const GET_SUBCATEGORY_BY_CATEGORYID_FAILURE = "GET_SUBCATEGORY_BY_CATEGORYID_FAILURE";

export const GET_PRODUCTLIST_BY_SUBCATEGORY_REQUEST = "GET_PRODUCTLIST_BY_SUBCATEGORY_REQUEST";
export const GET_PRODUCTLIST_BY_SUBCATEGORY_SUCCESS = "GET_PRODUCTLIST_BY_SUBCATEGORY_SUCCESS";
export const GET_PRODUCTLIST_BY_SUBCATEGORY_FAILURE = "GET_PRODUCTLIST_BY_SUBCATEGORY_FAILURE";

export const GET_BRANDLIST_REQUEST = "GET_BRANDLIST_REQUEST";
export const GET_BRANDLIST_SUCCESS = "GET_BRANDLIST_SUCCESS";
export const GET_BRANDLIST_FAILURE = "GET_BRANDLIST_FAILURE";

export const GET_FEATURED_PRODUCTLIST_REQUEST = "GET_FEATURED_PRODUCTLIST_REQUEST";
export const GET_FEATURED_PRODUCTLIST_SUCCESS = "GET_FEATURED_PRODUCTLIST_SUCCESS";
export const GET_FEATURED_PRODUCTLIST_FAILURE = "GET_FEATURED_PRODUCTLIST_FAILURE";

export const GET_FEATURED_PRODUCTS_CATEGORY_REQUEST = "GET_FEATURED_PRODUCTS_CATEGORY_REQUEST";
export const GET_FEATURED_PRODUCTS_CATEGORY_SUCCESS = "GET_FEATURED_PRODUCTS_CATEGORY_SUCCESS";
export const GET_FEATURED_PRODUCTS_CATEGORY_FAILURE = "GET_FEATURED_PRODUCTS_CATEGORY_FAILURE";

export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST"
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS"
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE"

export const ADD_ADDRESS_REQUEST = "ADD_ADDRESS_REQUEST";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAILURE = "ADD_ADDRESS_FAILURE";

export const GET_SAVED_ADDRESS_REQUEST = "GET_SAVED_ADDRESS_REQUEST";
export const GET_SAVED_ADDRESS_SUCCESS = "GET_SAVED_ADDRESS_SUCCESS";
export const GET_SAVED_ADDRESS_FAILURE = "GET_SAVED_ADDRESS_FAILURE";

export const GET_ADDRESSBY_ID_REQUEST = "GET_ADDRESSBY_ID_REQUEST";
export const GET_ADDRESSBY_ID_SUCCESS = "GET_ADDRESSBY_ID_SUCCESS";
export const GET_ADDRESSBY_ID_FAILURE = "GET_ADDRESSBY_ID_FAILURE";

export const UPDATE_ADDRESS_REQUEST = "UPDATE_ADDRESS_REQUEST";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAILURE = "UPDATE_ADDRESS_FAILURE";

export const REMOVE_ADDRESS_REQUEST = "REMOVE_ADDRESS_REQUEST";
export const REMOVE_ADDRESS_SUCCESS = "REMOVE_ADDRESS_SUCCESS";
export const REMOVE_ADDRESS_FAILURE = "REMOVE_ADDRESS_FAILURE";

export const UPDATE_PROFILE_IMAGE_REQUEST = 'UPDATE_PROFILE_IMAGE_REQUEST';
export const UPDATE_PROFILE_IMAGE_SUCCESS = 'UPDATE_PROFILE_IMAGE_SUCCESS';
export const UPDATE_PROFILE_IMAGE_FAILURE = 'UPDATE_PROFILE_IMAGE_FAILURE';

export const UPDATE_PROFILE_INFO_REQUEST = "UPDATE_PROFILE_INFO_REQUEST";
export const UPDATE_PROFILE_INFO_SUCCESS = "UPDATE_PROFILE_INFO_SUCCESS";
export const UPDATE_PROFILE_INFO_FAILURE = "UPDATE_PROFILE_INFO_FAILURE";

export const UPDATE_USER_EMAIL_REQUEST = "UPDATE_USER_EMAIL_REQUEST";
export const UPDATE_USER_EMAIL_SUCCESS = "UPDATE_USER_EMAIL_SUCCESS";
export const UPDATE_USER_EMAIL_FAILURE = "UPDATE_USER_EMAIL_FAILURE";

export const BOOKMARK_PRODUCT_REQUEST = "BOOKMARK_PRODUCT_REQUEST";
export const BOOKMARK_PRODUCT_SUCCESS = "BOOKMARK_PRODUCT_SUCCESS";
export const BOOKMARK_PRODUCT_FAILURE = "BOOKMARK_PRODUCT_FAILURE";

export const GET_BOOKMARK_PRODUCT_LIST_REQUEST = "GET_BOOKMARK_PRODUCT_LIST_REQUEST";
export const GET_BOOKMARK_PRODUCT_LIST_SUCCESS = "GET_BOOKMARK_PRODUCT_LIST_SUCCESS";
export const GET_BOOKMARK_PRODUCT_LIST_FAILURE = "GET_BOOKMARK_PRODUCT_LIST_FAILURE";

export const GET_CART_DETAILS_REQUEST = "GET_CART_DETAILS_REQUEST";
export const GET_CART_DETAILS_SUCCESS = "GET_CART_DETAILS_SUCCESS";
export const GET_CART_DETAILS_FAILURE = "GET_CART_DETAILS_FAILURE";

export const GET_QUOTATIONPRICE_REQUEST = "GET_QUOTATIONPRICE_REQUEST";
export const GET_QUOTATIONPRICE_SUCCESS = "GET_QUOTATIONPRICE_SUCCESS";
export const GET_QUOTATIONPRICE_FAILURE = "GET_QUOTATIONPRICE_FAILURE";

export const PLACE_ORDER_REQUEST = "PLACE_ORDER_REQUEST";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAILURE = "PLACE_ORDER_FAILURE";

export const FETCH_ORDER_LIST_REQUEST = "FETCH_ORDER_LIST_REQUEST";
export const FETCH_ORDER_LIST_SUCCESS = "FETCH_ORDER_LIST_SUCCESS";
export const FETCH_ORDER_LIST_FAILURE = "FETCH_ORDER_LIST_FAILURE";

export const LOGOUT_USER = "LOGOUT_USER";

// banners

export const BANNER_LIST_REQUEST="BANNER_LIST_REQUEST"
export const BANNER_LIST_SUCCESS="BANNER_LIST_SUCCESS"
export const BANNER_LIST_FAILURE="BANNER_LIST_FAILURE"


// subsubcategory
 

 export const FETCH_SUB_SUB_CATAGORYLIST_REQUEST="FETCH_SUB_SUB_CATAGORYLIST_REQUEST"
 export const FETCH_SUB_SUB_CATAGORYLIST_SUCCESS="FETCH_SUB_SUB_CATAGORYLIST_SUCCESS"
 export const FETCH_SUB_SUB_CATAGORYLIST_FAILURE="FETCH_SUB_SUB_CATAGORYLIST_FAILURE"