import {
  FETCH_CART_REQUEST,
  FETCH_CART_SUCCESS,
  FETCH_CART_FAILURE,
  FETCH_CART_PRODUCT_ID,
  REMOVE_CART_ITEM_REQUEST,
  REMOVE_CART_ITEM_SUCCESS,
  REMOVE_CART_ITEM_FAILURE,
  INCREMENT_CART_ITEMCOUNT_REQUEST,
  INCREMENT_CART_ITEMCOUNT_SUCCESS,
  INCREMENT_CART_ITEMCOUNT_FAILURE,
  GET_CART_DETAILS_REQUEST,
  GET_CART_DETAILS_SUCCESS,
  GET_CART_DETAILS_FAILURE,

} from "../../type";

const initialState = {
  isLoading: false,
  cart: [],
  cartIds:[],
  error: "",
  message: "",
  cartDetails: {},
};
const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CART_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    
    case FETCH_CART_PRODUCT_ID:
    return {
        ...state,
        cartIds: action.payload,
    };

    case FETCH_CART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cart: action.payload,
        message: action.payload.message,
      };

    case FETCH_CART_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case REMOVE_CART_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case REMOVE_CART_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
      };

    case REMOVE_CART_ITEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case INCREMENT_CART_ITEMCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case INCREMENT_CART_ITEMCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    case INCREMENT_CART_ITEMCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_CART_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CART_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cartDetails: action.payload,
      };

    case GET_CART_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default cartReducer;
