import {
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_FAILURE
}
    from "../../type"


const initialState = {
    isLoading: false,
    data: [],
    error: '',
    message: '',
    isAuthenticated: false

}






const signupReducer = (state = initialState, action) => {


    switch (action.type) {

        case SIGNUP_REQUEST:
            return {
                ...state,
                isLoading: true,


            }

        case SIGNUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                message: action.payload.message

            }

        case SIGNUP_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,


            }
        default:
            return state


    }

}

export default signupReducer