import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";


const MyAccount = () => {
  const location = useLocation()
  const pathName = location.pathname
  const history = useHistory()

  const baseClass = "list-group-item border-0 d-flex justify-content-between align-items-start"
  const activeClass = baseClass + (' active ')
  const logout = () => {
    localStorage.clear()
    history.push("/")
    window.location.reload()

  }

  return (
    <>
      <div className="card border-0 ">
        <div className="card-body p-0">
          <ol className="list-group">
            <li className={pathName === '/edit-profile' ? activeClass : baseClass}>
              <Link to="/edit-profile">
                <div className="d-flex">
                  <div className="size-16px sprite-icon">
                    <i className="fas fa-pen"></i>
                  </div>
                  <div className="px-3">Edit Profile</div>
                </div>
              </Link>
            </li>
            <li className={pathName === '/my-addresses' ? activeClass : baseClass}>
              <Link to="/my-addresses">
                <div className="d-flex">
                  <div className="size-16px sprite-icon">
                    <i className="fas fa-map-marker-alt"></i>
                  </div>
                  <div className="px-3">My Addresses </div>
                </div>
              </Link>
            </li>
            <li className={pathName === '/my-orders' ? activeClass : baseClass}>
              <Link to="/my-orders">
                <div className="d-flex">
                  <div className="size-16px sprite-icon">
                    <i className="far fa-credit-card"></i>
                  </div>
                  <div className="px-3">My Orders</div>
                </div>
              </Link>
            </li>
            <li className={pathName === '/save-for-later' ? activeClass : baseClass}>
              <Link to="/save-for-later">
                <div className="d-flex">
                  <div className="size-16px sprite-icon">
                    <i className="fas fa-heart"></i>
                  </div>
                  <div className="px-3">Save for later</div>
                </div>
              </Link>
            </li>
            <li className={baseClass}>
              <div onClick={() => logout()} style={{ cursor: 'pointer' }}>
                <div className="d-flex">
                  <div className="size-16px sprite-icon">
                    <i className="fas fa-sign-out-alt"></i>
                  </div>
                  <div className="px-3">Logout</div>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
