import React from 'react'
import { ClipLoader } from 'react-spinners'


const Loader = ({

    color = "#67AC65",
    size,
    ...rest

}) => {
    return (
        <div className="d-flex justify-content-center align-items-center">
            <ClipLoader color={color} size={size} {...rest} /></div>
    )
}
export default Loader