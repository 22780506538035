import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ImageView from "./ImageView";
import TabView from "./TabView";

function FeaturedProducts() {
  // const loading = useSelector(state => state.featuredProducts.isLoading)
  const featuredProducts = useSelector(
    (state) => state.featuredProducts.products
  );
  const [categoryList, setCategoryList] = useState([]);
  const categories = useSelector((state) => state.category.data);
  const [productList, setProductList] = useState([]);
  // const dispatch = useDispatch()
  // const featureProducts = useSelector(state=>)

  useEffect(() => {
    setProductList(featuredProducts);
    setCategoryList(categories);
  }, [featuredProducts, categories]);

  // const requestObj = {
  //   "count": 0,
  //   "limit": 9,
  //   "field": "max",
  //   "filters":
  //   {

  //     "type": "Feature"

  //   },
  //   "sortBy": {
  //     "type": "postDate",
  //     "order": -1
  //   }
  // }

  // useEffect(() => {
  //   dispatch(featuredProductList(requestObj))
  // }, [])

  return (
    <>
      <div className="container">
        <div className="row section-heading text-center">
          <div className="col-12 main-heading">
            <h3 className="fw-bold">Featured Products</h3>
          </div>
        </div>
        <div className="row my-3 pt-3">
          <div className="col-12">
             <TabView products={productList} categories={categoryList} />
          </div>
        </div>
        {/* <div className="row my-3">
          <div className="col-xl-10 col-lg-11 mx-auto">
            <div className="row">
              <div className="col-md-6 my-3">
                <ImageView
                  tagLine="Best Selling Power Tools"
                  priceInfo="Upto 30% OFF"
                  btnText="Explore Now"
                  image="images/offer1.png"
                />
              </div>
              <div className="col-md-6 my-3">
                <ImageView
                  tagLine="New Inverters Collection"
                  priceInfo="From ₹6,999"
                  btnText="View Collection"
                  image="images/offer2.png"
                />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default FeaturedProducts;
