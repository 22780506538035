import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";
import { fetchCategoryList } from "../../store/actions/category/categoryAction";
import { fetchSubCatagoryList } from "../../store/actions/subCategory/subCategoryAction";
import { fetchSubSubCatList } from "../../store/actions/subSubCategoryAction/subSubCategoryAction";
// import { fetchSubCatagoryList } from "../../store/actions/subCategory/subCategoryAction";
import RangleSliderComponent from "./RangleSliderComponent";
const FilterComponent = ({
  productCount,
  brandList,
  selectedBrand,
  setSelectedBrand,
  specificFilterList,
  setSpecificFilterList,
  priceRange,
  setPriceRange,
  setSelectedCategory,
  selectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  selectedSubSubCategory,
  setSelectedSubSubCategory,
  selectedHeaderValue,
  setSelectedHeaderValue,
  selectedHeaderTitle,
  setSelectedHeaderTitle,
}) => {

   const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const query = new URLSearchParams(location.search);
  const categoryId = query.get("category");
  const categoryName = query.get("catname");
  const SubCatName = query.get("catname");
  const brandId = query.get("brand");
  const brandName = query.get("brandName");
  const subCategoryId = query.get("subcategory");
  const Subcatname = query.get("subcatname");
  const Sub2catname = query.get("Sub2catname");
  const brandU = query.get("brandU");
  const catU = query.get("catU");
  const subCatU = query.get("subCatU");
  const sub2CatU = query.get("sub2CatU");

  // const [priceRange, setPriceRange] = useState([0, 100000]);
  //  const catname=new URLSearchParams(window.location.search).get("catname")
  //  const brandName=new URLSearchParams(window.location.search).get("brandName")

  useEffect(() => {
    dispatch(fetchCategoryList());
    dispatch(fetchSubCatagoryList());
    dispatch(fetchSubSubCatList());
  }, []);
  useEffect(() => {
    specificFilter();
  }, [brandU, catU, subCatU, sub2CatU]);
  const categoryList = useSelector((state) => state.category?.data);
  const subCategoryList = useSelector((state) => state?.subCategory?.data);
  const subSubCategoryList = useSelector(
    (state) => state?.subSubCategory?.data
  );
  // const subSubHeaderList=useSelector((state)=>(state?.subSubCategory?.data))

  const onPriceRangeChange = (value) => {
    setPriceRange(value);
    // let searchString = ``;
    // const path = location.pathname;

    // if (brandId && brandName) {
    //   searchString = `?&brand=${brandId}&brandName=${brandName}&priceStart=${value[0]}&priceLimit=${value[1]}`;
    //   if (categoryId && subCategoryId && subcatname) {
    //     searchString = `?category=${categoryId}&catname=${categoryName}&subcategory=${subCategoryId}&subcatname=${subcatname}&brand=${brandId}&brandName=${brandName}
    //   &priceStart=${value[0]}&priceLimit=${value[1]}`;
    //   }
    // } else {
    //   searchString = `?category=${categoryId}&catname=${categoryName}&priceStart=${value[0]}&priceLimit=${value[1]}`;
    //   if (subCategoryId && subcatname) {
    //     searchString = `?category=${categoryId}&catname=${categoryName}&subcategory=${subCategoryId}&subcatname=${subcatname}&priceStart=${value[0]}
    //     &priceLimit=${value[1]}`;
    //   }
    // }
    // history.push({
    //   pathname: path,
    //   search: searchString,
    // });
  };

  const setBrand = (event, brand) => {
    if (selectedBrand.includes(brand?.brand.brandId)) {
      setSelectedBrand(
        selectedBrand.filter((item) => item !== brand?.brand.brandId)
      );
    }
    if (!selectedBrand.includes(brand?.brand.brandId)) {
      setSelectedBrand([...selectedBrand, brand?.brand.brandId]);
    }
    // const path = location.path;
    // console.log(brand);
    // history.push({
    //   pathname: path,
    //   search: `?category=${categoryId}&catname=${categoryName}&brand=${brand.brandId}&brandName=${brand.name}`,
    // });
  };
  const setSubCat = (event, subCat) => {
    if (selectedSubCategory?.includes(subCat?.subcategory.categoryid)) {
      setSelectedSubCategory(
        selectedSubCategory.filter(
          (item) => item !== subCat?.subcategory.categoryid
        )
      );
    }
    if (!selectedSubCategory?.includes(subCat?.subcategory.categoryid)) {
      setSelectedSubCategory([
        ...selectedSubCategory,
        subCat?.subcategory.categoryid,
      ]);
    }
  };

  const setSubSubCat = (event, subsubCat) => {
    if (selectedSubSubCategory?.includes(subsubCat?.sub2category?.categoryid)) {
      setSelectedSubSubCategory(
        selectedSubSubCategory.filter(
          (item) => item !== subsubCat?.sub2category?.categoryid
        )
      );
    }
    if (
      !selectedSubSubCategory?.includes(subsubCat?.sub2category?.categoryid)
    ) {
      setSelectedSubSubCategory([
        ...selectedSubSubCategory,
        subsubCat?.sub2category?.categoryid,
      ]);
    }
  };

  const setCategory = (event, category) => {
    if (selectedCategory?.includes(category?.category.categoryid)) {
      setSelectedCategory(
        selectedCategory.filter(
          (item) => item !== category?.category.categoryid
        )
      );
    }
    if (!selectedCategory?.includes(category?.category.categoryid)) {
      setSelectedCategory([...selectedCategory, category?.category.categoryid]);
    }
  };

  // setSelectedHeaderValue
  // let titleArr = [];
  // const [titleArr, setTitleArr] = useState([]);
  const setHeadervalue = (event, data, header) => {
    // console.log("setHeaderValue evebt=====",event.target.value)
    // console.log("setHeaderValue=====",header)
    console.log(" data=====", data,selectedHeaderValue);
    // if(!titleArr.includes(header.title)){
    //   titleArr.push(header.title);
    //   setTitleArr(titleArr)
    // }else{
    //   // if()
    // }
    // console.log("titleArr==>",titleArr.includes(header.title))

    // var set=new Set([header.title])
    // const exSet=new Set([header.title,...set])

    // console.log("setHeaderValue=====",exSet)

    if (selectedHeaderValue?.includes(data)) {
      setSelectedHeaderValue(
        selectedHeaderValue.filter((item) => item !== data)
      );
    }
    if (!selectedHeaderValue?.includes(data)) {
      setSelectedHeaderValue([...selectedHeaderValue, data]);
    }

    //   if(selectedHeaderTitle?.includes(header.title)){
    //     setSelectedHeaderTitle([selectedHeaderTitle])
    //    }
    //   if(!selectedHeaderTitle?.includes(header.title)){
    //      setSelectedHeaderTitle([...selectedHeaderTitle,header.title])
    //    }
  };
  // console.log("titleArr==>", titleArr)
  const setHeaderTitle = (event, header) => {
    console.log("titleArr header header==>", header.title,selectedHeaderTitle);

    if (selectedHeaderTitle?.includes(header.title)) {
      setSelectedHeaderTitle(
        selectedHeaderTitle.filter((item) => item !== header.title)
      );
    }
    if (!selectedHeaderTitle?.includes(header.title)) {
      setSelectedHeaderTitle([...selectedHeaderTitle, header.title]);
    }
  };
  //   {
  //     dispatch(fetchSubSubCatListRequest())
  //     try{
  //         axiosInstance.post(FETCH_SUB_SUB_CAT_URL).then((response)=>{
  //             let res=JSON.parse(response.data)
  //              if(res.code===200)
  //             dispatch(fetchSubSubCategoryListSuccess(res.result))
  //         })
  //     }catch(error){
  //         console.log("erre",error)
  //         dispatch(fetchSubSubCatagoryListFailure(error.message))

  //     }
  // }
  const specificFilter = () => {
    let reqObj = {
      ...(brandU != null && { brand: brandU }),
      ...(catU != null && { category: catU }),
      ...(subCatU != null && { subcategory: subCatU }),
      ...(sub2CatU != null && { sub2category: sub2CatU }),
    };

    try {
      axiosInstance
        .post("/commonroutes/commonFilter", reqObj)
        .then((response) => {
          let res = JSON.parse(response?.data);
          setSpecificFilterList(res);
          // console.log("res======",res)
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="card filter-list-left left-menu-filter menu-toggle">
      <div className="card-body py-0">
        <div className="">
          <h3>All Products111</h3>
          <p>{productCount} PRODUCTS FOUND</p>
        </div>
        <ol className="ps-0 card border border-light-success">
          <li className="list-group-item d-flex justify-content-start pb-3 title">
            FILTER 111BY
          </li>
          <div className="list-group-item ">
            <label htmlFor="customRange2" className="form-label">
              PRICE
            </label>

            <RangleSliderComponent
              min={0}
              max={200000}
              step={1}
              defaultValue={[0, 100000]}
              priceRange={priceRange}
              setPriceRange={setPriceRange}
              onPriceRangeChange={onPriceRangeChange}
            />
          </div>

          {/* category filter */}

          {categoryName && (
            <>
              <div
                className="accordion accordion-flush list-group-item"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button fw-bold px-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="true"
                      aria-controls="flush-collapseOne"
                      style={{
                        minWidth:'100%'
                      }}
                    >
                      Brand
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body pt-0">
                      {specificFilterList.map((brand, index) => (
                        <div key={index} className="form-check ">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={brand?.brand.brandid}
                            id="flexCheckDefault"
                            // checked={brand.brandid === brandId}
                            onChange={(event) => setBrand(event, brand)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            {brand?.brand.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="accordion accordion-flush list-group-item"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button fw-bold px-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="true"
                      aria-controls="flush-collapseOne"
                      style={{
                        minWidth:'100%'
                      }}
                    >
                      Sub Category
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOnee"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-headingOnee"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body pt-0">
                      {specificFilterList?.map((subCat, index) => (
                        <div key={index} className="form-check ">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={subCat?.subcategory?.categoryid}
                            id="flexCheckDefaultaa"
                            // checked={brand.brandid === brandId}
                            onChange={(event) => setSubCat(event, subCat)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            {subCat?.subcategory?.categoryname}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* ================category=========== */}
          {brandName && (
            <div
              className="accordion accordion-flush list-group-item"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button fw-bold px-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="true"
                    aria-controls="flush-collapseOne"
                    style={{
                      minWidth:'100%'
                    }}
                  >
                    Category
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body pt-0">
                    {specificFilterList.map((category, index) => (
                      <div key={index} className="form-check ">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={category?.category?.categoryid}
                          id="flexCheckDefault"
                          // checked={brand.brandid === brandId}
                          onChange={(event) => setCategory(event, category)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          {category.category?.categoryname}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* ===subcat--- */}
          {Subcatname && (
            <>
              <div
                className="accordion accordion-flush list-group-item"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button fw-bold px-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="true"
                      aria-controls="flush-collapseOne"
                      style={{
                        minWidth:'100%'
                      }}
                    >
                      Brand 1212
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body pt-0">
                      {specificFilterList.map((brand, index) => (
                        <div key={index} className="form-check ">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={brand.brandid}
                            id="flexCheckDefault"
                            // checked={brand.brandid === brandId}
                            onChange={(event) => setBrand(event, brand)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            {brand?.brand?.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="accordion accordion-flush list-group-item"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button fw-bold px-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="true"
                      aria-controls="flush-collapseOne"
                    >
                      Sub Sub Category 122
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOnee"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-headingOnee"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body pt-0">
                      {specificFilterList.map((sub2Cat, index) => (
                        <div key={index} className="form-check ">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={sub2Cat?.sub2category?.categoryid}
                            id="flexCheckDefaultaa"
                            // checked={brand.brandid === brandId}
                            onChange={(event) => setSubSubCat(event, sub2Cat)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            {sub2Cat?.sub2category?.categoryname}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {Sub2catname && (
            <>
              <div
                className="accordion accordion-flush list-group-item"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button fw-bold px-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="true"
                      aria-controls="flush-collapseOne"
                    >
                      Brand =====(sub2cat)
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body pt-0">
                      {specificFilterList.map((brand, index) => (
                        <div key={index} className="form-check ">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={brand.brand?.brandid}
                            id="flexCheckDefault"
                            // checked={brand.brandid === brandId}
                            onChange={(event) => setBrand(event, brand)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            {brand.brand?.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {subSubCategoryList.map((sub2Cat) =>
                sub2Cat?.header?.map((header, index) => (
                  <div
                    className="accordion accordion-flush list-group-item"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button fw-bold px-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="true"
                          aria-controls="flush-collapseOne"
                        >
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={header.title}
                              id="flexCheckDefaultaa"
                              // checked={brand.brandid === brandId}
                              // onChange={(event) => setHeadervalue(event, data,header)}
                              onChange={(event) =>
                                setHeaderTitle(event, header)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              {header.title}
                            </label>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOnee"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingOnee"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body pt-0">
                          {header?.value?.map((data) => (
                            <div className="form-check ">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={data}
                                id="flexCheckDefaultaa"
                                // checked={brand.brandid === brandId}
                                onChange={(event) =>
                                  setHeadervalue(event, data, header)
                                }
                                // onChange={(event) => setHeadervalue(event,header)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                {data}
                              </label>
                            </div>
                          ))}
                          {/* ))
                                 )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </>
          )}
        </ol>
      </div>
    </div>
  );
};
export default FilterComponent;
