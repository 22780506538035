import {
    FETCH_SUB_CATAGORYLIST_REQUEST,
    FETCH_SUB_CATAGORYLIST_SUCCESS,
    FETCH_SUB_CATAGORYLIST_FAILURE,
    GET_SUBCATEGORY_BY_CATEGORYID_REQUEST,
    GET_SUBCATEGORY_BY_CATEGORYID_SUCCESS,
    GET_SUBCATEGORY_BY_CATEGORYID_FAILURE
} from "../../type";
const initialState = {
    isLoading: false,
    data: [],
    error: "",
    message: "",
    subCategoryByCategory: []
};

const SubCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SUB_CATAGORYLIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_SUB_CATAGORYLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                message: action.payload.message,
            };
        case FETCH_SUB_CATAGORYLIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };


        case GET_SUBCATEGORY_BY_CATEGORYID_REQUEST:
            return {
                ...state,
                isLoading: true,
                subCategoryByCategory: []
            };

        case GET_SUBCATEGORY_BY_CATEGORYID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                subCategoryByCategory: action.payload,
            };
        case GET_SUBCATEGORY_BY_CATEGORYID_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };




        default:
            return state;
    }
};

export default SubCategoryReducer;
