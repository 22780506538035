import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const Navigation = ({ categoryName = "", subCategoryName = "" }) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
  const categoryId = query.get("category");
  const catName = query.get("catname");
  const subCategoryId = query.get("subcategory");
  const subCatName = query.get("subcatname");
//   console.log("catName",catName)
    return (
        <>
            <div className="row justify-content-lg-start">
                <div className="col-xl-8 col-lg-5">
                    <nav style={{ style: "--bs-breadcrumb-divider: '>';" }} aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item text-capitalize"><Link to={`product?category=${categoryId}&catname=${catName}`}> {categoryName}</Link>  </li>
                            <li className="breadcrumb-item text-capitalize"> {subCategoryName}  </li>
                            {/* <li className="breadcrumb-item"><a href="#">Ceiling Fans</a></li>
                            <li className="breadcrumb-item"><a href="#">Orient Fans</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Orient Electric Fan</li> */}
                        </ol>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Navigation
