import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../components/homePage/Footer";
import Header from "../components/homePage/Header";
import MyAccount from "../components/savedForLater/MyAccount";
import SavedForLater from "../components/savedForLater/SavedForLater";
import { getBookmarkProducts } from "../store/actions/product/productListAction";
import { bookmarkProductAction } from "../store/actions/product/productAction";
import Loader from "../components/loader";
import { useHistory } from "react-router-dom";
import { noImageFound } from "../components/reuse/notFound";
import { removeCartItem } from "../store/actions/cartAction";

const SaveForLaterScreen = () => {
  const products = useSelector((state) => state.products.products);
  const loading = useSelector((state) => state.products.isLoading);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const productDetails = (product) => {
    history.push({
      pathname: "/product-details",
      search: `?product=${product.productId}&category=${
        product.category && product.category.categoryid
      }&catname=${
        product.category && product.category.id.categoryname
      }&subcategory=${
        product.subCategory && product.subCategory.categoryid
      }&subcatname=${
        product.subCategory && product.subCategory.id.categoryname
      }`,
    });
  };

  const removeBookmark = (productId) => {
    dispatch(bookmarkProductAction({ productId: productId })).then(
      (resolve) => {
        dispatch(getBookmarkProducts());
      }
    );
  };

  const removeItem = (product) => {
    const requestObj = {
      productId: product.productId,
      variantId: product.variantId,
    };
    dispatch(removeCartItem(requestObj));
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading, products]);

  useEffect(() => {
    dispatch(getBookmarkProducts());
  }, [dispatch]);

  return (
    <>
      <Header />
      <main className="page-wrapper">
        <section className="profile">
          <div className="container px-xxl-5 px-xl-4">
            <div className="row">
              <div className="col-lg-3 my-3 ">
                <div className="position-sticky-custom">
                  <div className="title pb-3">
                    <h5 className="fw-medium">My Account</h5>
                  </div>
                  <MyAccount />
                </div>
              </div>

              <div className="col-lg-9 cart">
                <div className="title pb-3 px-md-3">
                  <h5 className="fw-medium">Saved for111 later</h5>
                </div>
                <div className="row">
                  {isLoading ? (
                    <Loader />
                  ) : products.length > 0 ? (
                    products.map((product, index) => (
                      <SavedForLater
                        key={index}
                        product={product}
                        title={product.title}
                        price={product.variants[0]?.price}
                        baseImage={
                          product.imageUrls?.length > 0
                            ? product.imageUrls[0].url
                            : noImageFound()
                        }
                        removeBookmark={removeBookmark}
                        productDetails={productDetails}
                      />
                    ))
                  ) : (
                    <div className="">
                      <div className="text-center">
                        <img
                          className="mx-auto"
                          height="300"
                          src="../images/heart.png"
                          alt="cart-empty-img "
                        />
                        {/* Sorry nothing here, try adding some products to your cart first !! */}
                      </div>
                      {/* Sorry Nothing here, start adding product to your wishlist. */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default SaveForLaterScreen;
