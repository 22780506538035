import {
    GET_FEATURED_PRODUCTLIST_REQUEST,
    GET_FEATURED_PRODUCTLIST_SUCCESS,
    GET_FEATURED_PRODUCTLIST_FAILURE,
    GET_FEATURED_PRODUCTS_CATEGORY_REQUEST,
    GET_FEATURED_PRODUCTS_CATEGORY_SUCCESS,
    GET_FEATURED_PRODUCTS_CATEGORY_FAILURE
} from '../../type'


import {
    FETCH_PRODUCT_BY_SUBCATEGORY, FETCH_FEATURED_PRODUCT_URL
} from '../../../api/urls'

import axiosInstance from '../../../api/axiosInstance';



export const fetchFeaturedProductList = (data) => {
    return (dispatch) => {
        dispatch(fetchFeaturedProductRequest());
        // dispatch(setLoading())

        // api request
        try {
            axiosInstance
                .post(FETCH_PRODUCT_BY_SUBCATEGORY, data)
                .then((response) => {
                    let res = JSON.parse(response.data);



                    if (response.status === 200) {
                         dispatch(fetchFeaturedProductSuccess(res.data.products));
                        // dispatch(setLoadingFalse())
                    } else {
                        dispatch(fetchFeaturedProductFailure(res.message));
                        // dispatch(setLoadingFalse())
                    }
                })
                .catch((error) => {

                    dispatch(fetchFeaturedProductFailure(error.message));
                });
        } catch (e) { }
    };
};


export const fetchFeaturedProductCategoryList = (data) => {
    return (dispatch) => {
        dispatch(fetchFeaturedProductCategoryRequest());

        try {
            axiosInstance
                .post(FETCH_FEATURED_PRODUCT_URL, data)
                .then((response) => {
                    let res = JSON.parse(response.data);

                    
                    if (response.status === 200) {




                        dispatch(fetchFeaturedCategorySuccess(res.data.products));
                        // dispatch(setLoadingFalse())
                    } else {
                        dispatch(fetchFeaturedCategoryFailure(res.message));
                        // dispatch(setLoadingFalse())
                    }
                })
                .catch((error) => {

                    dispatch(fetchFeaturedCategoryFailure(error.message));
                });
        } catch (e) { }
    };
};



const fetchFeaturedProductRequest = () => {
    return {
        type: GET_FEATURED_PRODUCTLIST_REQUEST
    }
}

const fetchFeaturedProductSuccess = (productList) => {
    return {
        type: GET_FEATURED_PRODUCTLIST_SUCCESS,
        payload: productList
    }
}

const fetchFeaturedProductFailure = (error) => {
    return {
        type: GET_FEATURED_PRODUCTLIST_FAILURE,
        payload: error
    }
}


const fetchFeaturedProductCategoryRequest = () => {
    return {
        type: GET_FEATURED_PRODUCTS_CATEGORY_REQUEST
    }
}

const fetchFeaturedCategorySuccess = (productList) => {
    return {
        type: GET_FEATURED_PRODUCTS_CATEGORY_SUCCESS,
        payload: productList
    }
}

const fetchFeaturedCategoryFailure = (error) => {
    return {
        type: GET_FEATURED_PRODUCTS_CATEGORY_FAILURE,
        payload: error
    }
}



