import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from "../../type";

const initialState = {
  isLoading: false,
  data: [],
  error: "",
  message: "",
  isAuthenticated: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: action.payload,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.userData,
        message: action.payload.message,
        isAuthenticated: true,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default loginReducer;
