import React from "react";
import FilterComponent from "../components/product/FilterComponent";
import { useState } from "react";
// eslint-disable-next-line
import { productListBySubCategory } from "../store/actions/product/productListAction";
// eslint-disable-next-line
import { useDispatch, useSelector } from "react-redux";
import "../assets/css/pagination-custom.css";
import Loader from "../components/loader";
// eslint-disable-next-line
import ProductListComponent from "../components/product/ProductListComponent";
import BrandProductListComponent from "../components/product/productListBrandComponent";
import Layout from "../layout/Layout";
 
import ShopByCategoryFilter from "../components/homePage/ShopByCategoryFilter";

const BrandProductScreen = () => {
  const [showLargeComponent, setShowLargeComponent] = useState(false);
  const [showSmallComponent, setShowSmallComponent] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [priceSorter, setPriceSorter] = useState(1);
  const brandList = useSelector((state) => state.brands.brands);
  const products = useSelector((state) => state.products);
  const [selectedBrand,setSelectedBrand] = useState([])
  const [priceRange, setPriceRange] = useState([0, 100000]);
const [selectedCategory,setSelectedCategory]=useState([])
 const[selectedSubCategory,setSelectedSubCategory]=useState([])
 const[specificFilterList,setSpecificFilterList]=useState([])
 const[specificCatFilter,setSpecificCatFilter]=useState([])

// console.log("-----selectedCategory-----",selectedSubCategory)
  // eslint-disable-next-line
  const onClickHandler = () => {
    // console.log("clikcer");
    setShowSmallComponent(!showSmallComponent);
    setShowLargeComponent(!showLargeComponent);
  };

  // eslint-disable-next-line
  const onSortChange = (event) => {
    setPriceSorter(parseInt(event.target.value));
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Layout>
        <main className="page-wrapper">
          <div className="banner filter-all-products">
            <div className="container px-xxl-5 px-xl-4">
              <div className="row">
               
                <ShopByCategoryFilter  specificFilterList={specificFilterList}
                     setSpecificFilterList={setSpecificFilterList}
                     specificCatFilter={specificCatFilter}
                     setSpecificCatFilter={setSpecificCatFilter}/>
                <div className="col-xl-3">
                  <FilterComponent
                    productCount={products?.count}
                    brandList={brandList}
                    selectedBrand={selectedBrand}
                    setSelectedBrand={setSelectedBrand}
                    priceRange={priceRange}
                     setPriceRange={setPriceRange}
                     selectedCategory={selectedCategory}
                     setSelectedCategory={setSelectedCategory}
                     specificFilterList={specificFilterList}
                     setSpecificFilterList={setSpecificFilterList}
                    //  setSelectedSubCategory={setSelectedSubCategory}
                    //  selectedSubCategory={selectedSubCategory}
                
                  />
                </div>
                
                <BrandProductListComponent 
                selectedBrand={selectedBrand}
                setSelectedBrand={setSelectedBrand}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                specificFilterList={specificFilterList}
                setSpecificFilterList={setSpecificFilterList}
                specificCatFilter={specificCatFilter}
                setSpecificCatFilter={setSpecificCatFilter}
                // setSelectedSubCategory={setSelectedSubCategory}
                // selectedSubCategory={selectedSubCategory}
                priceRange={priceRange} />
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};
export default BrandProductScreen;
