import React, { useState } from "react";
import AmountFormat from "../amountFormat";
import { addToCartAction } from "../../store/actions/cartAction";
import { useDispatch } from "react-redux";

const SavedForLater = ({
  title,
  product,
  baseImage,
  price,
  removeBookmark,
  productDetails,
}) => {
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(1);
  const incrementCounter = () => setCounter(counter + 1);
  let decrementCounter = () => setCounter(counter - 1);
  if (counter <= 0) {
    decrementCounter = () => setCounter(1);
  }

  const addToCart = () => {
    // console.log("product-->", product)
    // console.log("productId-->", product.productId)
    // console.log("variantId-->", product.variants[0].variantId)
    // console.log("quantity-->", 5)

    let obj = {
      productId: product.productId,
      variantId: product.variants[0].variantId,
      quantity: counter,
    };
    dispatch(addToCartAction(obj))
      .then((result) => removeBookmark(product.productId))
      .catch((error) => console.log(error));
  };

  console.log("product--------", product);

  return (
    <>
      <div className="col-lg-4 px-2">
        <div className="py-1">
          <div className="mx-2 w-100">
            <div className="card border p-0 card-shadow w-100 ">
              <div className="btn btn-success rounded m-0 text-white fw-bold w-25 cost-font p-1 m-1">
                <span className="img-text">{product?.discount}% OFF</span>
              </div>
              <div>
                <img
                  src={baseImage}
                  alt="order img"
                  onClick={() => productDetails(product)}
                  className="mx-auto d-flex img-fluid"
                  // src={product?.imageUrls?.[0]?.url}
                  style={{ width: "235px", cursor: "pointer" }}
                />
              </div>
              <div className="card-body pt-1">
                <div className="card-text  ">
                  <p className="para-font fw-bold mb-1 text-truncate">
                    Ref No :- {product?.productCode}
                    <br />
                    <span className="text-truncate2 ">
                      Brand :- {product?.brand.id?.name}
                    </span>
                  </p>
                  <div
                    className="text-truncate2 fw-bold mb-1 mt-0 text-truncate"
                    style={{ fontSize: "large" }}
                  >
                    {" "}
                    {title}
                  </div>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row pb-0 pt-0  ">
                        <div class="d-flex bd-highlight">
                          <div class=" flex-grow-1    ">
                            <b>Price :-</b>
                            <span>
                              ₹
                              {product.price -
                                (product.price * product?.discount) / 100}
                            </span>
                          </div>
                          <div class=" bd-highlight ">
                            <b>MRP:- </b>{" "}
                            <span className="para-font">
                              {/* <del>₹{product.price}</del> */}
                              <del>
                                {" "}
                                <AmountFormat value={price} />
                              </del>
                            </span>
                          </div>
                        </div>

                        <div className="col-md-12 mt-3 d-flex align-item-center">
                          <div className="col-md-6 px-1">
                            <div
                              // onClick={() => addToCart(product)}
                              onClick={() => addToCart()}
                              className="btn btn-success rounded-0 m-0 text-white p-1 w-100"
                            >
                              Move to cart
                            </div>
                          </div>
                          <div className="col-md-6 px-1">
                            <span
                              className="btn btn-outline-dark rounded-0 m-0 p-1 w-100 "
                              onClick={() => removeBookmark(product.productId)}
                              style={{ cursor: "pointer" }}
                            >
                              {/* <i className="fas fa-trash text-light-gray "></i> */}
                              Remove
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedForLater;
