const SET_LOADING_TRUE = "SET_LOADING_TRUE";
const SET_LOADING_FALSE = "SET_LOADING_FALSE";

const initialState = {
    isLoading: false

};

const LoadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true,
            };

        case SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false,

            };

        default:
            return state;
    }
};

export default LoadingReducer;
