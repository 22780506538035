import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductCarouselCard from "./ProductCarouselCard";
import axiosInstance from "../../api/axiosInstance";
import { settings, slickConfig } from "../../utils/settings";

const   ProductCarousel = (props) => {
  const { title, type } = props;
  const [products, setProducts] = useState([]);

  console.log("prodycts========",products)

  
  useEffect(() => {
    function getProductByType() {
      let obj = {
        count: 0,
        field: "max",
        filters: { type: type },
        isActive: "true",
        limit: 9,
        sortBy: { type: "postDate", order: -1 },
      };

      try {
        axiosInstance
          .post(`/commonroutes/loadProducts`, obj)
          .then((response) => {
            let res = JSON.parse(response.data);
            if (res.code === 200) {
              setProducts(res.data.products);
            }
          })
          .catch((error) => {});
      } catch (e) {}
    }
    getProductByType();
  }, [type]);

  function showSection(type) {
    let bool = false;
    switch (type) {
     
      case "Best Deals":
        if (products?.length > 0) {
          bool = true;
        }
        return bool;

      case "Household Need":
        if (products?.length > 0) {
          bool = true;
        }
        return bool;

      case "Medical Need":
        if (products?.length > 0) {
          bool = true;
        }
        return bool;
        case "Similar Products":
          if (products?.length > 0) {
            bool = true;
          }
          return bool;
  

      case "Office Need":
        if (products?.length > 0) {
          bool = true;
        }
        return bool;
      default:
        return bool;
    }
  }

  // /sick setting

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="slick-arrow slick-next">
        <button
          type="button"
          onClick={onClick}
          role="presentation"
          class="btn rounded-pill border px-3 slider-navigation-button"
        >
          <span aria-label="Next">›</span>
        </button>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div class="slick-arrow slick-prev">
        <button
          type="button"
          onClick={onClick}
          role="presentation"
          class="btn rounded-pill border px-3 slider-navigation-button"
        >
          <span aria-label="Previous">‹</span>
        </button>
      </div>
    );
  }

  
    
   
  return (
    <div className={`contaner  px-sm-5 ${showSection(type) ? "" : "d-none"}`}>
      <div className="row section-heading text-center">
        <div className="col-12 main-heading">
          <h3 className="fw-bold">{title}</h3>
        </div>
      </div>
      <div className="pt-3">
        <div className="four-products-btn">
        
            {/* <Slider {...slickConfig(products?.length > 4)}> */}
            <Slider {...settings} >
              {products?.length > 0 &&
                products.map((product, index) => (
                  <ProductCarouselCard  key={index} product={product} />
                ))}
            </Slider>
      
        </div>
      </div>
    </div>
  );
};

export default ProductCarousel;
