import { combineReducers } from 'redux'
import signupReducer from '../reducers/auth/signupReducer'
import AddressReducer from './address/addAddressReducer'
import forgotPasswordReducer from './auth/forgotPasswordReducer'
import loginReducer from './auth/loginReducer'
import sendOtpReducer from './auth/sendOtpReducer'
import brandListReducer from './brand/brandReducer'
import cartReducer from './cart/cartReducer'
import CategoryReducer from './category/categoryReducer'
import bestDealsReducer from './deals/bestDealsReducer'
import LoadingReducer from './loadingReducer'
import orderReducer from './order/orderReducer'
import featuredProductListReducer from './product/featureProductListReducer'
import productListReducer from './product/productListReducer'
import productDetails from './product/productReducer'
import quotationReducer from './quotation/quotationReducer'
import productReview from './review/reviewReducer'
import SubCatagoryReducer from './subCategory/subCategoryReducer'
import SubSubCategoryReducer from './subSubCategory/subSubCategoryReducer'
import UserDetailsReducer from './user/userInfoReducer'

const rootReducer = combineReducers({

    signupData: signupReducer,
    bestDeals: bestDealsReducer,
    user: loginReducer,
    productDetails: productDetails,
    productReview: productReview,
    cart: cartReducer,
    order: orderReducer,
    products: productListReducer,
    quotation: quotationReducer,
    forgotPassword: forgotPasswordReducer,
    sendOtp: sendOtpReducer,
    category: CategoryReducer,
    subCategory: SubCatagoryReducer,
    subSubCategory:SubSubCategoryReducer,
    loading: LoadingReducer,
    brands: brandListReducer,
    featuredProducts: featuredProductListReducer,
    userInfo: UserDetailsReducer,
    address: AddressReducer

})

export default rootReducer