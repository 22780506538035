import React from "react";

const Description = ({ product }) => {
  return (
    <>
      <div className="card border-0">
        <div className="card-header bg-lighter-success border-0 rounded-0 fw-bold">
          Description
        </div>
        <div className="card-body px-sm-0 border-bottom text-dark">
          <p>{product?.description}</p>
        </div>
      </div>
      {product?.header.length > 0 ? (
        <div className="card border-0">
          <div className="card-header bg-lighter-success border-0 rounded-0 fw-bold">
            Specification
          </div>
          {product?.header?.map((items) => (
            // console.log("itemss===",items)
            <>
              <div className="row card-body px-sm-0 border-bottom mx-0">
                <div className="text-light-gray col-md-3 ">
                  <p className="text-dark mb-0">{items.title}</p>
                </div>

                <div className="col-lg-5">
                  {items.value?.map((data) => (
                    <div className="px-sm-0 text-light-gray">
                      <p className="mb-0">{data}</p>
                    </div>

                    // console.log("data------,",data)/
                  ))}
                </div>
              </div>
            </>
          ))}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Description;
