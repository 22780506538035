import React from "react";
import AmountFormat from "../amountFormat";

function OrderSummaryPriceComponent({ priceSummary }) {
  return (
    <>
      <div className="small">

      <div className="d-flex justify-content-between py-2 left-text">
          <div className="title">MRP</div>
          <div className="title-value">
            <AmountFormat value={priceSummary?.total} />
          </div>
        </div>

        <div className="d-flex justify-content-between py-2 left-text">
          <div className="title">Discount</div>
          <div className="title-value text-success">
            - <AmountFormat value={priceSummary?.totaldiscount} />
          </div>
        </div>

        <div className="d-flex justify-content-between py-2 left-text">
          <div className="title">Coupon Discount</div>
          <div className="title-value text-success">
            - <AmountFormat value={priceSummary?.totaldiscount} />
          </div>
        </div>

        <div className="d-flex justify-content-between py-2 left-text">
          <div className="title">Shipping</div>
          <div className="title-value">
            <AmountFormat value={priceSummary?.deliveryCharges} />
          </div>
        </div>

        <div className="d-flex justify-content-between py-2 left-text">
          <div className="title">Subtotal</div>
          <div className="title-value">
            <AmountFormat value={priceSummary?.total} />
          </div>
        </div>


        

        {/* <div className="d-flex justify-content-between py-2 left-text">
          <div className="title">Tax</div>
          <div className="title-value">
            <AmountFormat value={priceSummary?.totaltax} />
          </div>
        </div> */}

       

       

        {/* <div className="d-flex justify-content-between py-2 left-text">
          <div className="title">
            Promocode{" "}
            <span className="text-success text-uppercase small">FIRST50</span>
            <button className="btn p-0 text-light-gray btn-sm">
              <i className="fas fa-times-circle"></i>
            </button>
          </div>
          <div className="title-value text-success">- ₹ 456</div>
        </div> */}
      </div>
      <div className="d-flex h6 fw-medium justify-content-between py-2 left-text">
        <div className="totel-price">Order Total</div>
        <div className="title-value">
          <AmountFormat value={priceSummary?.grandTotal} />
        </div>
      </div>
    </>
  );
}

export default OrderSummaryPriceComponent;
