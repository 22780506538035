import React from 'react'
import Footer from '../components/homePage/Footer'
import Header from '../components/homePage/Header'
import EditProfile from '../components/savedForLater/EditProfile'
import MyAccount from '../components/savedForLater/MyAccount'
import { isUser } from '../utils/auth';

const EditProfileScreen = () => {
    const user = isUser()
    return (
        <>
            <Header />
            <main className="page-wrapper">
                <section className="profile">
                    <div className="container px-xxl-5 px-xl-4">
                        <div className="row">
                            <div className="col-lg-3 d-xl-block d-none ">
                                <div className="position-sticky-custom">
                                    <div className="title pb-3">
                                        <h5 className="fw-medium">My Account</h5>
                                    </div>
                                    <MyAccount />
                                </div></div>
                            <div className="col-lg-9">
                                <div className="title pb-3 px-md-3">
                                    <h5 className="fw-medium">Edit Profile</h5>
                                </div>
                                <EditProfile authUserId={user.userId} />
                            </div>


                        </div></div></section></main>
            <Footer />

        </>
    )
}

export default EditProfileScreen
