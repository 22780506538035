import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ProductCarousel from "../components/homePage/ProductCarousel";
import Description from "../components/product-details/Description";
import Item from "../components/product-details/Item";
import ItemDetails from "../components/product-details/ItemDetails";
import Navigation from "../components/product-details/Navigation";
import Pagination from "../components/product-details/Pagination";
import Reviews from "../components/product-details/Reviews";
import Loader from "../components/loader";
import {
  getProduct,
  bookmarkProductAction,
  getProductWithoutLoading,
} from "../store/actions/product/productAction";
import Swal from "sweetalert2";
import { addToCartAction, fetchCartAction, fetchCartActionV2, getCartPriceAction, updateCartItemCount } from "../store/actions/cartAction";
import { addItemToQuotationList } from "../store/actions/quotation/quotationAction";
import Layout from "../layout/Layout";
import { isAuth } from "../utils/auth";
import { noImageFound } from "../components/reuse/notFound";

const ProductDetailsScreen = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [productDetails, setProductDetails] = useState(null);
  const[cart,setCart]=useState(false)

  const loading = useSelector((state) => state.productDetails.isLoading);
  const product = useSelector((state) => state.productDetails.productDetails);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const productId = query.get("product");
  // const categoryId = query.get('category')
  // const categoryName = query.get('catname')
  // const subCategoryId = query.get('subcategory')
  // const subCategoryName = query.get('subcatname')

  const authed = isAuth();

  const Alert = (title, icon, path) => {
    Swal.fire({
      title: title,
      text: "",
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Continue",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
      } else {
      }
    });
  };

  const addToQuotation = (product, quantity, productVariant) => {
    const requestObj = {
      productId: product.productId,
      variantId: productVariant.variantId,
      quantity: quantity,
      productName: product.title,
      productImage: product.imageUrls[0]?.url,
      variant1: productVariant.variant1 ? productVariant.variant1 : "",
      skucode: productVariant.skucode,
      price: productVariant.price,
      slug: "slug",
      mrp: productVariant.mrp,
    };

    dispatch(addItemToQuotationList(requestObj)).then(
      (resolve) => {
        Alert("Product added Quotation List", "success");
      },
      (reject) => Alert("Something went wrong, please try later", "error")
    );
  };

  const addBookmark = () => {
    if (isAuth()) {
      dispatch(bookmarkProductAction({ productId: productId })).then(
        (result) => {
          dispatch(getProductWithoutLoading({ productId: productId }));
          if (result.message === "bookmarked") {
            Alert("Product Added in Saved For Later", "success");
          } else {
            Alert("Product Removed from Saved For later", "error");
          }
        }
      );
    } else {
      Alert("Please login", "error");
    }
  };

  const addProductToCart = (product, quantity, productVariant) => {
    // console.log(productVariant)

    const requestObj = {
      productId: product.productId,
      variantId: productVariant.variantId,
      quantity: quantity,
      productName: product.title,
      productImage: product.imageUrls[0]?.url,
      variant1: productVariant.variant1 ? productVariant.variant1 : "",
      skucode: productVariant.skucode,
      price: productVariant.price,
      slug: "slug",
      mrp: productVariant.mrp,
    };

    if (authed) {
      dispatch(addToCartAction(requestObj)).then(
        (resolve) => {
          Alert("Product added to your cart", "success");
          setCart(true)
        },
        (reject) => Alert("Something went wrong, please try later", "error")
      );
    } else {
      Alert("Please login", "error");
    }
  };

  useEffect(() => {
    dispatch(getProduct({ productId: productId }));
  }, [dispatch, productId]);

  useEffect(() => {
    setIsLoading(loading);
    setProductDetails(product);
  }, [loading, product]);


  const updateCartCount = (product, quantity) => {
    const requestObj = {
      productId: product.productId,
      variantId: product.variantId,
      quantity: quantity,
    };

    dispatch(updateCartItemCount(requestObj)).then(
      (resolve) => {
        dispatch(fetchCartActionV2());
        // dispatch(fetchCartAction());
        dispatch(
          getCartPriceAction({
            type: "cod",
            voucherCode: "",
          })
        );
      },
      (reject) => {
        dispatch(fetchCartAction());
      }
    );
  };

  return (
    <Layout>
      {isLoading ||
      productDetails.category === undefined ||
      productDetails.subCategory === undefined ? (
        <Loader />
      ) : (
        <main class="page-wrapper">
          <section class="pt-3">
            <div class="container px-sm-5">
              <Navigation
                categoryName={productDetails.category.id.categoryname}
                subCategoryName={productDetails.subCategory.id.categoryname}
              />
 
              <div class="row">
                <div class="col-xl-5 col-lg-5 pt-3">
                  <Item
                    productId={productId}
                    baseImage={
                      productDetails.imageUrls?.length > 0
                        ? productDetails.imageUrls[0].url
                        : noImageFound()
                    }
                    imageArray={
                      productDetails.imageUrls?.length > 0
                        ? productDetails.imageUrls
                        : [{ url: noImageFound() }]
                    }
                    addBookmark={addBookmark}
                    product={productDetails}
                  />
                </div>
                <div class="col-xl-7 col-lg-7 p-3 pb-5">
                  
                  <ItemDetails
                    product={product}
                    productName={productDetails.title}
                    price={productDetails.price}
                    addToCart={addProductToCart}
                    addToQuotation={addToQuotation}
                    cart={cart}
                    updateCartCount={updateCartCount}
                  />
                </div>
              </div>
              <div class="row pt-5">
                <div class="col-12">
                  <Description product={product} />
                </div>
              </div>
            </div>
          </section>

          <section class="reviews">
            <div class="container">
              <div class="row">
                <Reviews product={product}/>
              </div>
              <div class="row justify-content-center">
                <div className="col-auto">
                  <div className="justify-content-center d-flex pt-4">
                    <Pagination />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="">
            <ProductCarousel title="Similar Products" type="Similar Products" />
          </section>
        </main>
      )}
    </Layout>
  );
};

export default ProductDetailsScreen;
