import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from "../type";
import axiosInstance from "../../api/axiosInstance";
import { LOGIN_URL } from "../../api/urls";


export const login = (loginCredentials) => {
  return (dispatch) => new Promise((resolve, reject) => {

    dispatch(loginRequest(true));
    try {
      axiosInstance
        .post(LOGIN_URL, loginCredentials)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (response.status === 200) {
            if (res.message === 'success') {

              if(res.userData.role === "customer"){
                // console.log("customer-->", res.userData.role )
                localStorage.setItem("userData", JSON.stringify(res.userData));
                localStorage.setItem("accessToken", res.userData.sessionId);
                dispatch(loginSuccess(res));
                resolve(res.message)
              }else{
                reject("Invalid Credentials")
              }
            } else {
              reject("Invalid Credentials")
              dispatch(loginFailure(res.message))
              reject()
            }

          } if (res.code === 500) {
            reject("Invalid Credentials")
            dispatch(loginFailure(res.message))
            reject()
          }
        })
        .catch((error) => {
          // console.log(error.message);
          reject("Invalid Credentials")
          dispatch(loginFailure(error.message));
        }).finally(()=>  dispatch(loginRequest(false)));
    } catch (e) { }


  }

  )

};

// login state functions

const loginRequest = (isTrue) => {
  return {
    type: LOGIN_REQUEST,
    payload:isTrue
  };
};

const loginSuccess = (response) => {
  return {
    type: LOGIN_SUCCESS,
    payload: response,
  };
};

const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};
