import {
  GET_PRODUCTLIST_BY_BRAND_REQUEST,
  GET_PRODUCTLIST_BY_BRAND_SUCCESS,
  GET_PRODUCTLIST_BY_BRAND_FAILURE,
  GET_PRODUCTLIST_BY_SUBCATEGORY_REQUEST,
  GET_PRODUCTLIST_BY_SUBCATEGORY_SUCCESS,
  GET_PRODUCTLIST_BY_SUBCATEGORY_FAILURE,
  GET_BOOKMARK_PRODUCT_LIST_REQUEST,
  GET_BOOKMARK_PRODUCT_LIST_SUCCESS,
  GET_BOOKMARK_PRODUCT_LIST_FAILURE,
} from "../../type";

import {
  FETCH_PRODUCT_BY_SUBCATEGORY,
  GET_BOOKMARK_PRODUCTS_URL,
} from "../../../api/urls";

import axiosInstance from "../../../api/axiosInstance";
// import { setLoading, setLoadingFalse } from '../loadingAction'

export const productListBySubCategory = (data) => {
  return (dispatch) => {
    dispatch(getProductListBySubCategoryRequest());

    // api request
    try {
      axiosInstance
        .post(FETCH_PRODUCT_BY_SUBCATEGORY, data)
        .then((response) => {
          let res = JSON.parse(response.data);

          if (response.status === 200) {
            dispatch(getProductListBySubCategorySuccess(res.data));
          } else {
            dispatch(getProductListBySubCategoryFailure(res.message));
          }
        })
        .catch((error) => {
          dispatch(getProductListBySubCategoryFailure(error.message));
        });
    } catch (e) {}
  };
};

export const productListBySubCategoryWithoutLoader = (data) => {
  return (dispatch) => {
    // api request
    try {
      axiosInstance
        .post(FETCH_PRODUCT_BY_SUBCATEGORY, data)
        .then((response) => {
          let res = JSON.parse(response.data);

          if (response.status === 200) {
            dispatch(getProductListBySubCategorySuccess(res.data));
          } else {
            dispatch(getProductListBySubCategoryFailure(res.message));
          }
        })
        .catch((error) => {
          dispatch(getProductListBySubCategoryFailure(error.message));
        });
    } catch (e) {}
  };
};

export const getBookmarkProducts = () => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(getBookmarkProductListRequest());
      try {
        axiosInstance
          .post(GET_BOOKMARK_PRODUCTS_URL)
          .then((response) => {
            let res = JSON.parse(response.data);
            console.log(res);
            if (response.status === 200) {
              dispatch(getBookmarkProductListSuccess(res.result));
              resolve();
            } else {
              dispatch(getBookmarkProductListFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            console.log(error.message);
            dispatch(getBookmarkProductListFailure(error.message));
          });
      } catch (e) {}

      // alert(error)
    });
};

export const getProductListByBrandRequest = () => {
  return {
    type: GET_PRODUCTLIST_BY_BRAND_REQUEST,
  };
};

export const getProductListByBrandSuccess = (productList) => {
  return {
    type: GET_PRODUCTLIST_BY_BRAND_SUCCESS,
    payload: productList,
  };
};

export const getProductListByBrandFailure = (error) => {
  return {
    type: GET_PRODUCTLIST_BY_BRAND_FAILURE,
    payload: error,
  };
};

export const getProductListBySubCategoryRequest = () => {
  return {
    type: GET_PRODUCTLIST_BY_SUBCATEGORY_REQUEST,
  };
};
export const getProductListBySubCategorySuccess = (productList) => {
  return {
    type: GET_PRODUCTLIST_BY_SUBCATEGORY_SUCCESS,
    payload: productList,
  };
};
export const getProductListBySubCategoryFailure = (error) => {
  return {
    type: GET_PRODUCTLIST_BY_SUBCATEGORY_FAILURE,
    payload: error,
  };
};

export const getBookmarkProductListRequest = () => {
  return {
    type: GET_BOOKMARK_PRODUCT_LIST_REQUEST,
  };
};
export const getBookmarkProductListSuccess = (productList) => {
  return {
    type: GET_BOOKMARK_PRODUCT_LIST_SUCCESS,
    payload: productList,
  };
};
export const getBookmarkProductListFailure = (error) => {
  return {
    type: GET_BOOKMARK_PRODUCT_LIST_FAILURE,
    payload: error,
  };
};
