import {
    FETCH_CATAGORYLIST_REQUEST,
    FETCH_CATAGORYLIST_SUCCESS,
    FETCH_CATAGORYLIST_FAILURE,

} from "../../type";
const initialState = {
    isLoading: false,
    data: [],
    error: "",
    message: "",
};

const CategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CATAGORYLIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_CATAGORYLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                message: action.payload.message,
            };
        case FETCH_CATAGORYLIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default CategoryReducer;
