import React, { useEffect, useState } from 'react'
import Header from '../components/homePage/Header'
import Footer from '../components/homePage/Footer'
// import MyAddressSideMenuCard from '../components/product/MyAddressSideMenuCard'
import SaveAddressComponent from '../components/product/SaveAddressComponent'
import { Link } from 'react-router-dom'
import MyAccount from '../components/savedForLater/MyAccount'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/loader'
import { getSavedAddress, removeAddress } from '../store/actions/address/addAddressAction'
import Swal from 'sweetalert2'

const MyAddress = () => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true)
    const loading = useSelector(state => state.address.isLoading)
    const [addressList, setAddressList] = useState([])
    const address = useSelector(state => state.address.address)


    const Alert = (title, icon, addressId) => {
        Swal.fire({
            title: title,
            text: "",
            icon: icon,
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "tomato",
            dangerMode: true,
        }).then((isConfirm) => {
            if (isConfirm.isConfirmed) {
                

                dispatch(removeAddress({ addressId: addressId })).then((resolve) => {
                    dispatch(getSavedAddress())
                })

            } else {

            }
        });
    };

    const removeSavedAddress = (addressId) => {

        if(addressList && addressList.length >1){
            Alert('Do you want to remove this address ?', 'warning', addressId)
        }else{

            Swal.fire({
                title: "Please Add New Address Before Remove It",
                text: "",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: "Ok",
                confirmButtonColor: "tomato",
                dangerMode: true,
            })
        }
    }

    useEffect(() => {
        setIsLoading(loading)
        setAddressList(address)
    }, [loading, address])

    useEffect(() => {
        dispatch(getSavedAddress())
    }, [dispatch])
    return (
        <>
            <Header />


            <main className="page-wrapper">
                <section className="profile">
                    <div className="container px-xxl-5 px-xl-4">
                        <div className="row">


                            <div className="col-lg-3 d-xl-block d-none ">

                                <div className="position-sticky-custom">

                                    <div className="title pb-3">
                                        <h5 className="fw-medium">My Account</h5>
                                    </div>

                                    <MyAccount />

                                </div>

                            </div>



                            <div className="col-lg-9">

                                <div className="title pb-3 px-md-3">
                                    <h5 className="fw-medium">Saved Addresses</h5>
                                </div>


                                {
                                    isLoading ? <Loader /> :
                                        <>

                                            {

                                                addressList && addressList.length === 0 ?

                                                    <div className="text-success">
                                                        No address here, try adding some address to your list.
                                                    </div>

                                                    :
                                                    addressList && addressList.map(address => (
                                                        <SaveAddressComponent
                                                            name={address.firstName + " " + address.lastName}
                                                            address={address.area}
                                                            city={address.city}
                                                            state={address.state}
                                                            contact={address.phoneNumber}
                                                            addressType="Home"
                                                            addressId={address.addressId}
                                                            removeAddress={removeSavedAddress}
                                                            pincode={address.pincode}
                                                        />

                                                    ))}




                                            <div class="d-grid btn-holder my-4">
                                                <Link to="/add-new-address" class="btn btn-outline-light-success"><i class="fas fa-plus"></i> Add a new address</Link>
                                            </div>
                                        </>
                                }



                            </div>

                        </div>

                    </div>
                </section>

            </main>


            <Footer />

        </>
    )
}

export default MyAddress
