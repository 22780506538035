import {
    GET_BRANDLIST_REQUEST,
    GET_BRANDLIST_SUCCESS,
    GET_BRANDLIST_FAILURE
} from '../../type'




const initialState = {
    isLoading: false,
    brands: [],
    error: '',
    message: ''

}
const brandListReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_BRANDLIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case GET_BRANDLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                brands: action.payload,

            }

        case GET_BRANDLIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        default:
            return state
    }

}

export default brandListReducer