import {

    SEND_OTP_REQUEST,
    SEND_OTP_SUCCESS,
    SEND_OTP_FAILURE,
    VALIDATE_OTP_REQUEST,
    VALIDATE_OTP_SUCCESS,
    VALIDATE_OTP_FAILURE
} from '../../type'




const initialState = {
    isLoading: true,
    sendOtpResponse: {},
    validateOtpResponse: {},
    error: '',
    message: ''

}
const sendOtpReducer = (state = initialState, action) => {
    switch (action.type) {

        case SEND_OTP_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case SEND_OTP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                sendOtpResponse: action.payload,
                message: action.payload.message
            }

        case SEND_OTP_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case VALIDATE_OTP_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case VALIDATE_OTP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                validateOtpResponse: action.payload,
                message: action.payload.message
            }

        case VALIDATE_OTP_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }


        default:
            return state
    }

}

export default sendOtpReducer