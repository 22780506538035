import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { slickConfig } from "../../utils/settings";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useState } from "react";
function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      // className="'h4 btn-light bg-white shadow text-danger py-3 px-2 slick-prev slick-arrow position-absolute top-50 start-0 translate-middle'"

      className="h4 btn-light bg-white shadow text-danger py-3 px-2   slick-arrow position-absolute top-50 start-0 translate-middle"
      onClick={onClick}
    >
      <i className="bi bi-chevron-left"></i>
    </div>
  );
}
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="h4 btn-light bg-white shadow text-danger py-3 px-2  slick-arrow position-absolute top-50 start-100 translate-middle"
      onClick={onClick}
    >
      <i className="bi bi-chevron-right"></i>
    </div>
  );
}

function ShopBySubCategoryFilter({
  setSelectedSubCatBrandFilter,
  specificFilterList,
}) {
  const state = useSelector((state) => state.subCategory);
  console.log("specificFilterList",specificFilterList)
  const[active,setActive]=useState([])
  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,

    slidesToScroll: 1,
    // prevArrow: <SamplePrevArrow/>,

    // nextArrow: <SampleNextArrow/>,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  const filterProduct = (categoryId) => {
    
    setSelectedSubCatBrandFilter([categoryId]);
    setActive(categoryId)
  };
  return (
    <>
      <div className="container four-products-btn mt-2">
        <div className="row section-heading text-center">
          <div className="col-12 main-heading">
            <h6 className="">Shop By Sub Category</h6>
          </div>
        </div>

        <div className="four-products-carousel">
          {/* <Slider {...slickConfig(state.brands?.length > 2)}> */}
          <Slider {...settings}>
            {specificFilterList?.length > 0 &&
             specificFilterList.map((data, index) => (
                <div key={index} onClick={() => filterProduct(data?.subcategory?.categoryid)}
                className={`${active===data?.subcategory?.categoryid?"activeK":""}`}
                 >
                                

                  {/* <Link
                    to={`/brand?brand=${brand.brandId}&brandName=${brand.name}`}
                  > */}
  {data?.subcategory?.bannerImages.length>0?
   <img
  src={data?.subcategory?.bannerImages?.[0]}
  alt={data.name}
  style={{ height: 100 }}
/>
  :"Image Not Found"}  
                 
                  {/* </Link> */}
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default ShopBySubCategoryFilter;
