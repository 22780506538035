import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartBox from "../components/order/CartBox";
import DateRange from "../components/order/DateRange";
import { getMyOrdersList } from "../store/actions/order/orderAction";
import Loader from "../components/loader";
import Layout from "../layout/Layout";

const OrderScreen = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.order.isLoading);
  const orders = useSelector((state) => state.order.orderList);
  const [isLoading, setIsLoading] = useState(true);
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    setIsLoading(loading);
    setOrderList(orders);
  }, [loading, orders]);

  useEffect(() => {
    dispatch(getMyOrdersList());
  }, [dispatch]);
  return (
      <Layout>
      <main className="page-wrapper">
        <div className="my-orders-title">
          <div className="container px-xxl-5 px-xl-4">
            <div className="row justify-content-between">
              <div className="col-lg-6 align-self-lg-start">
                <div className="h2 pt-5 fw-bold fs-3">My Orders</div>
              </div>
              <div className="col-lg-4 pt-5">
                <p className="title-success">Order placed between:-</p>
                <DateRange />
              </div>
            </div>
          </div>
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <section className="order-details cart pt-3">
            <div className="container px-xxl-5 px-xl-4">
              <div className="row">
                {orderList?.length > 0 &&
                  orderList.map((order, index) => (
                    <div
                    key={index}
                      className="accordion accordion-flush my-3"
                      id="accordionFlushExample"
                    >
                      {order.products.map((product, index) => (
                        <CartBox key={index} order={order} product={product} />
                      ))}
                    </div>
                  ))}
                {/* <div
                    className="accordion accordion-flush my-3"
                    id="accordionFlushExample"
                  >
                    <CartBox />
                  </div> */}

                {/* <div className="col-12">
                  <div className="justify-content-center d-flex pt-4">
                    <Pagination />
                  </div>
                </div> */}
              </div>
            </div>
          </section>
        )}
      </main>
      </Layout>
  );
};

export default OrderScreen;
