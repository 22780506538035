import {
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILURE,
  APPLY_PROMOCODE_REQUEST,
  APPLY_PROMOCODE_SUCCESS,
  APPLY_PROMOCODE_FAILURE,
  REMOVE_PROMOCODE_REQUEST,
  REMOVE_PROMOCODE_SUCCESS,
  REMOVE_PROMOCODE_FAILURE,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  FETCH_ORDER_LIST_REQUEST,
  FETCH_ORDER_LIST_SUCCESS,
  FETCH_ORDER_LIST_FAILURE,
} from "../../type";
import {
  PLACE_ORDER_URL,
  VALIDATE_VOUCHER_URL,
  FETCH_ORDER_LIST_URL,
} from "../../../api/urls";
import axiosInstance from "../../../api/axiosInstance";

export const fetchOrder = (orderId) => {
  return (dispatch) => {
    dispatch(fetchOrderDetailsRequest());

    //
    // axios
    //     .get('https://jsonplaceholder.typicode.com/comments')
    //     .then(response => {
    //         // response.data is the users
    //         const posts = response.data
    //         dispatch(fetchSubAdminSuccess(posts))
    //     })
    //     .catch(error => {
    //         // error.message is the error message
    //         dispatch(fetchSubAdminFailure(error.message))
    //     })

    dispatch(fetchOrderDetailsSuccess());
  };
};

export const placeOrderAction = (requestObj) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(placeOrderRequest());
      try {
        axiosInstance
          .post(PLACE_ORDER_URL, requestObj)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(placeOrderSuccess(res.result));
              resolve();
            } else {
              dispatch(placeOrderFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            dispatch(placeOrderFailure(error.message));
          });
      } catch (e) {}
    });
};

export const applyPromocode = (requestObj) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(applyPromocodeRequest());
      try {
        axiosInstance
          .post(VALIDATE_VOUCHER_URL, requestObj)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(applyPromocodeSuccess(res));
              resolve(res);
            } else {
              dispatch(applyPromocodeFailure(res.message));
              resolve(res);
            }
          })
          .catch((error) => {
            dispatch(applyPromocodeFailure(error.message));
          });
      } catch (e) {}
    });
};

export const getMyOrdersList = () => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(fetchOrderListRequest());
      try {
        axiosInstance
          .post(FETCH_ORDER_LIST_URL)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(fetchOrderListSuccess(res));
              resolve();
            } else {
              dispatch(fetchOrderListFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            dispatch(fetchOrderListFailure(error.message));
          });
      } catch (e) {}
    });
};

export const removePromocode = (cartId) => {
  return (dispatch) => {
    dispatch(removePromoCodeRequest());
    // api call with cart Id
    const newCartData = ""; // this we get from api after promocode is removed

    dispatch(removePromocodeSuccess(newCartData));
  };
};

const fetchOrderDetailsRequest = () => {
  return {
    type: FETCH_ORDER_REQUEST,
  };
};

const fetchOrderDetailsSuccess = (orderData) => {
  return {
    type: FETCH_ORDER_SUCCESS,
    payload: orderData,
  };
};

// eslint-disable-next-line
const fetchOrderDetailsFailure = (error) => {
  return {
    type: FETCH_ORDER_FAILURE,
    payload: error,
  };
};

const applyPromocodeRequest = () => {
  return {
    type: APPLY_PROMOCODE_REQUEST,
  };
};

const applyPromocodeSuccess = (newSummary) => {
  return {
    type: APPLY_PROMOCODE_SUCCESS,
    payload: newSummary,
  };
};

const applyPromocodeFailure = (error) => {
  return {
    type: APPLY_PROMOCODE_FAILURE,
    payload: error,
  };
};

const removePromoCodeRequest = () => {
  return {
    type: REMOVE_PROMOCODE_REQUEST,
  };
};

const removePromocodeSuccess = (cartData) => {
  return {
    type: REMOVE_PROMOCODE_SUCCESS,
    payload: cartData,
  };
};

// eslint-disable-next-line
const removePromocodeFailure = (error) => {
  return {
    type: REMOVE_PROMOCODE_FAILURE,
    payload: error,
  };
};

const placeOrderRequest = () => {
  return {
    type: PLACE_ORDER_REQUEST,
  };
};

const placeOrderSuccess = (response) => {
  return {
    type: PLACE_ORDER_SUCCESS,
    payload: response,
  };
};

const placeOrderFailure = (error) => {
  return {
    type: PLACE_ORDER_FAILURE,
    payload: error,
  };
};

const fetchOrderListRequest = () => {
  return {
    type: FETCH_ORDER_LIST_REQUEST,
  };
};

const fetchOrderListSuccess = (orderList) => {
  return {
    type: FETCH_ORDER_LIST_SUCCESS,
    payload: orderList,
  };
};

const fetchOrderListFailure = (error) => {
  return {
    type: FETCH_ORDER_LIST_FAILURE,
    payload: error,
  };
};
