import {
    FETCH_ORDER_REQUEST,
    FETCH_ORDER_SUCCESS,
    FETCH_ORDER_FAILURE,
    PLACE_ORDER_REQUEST,
    PLACE_ORDER_SUCCESS,
    PLACE_ORDER_FAILURE,
    FETCH_ORDER_LIST_REQUEST,
    FETCH_ORDER_LIST_SUCCESS,
    FETCH_ORDER_LIST_FAILURE
} from '../../type';



const initialState = {
    isLoading: false,
    order: [],
    error: '',
    message: '',
    orderList: []

}
const orderReducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_ORDER_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case FETCH_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                order: action.payload,
                message: action.payload.message
            }

        case FETCH_ORDER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case PLACE_ORDER_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case PLACE_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.payload
            }

        case PLACE_ORDER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case FETCH_ORDER_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case FETCH_ORDER_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                orderList: action.payload
            }

        case FETCH_ORDER_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        default:
            return state
    }

}

export default orderReducer