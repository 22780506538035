import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { useRef } from "react";
import { getBanner } from '../../store/actions/banners.js/banner.action';
function Banner() {
    const slickRef = useRef();
    const [topBanner,SetTopBanner]=useState([])

    const play = () => {
        slickRef.current?.slickPlay();
      };
      const pause = () => {
        slickRef.current?.slickPause();
      };

 const dispatch=useDispatch()
   
    useEffect(()=>{
        let obj={
            "filters":{
                "title":""
            }
        }
         getBanner(obj,SetTopBanner)
    } ,[]);
       
        
    var settings = {
        arrows: false,
        infinite: true,
        speed: 900,
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
    
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 430,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };


    return (
        <>
        

<div>
        <div className="wide ps-0 pt-0 pe-0">
          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div
              onMouseOver={() => play()}
              onMouseLeave={() => pause()}
              className="img-holder hover-images-pro"
            >
              <Slider {...settings}>
                {topBanner?.map((item, i) => (
             <div key={i}>
                  
                   <a href={item?.link} target="_blank"  rel="noreferrer">
                  
                    <div className="img-holder" >
                       {/* <div
                       className='wide-img-height'
                        style={{
                          backgroundImage: `url(${item?.image})`,
                          // height: "400px",
                          width: "100%",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div> */}
                      <img className="img-fluid w-100" style={{height:"400px" }} src={item?.image} alt="" /> 
                    </div>
                  </a>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}
export default Banner