import React from 'react'

function ReviewProductPills({ pillsList, title }) {
    return (
        <div className="d-flex flex-sm-row flex-column font-medium my-3">
            <div className="text-light-gray mb-0 py-2">{title} : </div>
            <div className="text-light-gray mb-0">
                {
                    pillsList.map(pill => (

                        <button className="btn btn-outline-light-gray rounded-pill m-1">Quality</button>

                    ))
                }
            </div>
        </div>
    )
}

export default ReviewProductPills
