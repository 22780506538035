import React, { useEffect, useState } from "react";
import Header from "../components/homePage/Header";
import OrderSummaryComponent from "../components/product/OrderSummaryComponent";
import Footer from "../components/homePage/Footer";
import ReviewPayComponent from "../components/product/ReviewPayComponent";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCartAction,
  getCartPriceAction,
} from "../store/actions/cartAction";
import { placeOrderAction } from "../store/actions/order/orderAction";
import { getAddressById } from "../store/actions/address/addAddressAction";
import Loader from "../components/loader";
import Swal from "sweetalert2";

const ReviewPay = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selfTransport,setSelfTransport]=useState(false)
  const cartDetails = useSelector((state) => state.cart.cartDetails);
  const userAddress = useSelector((state) => state.address?.addressDetails);
  const [cartOrderSummary, setCartOrderSummary] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const addId = query.get("addressId");
  // console.log(addId)
  const loading = useSelector((state) => state.cart.isLoading);
  const history = useHistory();
  const address = localStorage.getItem("deliveryAddress")
    ? JSON.parse(localStorage.getItem("deliveryAddress"))
    : null;
  const cart = useSelector((state) => state.cart.cart);

  const Alert = (title, icon, path) => {
    Swal.fire({
      title: title,
      text: "",
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Continue",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        history.push("/");
      } else {
        history.push("/");
      }
    });
  };

  useEffect(() => {
    setCartOrderSummary(cartDetails);
    setIsLoading(loading);
  }, [cartDetails, loading, cart]);

  // paytm pay

  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === "[object Date]";
  }

  function isObj(val) {
    return typeof val === "object";
  }

  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }

  function buildForm({ action, params }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params).forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", stringifyValue(params[key]));
      form.appendChild(input);
    });

    return form;
  }

  function post(details) {
    const form = buildForm(details);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }


  const getData = (data) => {
    let token = localStorage.getItem("accessToken")
    console.log(token)
    // token = token.replaceAll(`"`, "")

    return fetch("http://3.111.112.229:7080/transaction/create-transaction", {
    // return fetch(`${BASE_URL}/transaction/create-transaction`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .catch((error) => console.error(error));
  };


  

  const makePayment = async () => {
    console.log("makepayment",cartDetails)
    // getData({ amount: cartDetails.total, paymentFor: "premium", }).then((response) => {
    getData({ amount: 500, paymentFor: "premium", }).then((response) => {


      var information = {
        // action: "https://securegw-stage.paytm.in/order/process",   //statging url
         action: "https://securegw.paytm.in/order/process",     //live url
        //  action: "https://securegw.paytm.in/order/process",     //paytm response use this 
        params: response?.data,
      };
console.log(information)
      post(information);

    });
  };


  const placeOrder = () => {
    // makePayment()
    const requestObj = {
      type: "cod",
      voucherCode: "",
      address: userAddress,
      billing_address:userAddress,
      isSelfTransport:selfTransport,
    };
    dispatch(placeOrderAction(requestObj)).then((resolve) =>
      Alert("Order Placed Successfully", "success", "/")
    );
  };

  useEffect(() => {
    dispatch(
      getCartPriceAction({
        type: "cod",
        voucherCode: "",
      })
    );
    dispatch(fetchCartAction());
  }, [dispatch]);

  useEffect(() => {
    let addressId = addId;
    dispatch(getAddressById({ addressId }));
  }, [dispatch,addId]);
  // console.log("address",address)
  // console.log("userAddress",userAddress)
  // console.log("selfTransport",selfTransport)

  return (
    <>
      <Header />
      {isLoading ? (
        <Loader />
      ) : (
        <main className="page-wrapper">
          <section className="pt-3">
            <div className="container px-sm-5">
              <div className="row">
                <div className="col-xl-8 col-lg-7 pt-3">
                  <ReviewPayComponent
                    name={address?.firstName + " " + address?.lastName}
                    lastdigit={7879}
                    cardName={"Master Card"}
                    amount={cartOrderSummary?.order?.grandTotal}
                    address={address}
                    setSelfTransport={setSelfTransport}
                    userAddress={userAddress}
                  />
                </div>
                <div className="col-xl-4 col-lg-5 pt-3">
                  <div className="card shadow border-0  cart-box py-4 px-2">
                    <div className="card-body">
                      <h6 className="fw-medium">Order Summary</h6>
                      <div className="box-card small"></div>
                      <OrderSummaryComponent
                        products={cart}
                        cartSummary={cartOrderSummary?.order}
                      />
                      <div className="d-grid">
                        <div
                          onClick={() => placeOrder()}
                          className="btn btn-light-success py-2 rounded-0"
                          type="submit"
                        >
                          Continuewww
                        </div>
                        {/* <Link to="/order-confirm" className="btn btn-light-success py-2 rounded-0" type="submit">Continue</Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
      <div className="modal" id="order-placed-success-modal" tabindex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body px-4 pb-4">
              <div className="close">
                <button
                  type="button"
                  className="btn-close size-50px"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="my-3 pt-3 text-center">
                <div className="d-flex justify-content-center">
                  <div className="img-holder size-80px">
                    <img
                      className="img-fluid w-100"
                      src="images/icons/checked-circle.png"
                      alt=""
                    />
                  </div>
                </div>
                <h4 className="fw-medium text-light-success my-3">
                  Yayy! Order Placed.
                </h4>
                <p>
                  Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                  used in laying out print, graphic or web designs Lorem ipsum,
                  or lipsum as it is sometimes known, is Click Here to explore
                  more products .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ReviewPay;
