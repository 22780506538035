import React, { useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Link } from "react-router-dom";
import AmountFormat from "../amountFormat";

const ItemDetails = ({
  product,
  productName,
  price,
  addToCart,
  addToQuotation,cart
}) => {
  const [value, setValue] = useState(1);
  // const [value1, setValue1] = useState(1);
  const variants = product.variants;
  const [productPrice, setProductPrice] = useState(price);
  const [ppp, setPpp] = useState(price);
  const [productVariant, setProductVariant] = useState("");
  const [addQt, setAddQt] = useState(0);
  const [morePrice, setMorePrice] = useState(0);
  const [qty, setQty] = useState(1);

  

  console.log("qtyqty morePrice==>", qty  );

  // console.log("productVariant===>", productVariant)

  const increment = () => {
    // const updatedValue = value + 1;
    // setValue(updatedValue);
    setQty(qty + 1);
    setPricePerPeice(qty + 1);
  };

  const decrement = () => {
    // const updatedValue = value - 1;
    // if (value > 1) {
    //   setValue(updatedValue);
    // }
    console.log("0====", qty);
    if (qty > 1) {
      setQty(qty - 1);
      setPricePerPeice(qty - 1);
    }
  };

  useEffect(() => {
    // setPricePerPeice(value);
    setPricePerPeice(qty);
    setProductPrice(ppp * qty);
    // setProductPrice(ppp * value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, qty, ppp]);

  // let productVariant = ""

  useEffect(() => {
    variants.map((variant, index, self) => {
      // console.log("kkkkkkkk===>", kk, index+1)

      // console.log("Current Varian=>", variant, index);
      let rangeArray =
        variant?.variant1 &&
        variant.variant1?.name?.split("-").map((ele) => parseInt(ele));

      let kk = self[index + 1];
      if (kk) {
        // console.log("vkk==>", kk);

        let priceRangeArray =
          kk.variant1 &&
          kk.variant1.name.split("-").map((ele) => parseInt(ele));

        // console.log("priceRangeArray==>", priceRangeArray);
        let addQ = priceRangeArray[0] - value;

        // setAddQt(addQ);
        setQty(priceRangeArray[priceRangeArray.length - 1]);
        // setPricePerPeice(priceRangeArray[priceRangeArray.length-1])

        setMorePrice(((kk.mrp - kk.price) * 100) / kk.mrp);
      }

      if (rangeArray && rangeArray.length) {
        let smallerLimit = rangeArray[0];
        let largerLimit = rangeArray[1];

        if (value >= smallerLimit && value <= largerLimit) {
          setPpp(variant.price);
          // productVariant = variant
          setProductVariant(variant);
        } else {
          if (variants.length - 1 === index && value >= largerLimit) {
            // productVariant = variant
            setProductVariant(variant);
          }
        }
      } else {
        // productVariant = variant

        setProductVariant(variant);
      }
      return variant;
    });
  }, [variants]);

  const setPricePerPeice = () => {
    variants.map((variant, index, self) => {
      // console.log("Current Varian=>", variant, index);
      let rangeArray =
        variant?.variant1 &&
        variant.variant1?.name?.split("-").map((ele) => parseInt(ele));

      let kk = self[index];
      if (kk) {
        let priceRangeArray =
          kk.variant1 &&
          kk.variant1.name.split("-").map((ele) => parseInt(ele));
        let addQ = priceRangeArray?.[0] - qty;
        setAddQt(addQ);
        // setQty(priceRangeArray[priceRangeArray.length-1])

        setMorePrice(((kk.mrp - kk.price) * 100) / kk.mrp);
      }

      if (rangeArray && rangeArray.length) {
        let smallerLimit = rangeArray[0];
        let largerLimit = rangeArray[1];

        // console.log(smallerLimit, largerLimit, qty);

        if (qty >= smallerLimit && qty <= largerLimit) {
          setPpp(variant.price);
          // productVariant = variant
          setProductVariant(variant);
        } else {
          // if (variants.length - 1 === index && qty >= largerLimit) {
          //   // productVariant = variant
          //   setProductVariant(variant);
          // }
          if (qty < smallerLimit && index === 0) {
            setPpp(price);
          }
        }
      } else {
        setProductVariant(variant);
      }
      return variant;
    });
  };

  const onSliderChange = (value) => {
    setValue(value);
    setQty(value);
    //dispatch action fetch price per piece on quantity based and setPpp() from that data
    // setPricePerPeice()   //This will set the new price per peice
  };

  useEffect(() => {
    setValue(1);
  }, []);

  function getDiscount() {
    let dis =
      product?.variants?.length > 0 &&
      ((product?.variants?.[0].mrp - product?.variants?.[0].price) * 100) /
        product?.variants?.[0].mrp;
    return !isNaN(dis) ? dis : 0;
  }

  // console.log("----------Product-----",product )
  return (
    <div className="product-view-details">
      <div className="d-inline-block">
        <h5 className="product-name text-capitalize fw-medium fs-3" id="product-name">
          {/* Product :-  */}
          {productName}
        </h5>
        <h5 className="product-name text-capitalize" id="product-name">
          
        <span
            className=""
            style={{
              width: "30px",
              height: "30px",
              paddingRight:'8px'
              // border:"1px solid #dee2e6"
            }}
          >
            <img
              className="img-fluid"
              width="30px"
              height="30px"
              alt=".."
              src={product.brand?.id?.brandImage}
              // style={{width:"90px"}}
            />
          </span>
          {product?.brand.id?.name}{" "}
          
        </h5>
        <h5 className="product-name text-capitalize" id="product-name">
          Ref No :- {product?.productCode}
        </h5>

        {/* <div className="d-flex">
          <div className="pro-rating-icon small text-light-gray">
            <ul className="d-flex small list-unstyled">
              <li className="pe-1">
                <i className="fa fa-star text-warning"></i>
              </li>
              <li className="pe-1">
                <i className="fa fa-star text-warning"></i>
              </li>
              <li className="pe-1">
                <i className="fa fa-star text-warning"></i>
              </li>
              <li className="pe-1">
                <i className="fa fa-star text-warning"></i>
              </li>
              <li className="pe-1">
                <i className="fa fa-star"></i>
              </li>
            </ul>
          </div>
           <div className="small">
            <span className="text-dark">4.3</span>
            <span className="text-light-gray">from 29 reviews</span>
          </div>  
        </div> */}
         <div className="h5 text-dark  mb-3 fw-bold px-3 text-decoration-line-through">
           Mrp : ₹ {product.MRP}
          
          </div>
          <div className="h5 text-dark  mb-3   px-3  ">
          Sale Price :₹ 
            {ppp}
          </div>
          <div className="h5 text-dark  mb-3  px-3  ">
          Price:₹ {productPrice}
          </div>
          <div className="h5 text-dark  mb-3  px-3  ">
          Discount:-{(((product.MRP)-(ppp))/(product.MRP))*100}% Off
          </div>
        {/* <div className="d-flex align-items-end">
          <div className="h5 text-dark mb-0  ">
         
            {/* <AmountFormat value={product?.variants[0].price} /> 
            {}
            Sale Price :₹ 
            {ppp}
            {/* <AmountFormat
              value={product?.variants[product?.variants?.length - 1].price}
            /> 
{" "}<br/>
            Price:₹ {productPrice}
          </div>
          
          {/* {getDiscount() > 0 && (
            <div className="text-success">
              ({getDiscount().toFixed(2)}% OFF)
            </div>
          )} 

          Discount:-{}
        </div> */}
      </div>
      <div className="my-3">
        <div className="control input-group">
          <button
            className="btn border rounded btn-white"
            id="minus"
            onClick={decrement}
          >
            <span className="fa fa-minus"></span>
          </button>
          <input
            type="number"
            className="form-control border-0 w-50px px-2"
            id="input"
            min={1}
            // value={value}
            value={qty}
            // onChange={(e)=>setQty(e.target.value)}
          />
          <button
            className="btn border rounded btn-white"
            id="plus"
            onClick={increment}
          >
            <span className="fa fa-plus"></span>
          </button>
        </div>
      </div>
      {/* <div className="row my-3">
        <div className="col-auto px-0">
          <div
            className="btn btn-sm text-dark fw-medium mx-1"
            onClick={() => addToQuotation(product, value, productVariant)}
          >
            Add to My Quotation
          </div>
        </div>
      </div> */}
      <div className="row my-3">
        {/* <div className="col-auto">
          <span className="h5 fw-bold">
            
            ₹ {productPrice}
            : {ppp}
          </span>
        </div> */}
        {/* <div className="col-auto">
          {addQt && addQt > 0 ? (
            <span className="text-danger pt-2">
              (Hurry! add {addQt} more products to avail {morePrice.toFixed(2)}{" "}
              % discount)
            </span>
          ) : (
            ""
          )}
        </div> */}
      </div>
      <div className="row my-3">
        <div className="col-12 d-flex">
          <div className="px-1">Quantity:</div>
          <div className="d-flex quantity-range-slider mx-3">
            <Slider
              min={1}
              max={500}
              value={qty}
              onChange={onSliderChange}
              railStyle={{
                height: 2,
              }}
              handleStyle={{
                height: 18,
                width: 18,
                marginLeft: -9,
                marginTop: -9,
                backgroundColor: "#8DD883",
                border: 0,
                cursor: "pointer",
              }}
              trackStyle={{
                background: "none",
              }}
            />

            <div className="mx-3 quantity-text">
              <span id="ex6CurrentSliderValLabel">
                <span id="ex6SliderVal">{qty}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <button
          className="col-xl-5 col-lg-6 col-md-6 col-max-xs-6 col-max-xxs-12 order-md-1 d-grid my-3 btn px-xl-5 btn-light-success"
          onClick={() => addToCart(product, qty, productVariant)}
          disabled={cart===true}
        >
          {cart===true?"Already in Cart":"Add to Cart"}
        </button>
        <div className="col-xl-5 col-lg-6 col-md-6 col-max-xs-6 col-max-xxs-12 order-md-3 d-grid my-3">
          <Link to="/cart" className="btn px-xl-5 btn-light-success">
            Buy Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ItemDetails;
