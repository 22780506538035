import React from 'react'
import { useHistory } from 'react-router-dom'

const SaveAddressComponent = ({ name, address, city, state, district, contact, addressType, addressId, removeAddress, pincode }) => {
    const history = useHistory()
    const editAdress = () => {
        history.push({
            pathname: '/edit-address',
            search: `?address=${addressId}`

        })
    }
    return (
        <div className="card card-shipping border-0 shadow mb-3">
            <div className="card-body p-4">

                <div className="top-right pe-3 me-1 mt-2">

                    <i className="fas fa-trash"
                     style={{ marginRight: '1rem', color: 'tomato', cursor: 'pointer' }}
                      onClick={() => removeAddress(addressId)}>

                    </i>

                    <span className="badge bg-light-success">{addressType}</span>
                </div>
                <div>
                    <div className="mb-1 fw-medium text-uppercase">{name}</div>
                    <div className="mb-1 text-light-gray w-50 text-capitalize">{address} {" "}{city}</div>
                    <div className="mb-1 text-light-gray w-50 text-capitalize">{state} {" - "}{pincode}</div>
                    <div className="mb-1 text-dark fw-medium">{contact}</div>
                </div>
                <div className="bottom-right pe-3 me-1 my-2">
                    <button className="btn btn-sm  " onClick={editAdress}><i className="fas fa-edit"></i></button>
                </div>
            </div>
        </div>
    )
}

export default SaveAddressComponent
