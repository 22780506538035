import React from "react";

const ReviewPayAcountDetails = ({ userAddress }) => {
  const {
    firstName,
    lastName,
    area,
    city,
    state,
    country,
    pincode,
  } = userAddress;
  return (
    <div class="card card-review-pay border-0 shadow mb-3">
      <div class="card-header bg-white d-flex align-items-center justify-content-between">
        <div class="fw-medium">Account Details</div>
        {/* <div class="">
          <button class="btn btn-sm text-light-gray text-uppercase text-underline">
            Edit Details
          </button>
        </div> */}
      </div>
      <div class="card-body">
        <div class="mb-1 fw-medium text-uppercase">{`${firstName} ${lastName}`}</div>
        <div class="mb-1 text-uppercase">{`${area} ${city} ${state} ${country} - ${pincode}`}</div>
      </div>
    </div>
  );
};

export default ReviewPayAcountDetails;
