import React from "react";

const SelfTransport = ({isChecked}) => {
    
  return (
    <>
      <div className="card cart-box border-0 position-relative my-4">
        <div className="top-right">
          <div className="btn-group-toggle">
            <div className="btn form-check common-checkbox">
              <input
                className="form-check-input common-checkbox-input"
                type="checkbox"
                // value={isChecked}
                id="flexCheckChecked"
                checked={isChecked}
                hidden=""
                data-bs-toggle="modal"
                data-bs-target="#self-transport-modal"
              />
              <label
                className="form-check-label common-checkbox-label ps-2"
                htmlFor="flexCheckChecked"
              ></label>
            </div>
          </div>
        </div>
        <h6 className="fw-medium">Opt For Self-Transport</h6>
        <p className="text-light-gray">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt{" "}
        </p>
      </div>
    </>
  );
};

export default SelfTransport;
