import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { slickConfig } from "../../utils/settings";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function ShopByBrand() {
  const state = useSelector((state) => state.brands);
  
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    // prevArrow: <SamplePrevArrow/>,

    // nextArrow: <SampleNextArrow/>,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
         
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
      
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
    
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <>
      <div className="container four-products-btn">
        <div className="row section-heading text-center">
          <div className="col-12 main-heading">
            <h3 className="fw-bold">Shop By Brands</h3>
          </div>
        </div>

        {/* <div className="four-products-carousel"> */}
        <div className="slider-custom-space">
          {/* <Slider {...slickConfig(state.brands?.length > 2)}> */}
          <Slider {...settings}>
            {state.brands?.length > 0 &&
              state.brands.map((brand, index) => (
                <div key={index} className="  mt-2 p-3 rounded-3 shadow-sm">
                  <Link
                    to={`/brand?brand=${brand.brandId}&brandName=${brand.name}`}
                  >
                    <img
                      src={brand.brandImage}
                      alt={brand.name}
                      style={{ height: 100 }}
                      className="mx-auto"
                    />
                  </Link>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default ShopByBrand;
