import React from 'react'

const InfoBar = () => {
    return (
        <div className="container">
        <div className="row col-lg-11 mx-auto justify-content-center text-sm-left text-center">
            <div className="col-md-4 d-flex flex-sm-row flex-column align-items-center my-3">
                <div className="size-50px me-sm-3">
                    <img src="images/icons/group.png" alt="" className="img-fluid w-100"/>
                </div>
                <div className="text-warning">
                    <h5 className="fw-bold">200+</h5>
                    <h6 className="">Certified vendors </h6>
                </div>
            </div>
            <div className="col-md-4 d-flex flex-sm-row flex-column align-items-center my-3">
                <div className="size-50px me-sm-3">
                    <img src="images/icons/group.png" alt="" className="img-fluid w-100"/>
                </div>
                <div className="text-warning">
                    <h5 className="fw-bold">50,000+</h5>
                    <h6 className="">Products</h6>
                </div>
            </div>
            <div className="col-md-4 d-flex flex-sm-row flex-column align-items-center my-3">
                <div className="size-50px me-sm-3">
                    <img src="images/icons/group.png" alt="" className="img-fluid w-100"/>
                </div>
                <div className="text-warning">
                    <h5 className="fw-bold">50+</h5>
                    <h6 className="">Brands </h6>
                </div>
            </div>
        </div>
    </div>
    )
}

export default InfoBar
