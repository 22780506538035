import {
    GET_USER_DETAILS_REQUEST,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILURE,
    UPDATE_PROFILE_IMAGE_REQUEST,
    UPDATE_PROFILE_IMAGE_SUCCESS,
    UPDATE_PROFILE_IMAGE_FAILURE,
    UPDATE_PROFILE_INFO_REQUEST,
    UPDATE_PROFILE_INFO_SUCCESS,
    UPDATE_PROFILE_INFO_FAILURE,
    UPDATE_USER_EMAIL_REQUEST,
    UPDATE_USER_EMAIL_SUCCESS,
    UPDATE_USER_EMAIL_FAILURE,
} from '../../type'

import { FET_PROFILE_DATA_URL, UPDATE_PROFILE_IMAGE_URL, UPDATE_PROFILE, UPDATE_USER_EMAIL_URL } from '../../../api/urls'
import axiosInstance from '../../../api/axiosInstance';


export const getProfileData = (userId) => {
    return (dispatch) => new Promise((resolve, reject) => {

        dispatch(getProfileRequest());
        try {
            axiosInstance
                .post(FET_PROFILE_DATA_URL, userId)
                .then((response) => {
                    let res = JSON.parse(response.data);

                    if (response.status === 200) {

                        if (res.success === true) {
                            // console.log(res.result.fullName)
                            dispatch(getProfileSuccess(res.result));
                            resolve(res.result)

                        } else {
                            dispatch(getProfileFailure(res.message))
                            reject()
                        }

                    } if (res.code === 500) {
                        dispatch(getProfileFailure(res.message))
                        reject()
                    }
                })
                .catch((error) => {

                    dispatch(getProfileFailure(error.message));
                });
        } catch (e) { }


    }

    )

};



export const updateProfileImage = (addressId) => {

    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(updateProfileImageRequest())
            try {
                axiosInstance
                    .post(UPDATE_PROFILE_IMAGE_URL, addressId)
                    .then((response) => {
                        let res = JSON.parse(response.data);

                        if (response.status === 200) {
                            dispatch(updateProfileImageSuccess(res));
                            resolve();
                        } else {
                            dispatch(updateProfileImageFailure(res.message));
                            reject()
                        }
                    })
                    .catch((error) => {

                        dispatch(updateProfileImageFailure(error.message));
                    });
            } catch (e) { }

            // alert(error)
        });
};


export const updateProfileInfo = (profileData) => {

    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(updateProfileInfoRequest())
            try {
                axiosInstance
                    .post(UPDATE_PROFILE, profileData)
                    .then((response) => {
                        let res = JSON.parse(response.data);

                        if (response.status === 200) {
                            dispatch(updateUserEmailSuccess(res));
                            resolve();
                        } else {
                            dispatch(updateProfileInfoFailure(res.message));
                            reject()
                        }
                    })
                    .catch((error) => {

                        dispatch(updateProfileInfoFailure(error.message));
                    });
            } catch (e) { }


        });
};

export const updateUserEmail = (profileData) => {

    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(updateUserEmailRequest())
            try {
                axiosInstance
                    .post(UPDATE_USER_EMAIL_URL, profileData)
                    .then((response) => {
                        let res = JSON.parse(response.data);

                        if (response.status === 200) {
                            dispatch(updateProfileInfoSuccess(res));
                            resolve();
                        } else {
                            dispatch(updateUserEmailFailure(res.message));
                            reject()
                        }
                    })
                    .catch((error) => {

                        dispatch(updateUserEmailFailure(error.message));
                    });
            } catch (e) { }


        });
};





const getProfileRequest = () => {
    return {
        type: GET_USER_DETAILS_REQUEST
    }
}

const getProfileSuccess = (user) => {
    return {
        type: GET_USER_DETAILS_SUCCESS,
        payload: user
    }
}

const getProfileFailure = (error) => {
    return {
        type: GET_USER_DETAILS_FAILURE,
        payload: error
    }
}


const updateProfileImageRequest = () => {
    return {
        type: UPDATE_PROFILE_IMAGE_REQUEST
    }
}

const updateProfileImageSuccess = (success) => {
    return {
        type: UPDATE_PROFILE_IMAGE_SUCCESS,
        payload: success
    }
}

const updateProfileImageFailure = (error) => {
    return {
        type: UPDATE_PROFILE_IMAGE_FAILURE,
        payload: error
    }
}

const updateProfileInfoRequest = () => {
    return {
        type: UPDATE_PROFILE_INFO_REQUEST
    }
}

const updateProfileInfoSuccess = (success) => {
    return {
        type: UPDATE_PROFILE_INFO_SUCCESS,
        payload: success
    }
}

const updateProfileInfoFailure = (error) => {
    return {
        type: UPDATE_PROFILE_INFO_FAILURE,
        payload: error
    }
}

const updateUserEmailRequest = () => {
    return {
        type: UPDATE_USER_EMAIL_REQUEST
    }
}

const updateUserEmailSuccess = (success) => {
    return {
        type: UPDATE_USER_EMAIL_SUCCESS,
        payload: success
    }
}

const updateUserEmailFailure = (error) => {
    return {
        type: UPDATE_USER_EMAIL_FAILURE,
        payload: error
    }
}


