import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { signup } from "../../store/actions/signupAction";
import { login } from "../../store/actions/loginAction";
import { signupValidation, loginValidation } from "../../utils/validation";
import "bootstrap/js/dist/modal";
import md5 from "md5";
import { fetchCategoryList } from "../../store/actions/category/categoryAction";
import { getBrandList } from "../../store/actions/brandAction";
import Swal from "sweetalert2";
import Loader from "../loader";
import Randomstring from "randomstring";
import { isAuth } from "../../utils/auth";
import { fetchCartProductId } from "../../store/actions/cartAction";
import { getProfileData } from "../../store/actions/user/userInfoAction";
// import ReactFacebookLogin from "react-facebook-login";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";


import { fetchSubSubCatList } from "../../store/actions/subSubCategoryAction/subSubCategoryAction";
import { fetchSubCatagoryList } from "../../store/actions/subCategory/subCategoryAction";
import axiosInstance from "../../api/axiosInstance";
import { Button, Form, Modal } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
 
import GoogleLogin from "react-google-login";


import { BASE_URL } from "../../api/urls";
import { gapi } from "gapi-script";

function Header() {
  const [fullscreen, setFullscreen] = useState(true);
  const [showSearch, setShowSearch] = useState(false);

 
 //facebook client id
 const appId = "1105622003472992";

 // google client id

  const clientId = "575917640543-8ihsmd6rklb1vtfeuor3e14h1cs25uvh.apps.googleusercontent.com"

 

  const dispatch = useDispatch();
  const myRefname2 = useRef(null);
  // const myRefname1 = useRef(null)console
  const authed = isAuth();

  const brands = useSelector((state) => state.brands.brands);
  const categories = useSelector((state) => state.category.data);
  const subCategories = useSelector((state) => state?.subCategory?.data);
  const subsubCategories = useSelector((state) => state.subSubCategory?.data);

  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  // console.log("subCategoryList=======",subCategoryList)
  const [sub2CateList, setSub2CateList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const catLoading = useSelector((state) => state.category.isLoading);
  const brandLoading = useSelector((state) => state.brands.isLoading);
  const loginLoading = useSelector((state) => state.user.isLoading);
  const signupLoading = useSelector((state) => state.signupData.isLoading);
  const loading = catLoading || brandLoading || loginLoading || signupLoading;
  const signupError = useSelector((state) => state.signupData.error);

  const [authErrorLogin, setAuthErrorLogin] = useState([]);

  const cartData = useSelector((state) => state.cart);

  const successAdditionOfProductAlert = (title, icon) => {
    Swal.fire({
      title: title,
      text: "",
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Continue",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        handleClick2();
        // history.push("/");
      } else {
        // history.push("/product-mgmt");
        handleClick2();
      }
    });
  };

  // const responseFacebook = (response) => {
  //   console.log(response);
  // };
// google
  const onSuccess = async(response) => {
    console.log("ssssssssssss",response)
    if (response?.xc?.id_token) {
      // setShowOverLay(true)
      const reqObj = {
        idToken: response?.xc?.id_token,
        userEmail: response?.wt?.cu,
      };
      // console.log(reqObj);
      try {
        const response = await axiosInstance.post(
          "/social/login-with-google",
          reqObj
        );
        console.log(response);
        const res = JSON.parse(response.data);
        console.log(res);
        if (res.success) {
          // alert("logged In")
          // setShowOverLay(false)
          localStorage.setItem("accessToken", res.token);
          console.log("agter========",  localStorage.setItem("accessToken", res.token))
          localStorage.setItem("userData", JSON.stringify(res.data));
          
          // toast.success("Logged In Successfully..!!", {
          //   position: "top-center",
          //   autoClose: 2000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
          setTimeout(() => {
            // window.location.href="/link-accounts"
            // navigate("/link-accounts");
            // window.location.reload();
          }, 2000);
        }else{
          // setShowOverLay(false)
        }
      } catch (e) {
        console.log(e.message);
        alert(e.message)
      }
    }
};
const onFailure = (err) => {
    console.log('failed:', err);
};

  useEffect(() => {
    const initClient = () => {
          gapi.client.init({
          clientId: clientId,
          scope: ''
        });
     };
     gapi.load('client:auth2', initClient);
 });
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      { pageLanguage: "en" },
      "google_translate_element"
    );
  };

  //Facebook Login
  const responseFacebook = async (facebook_response) => {
    console.log("facebook_response", facebook_response);
    if (facebook_response) {
      // setShowOverLay(true);
      const reqObj = {
        accessToken: facebook_response?.accessToken,
        userFbID: facebook_response?.userID,
        // userEmail: facebook_response?.email,
      };
      
      console.log(reqObj);
      try {
        const response = await axiosInstance.post(
          "/social/login-with-facebook",
          reqObj
        );
        const res = JSON.parse(response.data);
        console.log(res);
        if (res.success) {
          // alert("logged In")
          // setShowOverLay(false)
          // localStorage.setItem("accessToken", res.data.token);
          // toast.success("Logged In Successfully..!!", {
          //   position: "top-center",
          //   autoClose: 2000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
          // setTimeout(() => {
          //   navigate("/link-accounts");
          // }, 2000);
        }else{
          // setShowOverLay(false)
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  //Google login

  const responseGoogle = async(response)=>{
    console.log("ssssssssssss",response)
    if (response?.xc?.id_token) {
      // setShowOverLay(true)
      const reqObj = {
        idToken: response?.xc?.id_token,
        userEmail: response?.wt?.cu,
      };
      // console.log(reqObj);
      try {
        const response = await axiosInstance.post(
          "/social/login-with-google",
          reqObj
        );
        console.log(response);
        const res = JSON.parse(response.data);
        console.log(res);
        if (res.success) {
          // alert("logged In")
          // setShowOverLay(false)
          // localStorage.setItem("accessToken", res.data.token);
          // toast.success("Logged In Successfully..!!", {
          //   position: "top-center",
          //   autoClose: 2000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
          setTimeout(() => {
            window.location.href="/link-accounts"
            // navigate("/link-accounts");
            // window.location.reload();
          }, 2000);
        }else{
          // setShowOverLay(false)
        }
      } catch (e) {
        console.log(e.message);
        alert(e.message)
      }
    }
  }
  const responseGoogleFailure = (response)=>{
    console.log("fffffff",response)
  }


  useEffect(() => {
    setIsLoading(loading);
    setCategoryList(categories);
    setSubCategoryList(subCategories);
    setSub2CateList(subsubCategories);

    setBrandList(brands);
  }, [loading, categories, brands, subCategories, subsubCategories]);

  useEffect(() => {
    dispatch(fetchCategoryList());
    dispatch(getBrandList());
    dispatch(fetchSubSubCatList());
    dispatch(fetchSubCatagoryList());
  }, [dispatch]);

  const handleClick2 = () => {
    // myRefname.current.focus();
    myRefname2.current.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;

    document
      .querySelector("#navbarSideCollapse")
      .addEventListener("click", function () {
        document.querySelector(".offcanvas-collapse").classList.toggle("open");
        this.classList.toggle("open");
      });

    // setIsloading(false);
  }, []);

  const [userData, setUserData] = useState("");

  const userDetails = useSelector((state) => state.userData);

  useEffect(() => {
    setUserData(userDetails);
  }, [userData, userDetails, dispatch, authed]);

  const handleSignup = (data) => {
    console.log(data);
    const userEmail = data.email;
    const fullName = data.fullName;
    const mobile = data.mobile;
    const userType = data.userType;
    const password = md5(data.password);
    // const password = (data.password);
    const countryCode = data.countryCode;
    // const userNameArray = data.email.split("@");
    const userName = Randomstring.generate({
      length: 6,
      charset: "alphabetic",
    });

    const requestObj = {
      userEmail: userEmail,
      fullName: fullName,
      mobile: mobile,
      // userType: userType,
      userType: "individual",
      password: password,
      countryCode: countryCode,
      username: userName,
      role: "customer",
    };

    console.log("reqObj====", requestObj);
    // if (userType === "business") {
    //   requestObj.gst = String(data.gst);
    // }
    // if (userType === "individual") {
    //   requestObj.aadhar = String(data.aadhar);
    // }

    dispatch(signup(requestObj)).then(
      (resolve) => {
        successAdditionOfProductAlert(
          "Successfully Registered, Please continue your shopping",
          "success"
        );

        // handleClick2()
      },
      (reject) => {}
    );
  };

  const handleLogin = (data) => {
    const requestObj = {
      loginId: data.loginEmail,
      password: md5(data.loginPassword),
      // password: data.loginPassword,
    };

    dispatch(login(requestObj)).then(
      (resolve) => {
        handleClick2();
      },
      (reject) => {
        setAuthErrorLogin(reject);
        setTimeout(() => {
          setAuthErrorLogin([]);
        }, 5000);
      }
    );
  };

  const initialValues = {
    fullName: "",
    email: "",
    countryCode: "+91",
    mobile: "",
    userType: "",
    password: "",
    // validId: "",
    aadhar: "",
    gst: "",
  };
  const [userName, setUserName] = useState("");
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    // const userData = JSON.parse(localStorage.getItem(""));
    console.log("userdtaa======",userData)
    console.log("userdtaa==authed====",authed)
    if (authed) {
      dispatch(fetchCartProductId());
      dispatch(getProfileData(userData?.userId)).then((resolve) => {
        // console.log(resolve.fullName)
        setUserName(resolve.fullName);
      });
    }
    
    onSuccess()
  }, [dispatch, authed]);
  useEffect(()=>{
    onSuccess()
    const userData = JSON.parse(localStorage.getItem("userData"));
    console.log("userData social login ",userData);
  },[userData])

  const GoToProfile = () => {
    const history = useHistory();

    return (
      <div className="btn mx-1" onClick={() => history.push("/edit-profile")}>
        <span className="mx-1">{userName}</span>
        <span className="sprite-icon size-20px">
          <img
            className="img-fluid w-100"
            src="images/icons/icon-user.png"
            alt="mail Icon"
          />
        </span>
      </div>
    );
  };

  const OpenDialog = () => {
    return (
      <a
        href="#login-signup-modal"
        className="btn mx-1"
        data-bs-toggle="modal"
        data-target="#login-signup-modal"
      >
        <span className="sprite-icon size-20px">
          <img
            className="img-fluid w-100"
            src="images/icons/icon-user.png"
            alt="mail Icon"
          />
        </span>
      </a>
    );
  };
  const [email, setEmail] = useState([]);
  const [message, setMessage] = useState([]);
  const [modelClose, setModelClose] = useState(false);
  // console.log("email------", modelClose);
  const Handleforgetsubmit = async () => {
    let reqObj = {
      email: email,
    };
    const result = await axiosInstance.post(`forgotpassword/sendLink`, reqObj);

    const res = JSON.parse(result.data);
    console.log("result", res.success);
    if (res.success) {
      setMessage(res.message);
      setTimeout(() => {
        handleClose();
      }, 3000);
      window.location.reload();
    } else {
      setMessage(res.message);
    }
    // setModelClose(res.success);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      Handleforgetsubmit();
    }
  };
  // search
  // const [search,setSearch]=useState([])
  // console.log("search-------",search)
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  console.log("search inpit", searchInput);
  console.log("search searchResults", searchResults);

  // search work

  // const params = {
  //   count: 0,
  //   limit: 12,
  //   field: "max",
  //   filters: {
  //     catalogueName: searchInput.toLowerCase(),
  //   },
  // };

  useEffect(() => {
    if (searchInput.length < 2) return;
    const timer = setTimeout(() => {
      handleSearch(setSearchResults);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchInput]);

  const handleSearch = async () => {
    let reqObj = {
      search: searchInput,
    };
    try {
      await axiosInstance
        .post(`/commonroutes/search`, reqObj, setSearchResults)
        .then((res) => {
          const response = JSON.parse(res.data);
          console.log("response", response);
          setSearchResults(response.data?.products);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const history = useHistory();
  function mySearch() {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("mysearchinput");
    filter = searchInput.toUpperCase();
    ul = document.getElementById("seachmycontent");
    li = ul.getElementsByClassName("list-group-item");

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].classList.remove("d-none");
      } else {
        li[i].classList.add("d-none");
      }
    }
  }
  var input, ul, li, i;
  if (input && li && ul) {
    input = document.getElementById("mysearchinput");
    ul = document.getElementById("seachmycontent");
    li = ul.getElementsByClassName("list-group-item");

    li.addEventListener("click", function () {
      input.value = "";
      for (i = 0; i < li.length; i++) {
        li[i].classList.add("d-none");
      }
    });
  }
  useEffect(() => {
    let dropdown = document.getElementsByClassName("dropdown");

    for (let m = 0; m < dropdown.length; m++) {
      dropdown[m].addEventListener("mouseenter", function () {
        dropdown[m]
          .getElementsByClassName("dropdown-menu")[0]
          .classList.add("show");
      });
    }

    for (let m = 0; m < dropdown.length; m++) {
      dropdown[m].addEventListener("mouseleave", function () {
        dropdown[m]
          .getElementsByClassName("dropdown-menu")[0]
          .classList.remove("show");
      });
    }

    for (let i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("mouseenter", function () {
        var getfullwidth =
          dropdown[i].offsetLeft +
          dropdown[i].getElementsByClassName("dropdown-menu")[0].offsetWidth;
        if (getfullwidth < window.innerWidth) {
          dropdown[i].getElementsByClassName("dropdown-menu")[0].style.left =
            dropdown[i].offsetLeft + "px";
        } else if (getfullwidth > window.innerWidth) {
          var gap = getfullwidth - window.innerWidth;
          dropdown[i].getElementsByClassName("dropdown-menu")[0].style.left =
            Number(dropdown[i].offsetLeft - gap - 100) + "px";
        }
      });
    }

    let dropdownitem = document.getElementsByClassName("dropdown-item");

    for (let i = 0; i < dropdownitem.length; i++) {
      let dropdownmenu = document.getElementsByClassName("dropdown-menu");

      dropdownitem[i].addEventListener("click", function () {
        for (let d = 0; d < dropdownmenu.length; d++) {
          dropdownmenu[d].classList.remove("show");
        }
      });
    }
  }, []);

  const onPressEnterHandler = (e) => {
    if (searchResults.length === 0) return;
    if (e.key === "Enter") {
      const crossButton = document.getElementById("close-search-button");
      if (crossButton) {
        crossButton.click();
      }
      history.replace(`/search-results/${searchInput}`);
    }
  };

  return (
    <>
      <div className="site-header">
        <div className="top-navber">
          <div className="container-fluid px-xxl-5 px-xl-4">
            <div className="row align-items-center">
              <div className="col social ps-sm-0">
                <a className="btn" href="mailto:Info@ecommercewebsite.com">
                  <span className="sprite-icon size-20px">
                    <img
                      className="img-fluid w-100"
                      src="images/icons/icon-envelop-white.png"
                      alt="mail Icon"
                    />
                  </span>
                  <span className="d-lg-inline-block d-none">
                    Info@ecommercewebsite.com
                  </span>
                </a>
                {/* <a className="btn" href="tel:919876543210">
                  <span className="sprite-icon size-20px">
                    <img
                      className="img-fluid w-100"
                      src="images/icons/icon-phone-white.png"
                      alt="mail Icon"
                    />
                  </span>
                  <span className="d-lg-inline-block d-none">
                    +91 9876543210
                  </span>
                </a> */}
              </div>
              <div className="col-auto d-flex align-items-center ml-auto">
                {/* <Link
                                to="/reset-password"

                  className="btn btn-secondary"
                  // href="#login-signup-modal"
                  // data-bs-toggle="modal"
                  style={{ backgroundColor: "white", color: "black" }}
                >
                     reset password 
              
                </Link> */}
                <span
                  className="btn btn-secondary"
                  href="#login-signup-modal"
                  data-bs-toggle="modal"
                  style={{ backgroundColor: "white", color: "black" }}
                >
                  Become a Vendor
                </span>
                <span
                  className="btn btn-secondary"
                  href="#login-signup-modal"
                  data-bs-toggle="modal"
                  style={{ backgroundColor: "white", color: "black" }}
                >
                  Make you Quotation (%)
                </span>
                <div className="d-inline-block p-2">
                  <div id="google_translate_element"></div>

                  {/* <select
                    className="form-select form-select-sm"
                    aria-label=".form-select-sm example"
                  >
                    <option selected>English</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select> */}
                </div>
                <div className="d-sm-inline-block d-none p-2">
                  <div className="input-group input-group-sm flex-nowrap">
                    <span
                      className="input-group-text bg-white"
                      id="addon-wrapping"
                    >
                      <img src="images/icons/icon-india-flag.png" alt="" />
                    </span>
                    <select
                      className="form-select form-select-sm border-0"
                      aria-label=".form-select-sm example"
                      defaultValue="₹"
                    >
                      <option value="₹">₹ (INR)</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div className="d-sm-inline-block d-none">
                  <a className="btn" href="tel:919876543210">
                    <span className="sprite-icon size-20px">
                      <img
                        className="img-fluid w-100"
                        src="images/icons/icon-phone-white.png"
                        alt="mail Icon"
                      />
                    </span>
                    <span className="d-lg-inline-block d-none">
                      +91 9876543210
                    </span>
                  </a>
                  {/* <a className="btn" href="tel:919876543210"> */}
                  {/* <span className="sprite-icon size-20px">
                      <img
                        className="img-fluid w-100"
                        src="images/icons/icon-enquiry-white.png"
                        alt="mail Icon"
                      />
                    </span> */}
                  {/* <span>Enquiry</span> */}
                  {/* </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" id="login-signup-modal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body px-4 pb-4">
                <div className="close">
                  <button
                    type="button"
                    className="btn-close size-50px"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={myRefname2}
                  ></button>
                </div>
                <div className="my-3 pt-3 text-center">
                  <h6 className="fw-bold">Welcome to our website</h6>
                </div>
                <ul className="nav justify-content-center mb-3" id="pills-tab">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link btn btn-link active"
                      id="pills-signin-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-signin"
                      type="button"
                      role="tab"
                      aria-controls="pills-signin"
                      aria-selected="true"
                    >
                      Sign in
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link btn btn-link w-100"
                      id="pills-signup-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-signup"
                      type="button"
                      role="tab"
                      aria-controls="pills-signup"
                      aria-selected="false"
                    >
                      Sign up
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane show active"
                    id="pills-signin"
                    role="tabpanel"
                    aria-labelledby="pills-signin-tab"
                  >
                    <Formik
                      initialValues={initialValues}
                      onSubmit={(values) => {
                        handleLogin(values);
                      }}
                      validationSchema={loginValidation}
                    >
                      {(formik) => {
                        // console.log("formik", formik.errors);
                        return (
                          <form
                            onSubmit={formik.handleSubmit}
                            className="w-100"
                          >
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Email ID or Phone no.{" "}
                                <span style={{ color: "tomato" }}>*</span>
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder=""
                                name="loginEmail"
                                onChange={formik.handleChange}
                              />
                              {formik.errors.loginEmail ? (
                                <div
                                  style={{
                                    color: "tomato",
                                    fontSize: ".6rem",
                                  }}
                                >
                                  {formik.errors.loginEmail}
                                </div>
                              ) : null}

                              {authErrorLogin.length ? (
                                <div
                                  style={{
                                    color: "tomato",
                                    fontSize: ".8rem",
                                  }}
                                >
                                  {" "}
                                  {authErrorLogin}{" "}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-2">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Password{" "}
                                <span style={{ color: "tomato" }}>*</span>
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder=""
                                name="loginPassword"
                                onChange={formik.handleChange}
                              />
                              {formik.errors.loginPassword ? (
                                <div
                                  style={{
                                    color: "tomato",
                                    fontSize: ".6rem",
                                  }}
                                >
                                  {formik.errors.loginPassword}
                                </div>
                              ) : null}
                            </div>
                            <div className="row">
                              <div className="col-12 ps-sm-0 mb-3">
                                {/* <Link to="/forgetPassword"
                                  type="button"
                                  className="btn btn-sm btn-link text-light-success label mx-1"
                                  data-target="#forgot-password"
                                  data-toggle="modal"
                                >
                                  Forgot Password?
                                </Link> */}

                                {/* <button type="button" className="btn btn-sm btn-link text-light-success label mx-1"   data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-dismiss="modal"  >
                                Forgot Password?
</button> */}
                                <button
                                  type="button"
                                  className="btn btn-sm btn-link text-light-success label mx-1"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#exampleModal"
                                  data-bs-dismiss="modal"
                                  onClick={handleShow}
                                >
                                  Forgot Password?
                                </button>
                              </div>
                            </div>
                            <div className="my-2 d-grid">
                              <button
                                type="submit"
                                disabled={isLoading}
                                className="btn text-white btn-light-success px-0 "
                                style={{
                                  minWidth: "100%",
                                }}
                              >
                                {isLoading ? "Login..." : "Login"}
                              </button>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>

                  <div
                    className="tab-pane"
                    id="pills-signup"
                    role="tabpanel"
                    aria-labelledby="pills-signup-tab"
                  >
                    {signupError.length > 0 ? (
                      <div
                        className="text-danger"
                        style={{ backgroundColor: "lightgrey" }}
                      >
                        {signupError}
                      </div>
                    ) : null}

                    <Formik
                      initialValues={initialValues}
                      onSubmit={(values) => {
                        handleSignup(values);
                      }}
                      // validationSchema={signupValidation}
                    >
                      {(formik) => {
                        // console.log(formik);
                        return isLoading ? (
                          <Loader />
                        ) : (
                          <>
                            <form
                              onSubmit={formik.handleSubmit}
                              noValidate
                              name="editVendor"
                              className="w-100"
                            >
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  {" "}
                                  Full Name{" "}
                                  <span style={{ color: "tomato" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Full Name"
                                  name="fullName"
                                  formik={formik}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik.values.fullName}
                                />
                                <ErrorMessage
                                  name="fullName"
                                  component="small"
                                  style={{ color: "tomato", fontSize: ".6rem" }}
                                />
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Mobile Number{" "}
                                  <span style={{ color: "tomato" }}>*</span>
                                </label>
                                <div className="d-flex row">
                                  <div className="col-auto">
                                    <input
                                      type="text"
                                      maxLength={4}
                                      className="form-control w-50px"
                                      id="exampleFormControlInput1"
                                      placeholder="+91"
                                      name="countryCode"
                                      onBlur={formik.handleBlur}
                                      onChange={formik.handleChange}
                                      value={formik.values.countryCode}
                                    />
                                    <ErrorMessage
                                      name="countryCode"
                                      component="small"
                                      style={{
                                        color: "tomato",
                                        fontSize: ".6rem",
                                      }}
                                    />
                                  </div>
                                  <div className="col ps-0">
                                    <input
                                      type="text"
                                      min="10"
                                      maxlength="10"
                                      className="form-control"
                                      id="exampleFormControlInput1"
                                      placeholder="Mobile Number"
                                      name="mobile"
                                      onBlur={formik.handleBlur}
                                      onChange={formik.handleChange}
                                      value={formik.values.mobile}
                                      // onKeyUp={() => setView(true)}
                                    />
                                    <ErrorMessage
                                      name="mobile"
                                      component="small"
                                      style={{
                                        color: "tomato",
                                        fontSize: ".6rem",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* <div>{view ? <VerifyMobile /> : null}</div>
                                <div>{viewOtp ? <VerifyOtp /> : null}</div>
                                <div>{viewVerify ? <Verify /> : null}</div> */}

                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Email Id{" "}
                                  <span style={{ color: "tomato" }}>*</span>
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Email"
                                  name="email"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik.values.email}
                                />
                                <ErrorMessage
                                  name="email"
                                  component="small"
                                  style={{ color: "tomato", fontSize: ".6rem" }}
                                />
                              </div>

                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Create Password{" "}
                                  <span style={{ color: "tomato" }}>*</span>
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Atleast 8 characters"
                                  name="password"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik.values.password}
                                />
                                <ErrorMessage
                                  name="password"
                                  component="small"
                                  style={{ color: "tomato", fontSize: ".6rem" }}
                                />
                              </div>
                              <div className="row mb-3">
                                <div className="col-12">
                                  <span className="text-dark">
                                    We will send you a text to verify your phone{" "}
                                  </span>
                                </div>
                              </div>
                              <div className="my-2 d-grid">
                                <button
                                  type="submit"
                                  className="btn text-white btn-light-success px-0"
                                  // data-bs-toggle="modal"
                                  // data-bs-dismiss="modal"
                                  // data-bs-target="#verify-otp-modal"
                                >
                                  Sign Up
                                </button>
                              </div>
                            </form>
                          </>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="my-3 text-light-gray text-center">
                  <span>Or</span>
                </div>
                {/* <div className="my-2 d-grid">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    data-bs-target="#verify-otp-modal"
                    className="btn text-white btn-light-success px-0"
                  >
                    Continue as Guest
                  </button>
                </div>
                <div className="my-3 text-light-gray text-center">
                  <span>Or</span>
                </div> */}
                {/* <div className="my-2 d-grid">
                  <button
                    type="button"
                    className="btn text-white px-0 bg-facebook"
                    style={{
                      minWidth: "100%",
                    }}
                  >
                    <ReactFacebookLogin
                      appId="530692551876548"
                      autoLoad={true}
                      fields="name,email,picture"
                      // onClick={componentClicked}
                      callback={responseFacebook}
                    />
                    <span className="size-20px sprite-icon">
                      <i className="fab fa-facebook-f"></i>
                    </span>
                    Sign In With Facebook
                  </button>
                </div> */}
{/* facebook Login */}
                <FacebookLogin
                  appId={appId}
                  autoLoad={false}
                  fields="name,email,picture"
                  // onClick={componentClicked}

                  callback={responseFacebook}
                  render={(renderProps) => (
                    <div
                      className="btn shadow-sm px-0 text-center btn-facebook"
                      onClick={renderProps.onClick}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        {/* <div className="icon size-25px me-1">
                          <img
                            src="/assets/images/icon-facebook.png"
                            alt=""
                            className="img-fluid w-100"
                          />
                        </div> */}
                        <span className="size-20px sprite-icon">
                      <i className="fab fa-facebook-f"></i>
                    </span>
                        {/* <div className="col-auto fw-normal small">
                          Sign In with Facebook
                        </div> */}
                      </div>
                    </div>
                  )}
                />

{/* google login */}

{/* <GoogleLogin

                      clientId={clientId}

                      onSuccess={responseGoogle}

                      onFailure={responseGoogleFailure}

                      cookiePolicy={"single_host_origin"}
                      plugin_name= 'hello'

                      // buttonText="Login"

                      render={(renderProps) => (

                        <div className="btn shadow-sm px-0 btn-google">

                        <div

                          className="d-flex justify-content-center align-items-center"

                          onClick={renderProps.onClick}

                          disabled={renderProps.disabled}

                        >

                         
                          <span className="size-20px sprite-icon">
                      <i className="fas fa-envelope"></i>
                    </span>
                        </div>

                        </div>

                      )}

                    /> */}


<GoogleLogin
          clientId={clientId}
          buttonText="Sign in with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
          isSignedIn={true}
      />

                {/* <div className="my-2 d-grid">
                  <button
                    type="button"
                    className="btn text-white px-0 bg-mail"
                    style={{
                      minWidth: "100%",
                    }}
                  >
                    <span className="size-20px sprite-icon">
                      <i className="fas fa-envelope"></i>
                    </span>{" "}
                    Continue as Guest
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* forget password modal */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Forget Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label for="exampleFormControlInput1" className="form-label">
                Email{" "}
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter Email Id"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
            </div>
            <p className="text-success">{message}</p>
            <div className="text-end">
              <button
                type="button"
                className="btn btn-success"
                data-bs-dismiss={modelClose === false && "modal"}
                onClick={Handleforgetsubmit}
              >
                Send
              </button>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary">Understood</Button>
          </Modal.Footer> */}
        </Modal>
      </div>
      <div className="sticky-top site-header bg-white">
        <nav
          className="navbar navbar-expand-lg bg-white navbar-light"
          aria-label="Main navigation"
        >
          <div className="container-fluid px-xxl-5 px-xl-4">
            <Link className="navbar-brand" to="/">
              <img
                className="logo "
                style={{ height: "80px", width: "240px" }}
                src="images/eleven_logo.svg"
                alt=""
              />
            </Link>

            <div
              className="navbar-collapse offcanvas-collapse"
              id="navbar-collapse"
            >
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item dropdown">
                  {/* eslint-disable-next-line */}
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="dropdown04"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Shop by Brand
                  </a>
                  <div
                    className="dropdown-menu shadow pb-3"
                    aria-labelledby="dropdown04"
                    style={{
                      overflow: "scroll",
                      width: "200px",
                      height: "600px",
                    }}
                  >
                    {brandList.map((brandItem, index) => {
                      return (
                        <Link
                          className="dropdown-item"
                          key={index}
                          to={{
                            pathname: "/brand",
                            search: `?brand=${brandItem.brandId}&brandU=${brandItem._id}&brandName=${brandItem.name}`,

                            state: { from: "brand" },
                          }}
                        >
                          {brandItem.name}
                        </Link>
                      );
                    })}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  {/* eslint-disable-next-line */}
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="dropdown04"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Shop by category
                  </a>

                  <div
                    className="dropdown-menu shadow pb-3"
                    aria-labelledby="dropdown04"
                    style={{
                      overflow: "scroll",
                      width: "200px",
                      height: "600px",
                    }}
                  >
                    {categoryList.map((categoryItem, index) => (
                      <Link
                        key={index}
                        className="dropdown-item"
                        to={{
                          pathname: "/product",
                          search: `?category=${categoryItem.categoryid}&catU=${categoryItem._id}&catname=${categoryItem.categoryname}`,

                          state: { from: "category" },
                        }}
                      >
                        {/* {  console.log("categoryItem",categoryItem._id)} */}
                        {categoryItem.categoryname}
                      </Link>
                    ))}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="dropdown04"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Shop by sub Category working
                  </a>
                  <div
                    className="dropdown-menu shadow pb-3"
                    aria-labelledby="dropdown04"
                    style={{
                      overflow: "scroll",
                      width: "200px",
                      height: "600px",
                    }}
                  >
                    {subCategoryList.map((subCategoryItem, index) => (
                      <Link
                        key={index}
                        className="dropdown-item"
                        to={{
                          pathname: "/product",
                          search: `?subCategory=${subCategoryItem.categoryid}&subCatU=${subCategoryItem._id}&subcatname=${subCategoryItem.categoryname}`,

                          state: { from: "SubCategory" },
                        }}
                      >
                        {subCategoryItem.categoryname}
                      </Link>
                    ))}
                  </div>
                </li>
                <li className="nav-item dropdown d-none">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="dropdown04"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Shop by sub2 Category
                  </a>
                  <div
                    className="dropdown-menu shadow pb-3"
                    aria-labelledby="dropdown04"
                    style={{
                      overflow: "scroll",
                      width: "200px",
                      height: "600px",
                    }}
                  >
                    {sub2CateList.map((sub2CategoryItem, index) => (
                      <Link
                        key={index}
                        className="dropdown-item"
                        to={{
                          pathname: "/product",
                          search: `?sub2Category=${sub2CategoryItem.categoryid}&sub2CatU=${sub2CategoryItem._id}&Sub2catname=${sub2CategoryItem.categoryname}`,

                          state: { from: "SubsubCategory" },
                        }}
                      >
                        {sub2CategoryItem.categoryname}
                      </Link>
                    ))}
                  </div>
                </li>
                <li className="nav-item d-none">
                  <Link className="nav-link" to="/my-quotation">
                    Make your own quotation
                  </Link>
                  {/* <Link className="nav-link" to="/product">
                    Make your own quotation
                  </Link> */}
                </li>
              </ul>
            </div>

            <div className="nav-btns  d-flex align-items-center nav-item">
              <div className="nav-link py-0 d-none d-max-xsmall-block d-sm-block">
                {/* eslint-disable-next-line */}
                <a
                  className="btn mx-1"
                  href="#search-modal"
                  data-bs-toggle="modal"
                  // className="nav-link link-dark px-3"
                  // onClick={() => clickHandler()}
                  // onClick={() => setShowSearch(!showSearch)}
                >
                  <span className="sprite-icon size-20px">
                    <img
                      className="img-fluid w-100"
                      src="images/icons/icon-search.png"
                      alt="mail Icon"
                    />
                  </span>
                </a>

                {/* {authed && ( */}
                <Link to="/cart" className="btn mx-1 border-1">
                  <span className="sprite-icon size-20px">
                    <img
                      className="img-fluid w-100"
                      src="images/icons/icon-cart.png"
                      alt="mail Icon"
                    />
                  </span>
                  {cartData?.cartIds?.length > 0 ? (
                    <span
                      className="badge pt-1 rounded-circle"
                      style={{
                        color: "#000000",
                        fontSize: "14px",
                        top: "-4px",
                        position: "absolute",
                        right: "-4px",
                      }}
                    >
                      {cartData?.cartIds?.length}
                    </span>
                  ) : (
                    <span
                      className="badge pt-1 rounded-circle"
                      style={{
                        color: "#000000",
                        fontSize: "12px",
                        top: "-4px",
                        position: "absolute",
                        right: "-4px",
                      }}
                    >
                      {cartData?.cartIds?.length}
                    </span>
                  )}
                </Link>
                {/* )} */}

                {authed ? <GoToProfile /> : <OpenDialog />}

                {/* <div
                  ref={myRefname1}
                  // href="#login-signup-modal"
                  className="btn mx-1"
                  data-bs-toggle="modal"
                  // data-target="#login-signup-modal"
                  onClick={openModal}
                >
                  <span className="sprite-icon size-20px">
                    <img
                      className="img-fluid w-100"
                      src="images/icons/icon-user.png"
                      alt="mail Icon"
                    />
                  </span>
                </div> */}
              </div>
              <div>
                <button
                  className="navbar-toggler p-0 border-0"
                  type="button"
                  id="navbarSideCollapse"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {/* ebter verification */}
      <div className="modal" id="verify-otp-modal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 px-4">
              <h5 className="modal-title fw-bold">Enter Verification Code</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body px-4 pb-4">
              <form
                action=""
                method="get"
                className="digit-group"
                data-group-name="digits"
                data-autosubmit="false"
                autoComplete="off"
              >
                <div className="row mb-3">
                  <div className="col-12">
                    <span className="text-dark">
                      We have sent a verification code to abc@example.com and{" "}
                      <span>+91 9876543210</span>
                    </span>
                  </div>
                </div>
                <div className="mb-3 pb-3">
                  <div className="form-group col-12 p-0 m-0">
                    <div className="d-flex justify-content-center mb-1">
                      <input
                        className="form-control"
                        type="text"
                        id="digit-1"
                        name=""
                        data-next="digit-2"
                      />
                      <input
                        className="form-control"
                        type="text"
                        id="digit-2"
                        name=""
                        data-next="digit-3"
                        data-previous="digit-1"
                      />
                      <input
                        className="form-control"
                        type="text"
                        id="digit-3"
                        name=""
                        data-next="digit-4"
                        data-previous="digit-2"
                      />
                      <input
                        className="form-control"
                        type="text"
                        id="digit-4"
                        name=""
                        data-next="digit-5"
                        data-previous="digit-3"
                      />
                      <input
                        className="form-control"
                        type="text"
                        id="digit-4"
                        name=""
                        data-next="digit-6"
                        data-previous="digit-4"
                      />
                      <input
                        className="form-control"
                        type="text"
                        id="digit-4"
                        name=""
                        data-previous="digit-5"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-12">
                    <span className="text-light-success">
                      Send the code again
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    data-bs-target="#login-signup-modal"
                    className="btn btn-link text-light-success px-0"
                  >
                    Change Phone Number
                  </button>
                </div>
                <div className="my-2 d-grid">
                  <button
                    type="submit"
                    className="btn text-white btn-light-success px-0"
                  >
                    Verify
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="forgot-password" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body px-4 pb-4">
              <div className="close">
                <button
                  type="button"
                  className="btn-close size-50px"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Button className="me-2 mb-2" onClick={() => setShowSearch(!showSearch)}>
        Full screen
      </Button> */}
      {/* search model */}
      <Modal
        show={showSearch}
        fullscreen={fullscreen}
        onHide={() => setShowSearch(false)}
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-7 mx-auto ">
            <InputGroup className="mb-3">
              {/* <InputGroup.Text id="basic-addon1">@</InputGroup.Text> */}
              <Form.Control
                placeholder="Search..."
                aria-label="Username"
                aria-describedby="basic-addon1"
                className="border-top-0 border-left-0 border-right-0 rounded-0 bg-transparent"
                // onChange={(e)=>(setSearch(e.target.value))}
                // onClick={handleSearch()}
                style={{
                  borderBottom: "2px solid black",
                }}
              />
            </InputGroup>
          </div>
        </Modal.Body>
      </Modal>

      <div
        className="modal fade"
        id="search-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-fullscreen modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end me-4">
                <button
                  type="button"
                  className="btn-close"
                  id="close-search-button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="px-1 pt-2 col-xl-4 col-lg-5 col-md-6 mx-auto">
                <input
                  type="search"
                  className="form-control form-control-lg px-0 border-dark text-dark only-border-bottom"
                  // value={searchInput}

                  placeholder="Search"
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                    mySearch();
                  }}
                  // onKeyDown={(e) => onPressEnterHandler(e)}
                />
              </div>
              <div
                className="list-group px-1 pt-2 col-xl-4 col-lg-5 col-md-6 mx-auto"
                id="seachmycontent"
              >
                {searchInput.length > 0 &&
                  searchResults?.map((item, index) => (
                    <div
                      key={index}
                      className="list-group-item list-group-item-action d-none p-0"
                      // data-bs-dismiss="modal"
                    >
                      {console.log("------map---item---", item)}
                      <a
                        className=" w-100 text-start"
                        // to={`/products/${item.productId}`}
                        href={`http://bulkmarket.com.s3-website.ap-south-1.amazonaws.com/product-details?product=${item.productId}&category=${item?.category?.id?.categoryid}&catname=${item?.category?.id?.categoryname}&subcategory=${item?.subCategory?.id?.categoryid}&subcatname=${item?.subcategory?.id?.categoryname}`}
                        // target="_blank"
                        rel="noreferrer"
                        // onClick={() => {
                        //   // dispatch(catalogueName(item.catalogueName));
                        //   // dispatch(productSlug(item.slug));
                        // }}
                      >
                        <div className="d-flex border-bottom">
                          <div>
                            <img
                              src={item.imageUrls[0]?.url}
                              width="40px"
                              alt=" "
                            />
                          </div>
                          <div className="ps-3">
                            {console.log("item-----------",item.title)}
                            <p className="mb-0 fw-bold"> {item.title}</p>
                            <p className="mb-0">{item.brand?.id?.name}</p>
                          </div>
                        </div>

                        {console.log(item.title)}
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
