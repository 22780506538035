import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getBanner } from "../../store/actions/banners.js/banner.action";

const ProductBanners = () => {
  const [topBanner,SetTopBanner]=useState([])
  const[leftBanner,SetLeftBanner]=useState([])
  const[rightBanner,setRightBanner]=useState([])
   useEffect(()=>{
    let obj={
        "filters":{
            "title":""
        }
    }
     getBanner(obj,SetTopBanner,SetLeftBanner,setRightBanner)
} ,[]);
   
  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-6 d-flex my-2">
          <div className="card card1 border-0 text-dark">
          {leftBanner?.map((item, i) => (
             <div key={i}>
                  
                   <a href={item?.link} target="_blank"  rel="noreferrer">
                  
                    <div className="img-holder" >
                       {/* <div
                       className='wide-img-height'
                        style={{
                          backgroundImage: `url(${item?.image})`,
                          // height: "400px",
                          width: "100%",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div> */}
                      <img className="img-fluid w-auto"  src={item?.image} alt="" /> 
                    </div>
                  </a>
                  </div>
                ))}
            
            {/* <div className="card-img-overlay">
              <div className="">
                <p className="h5 card-text mb-2">Provide us oppertunity to</p>
                <p className="h4 fw-bold text-truncate col-12 col-lg-11 col-xl-8">
                  Make you feel comfortable lore Lorem ipsum dolor sit
                </p>
                <div className="btn-holder border-dark my-3">
                  
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-xl-6 d-flex my-2">
          <div className="row">
            <div className="col-xl-12 col-md-6 mb-3 pb-1">

             

              {rightBanner?.map((item, i) => (
                <div className="card card2 border-0 text-dark">
             <div key={i}>
                  
                   <a href={item?.link} target="_blank"  rel="noreferrer">
                  
                    <div className="img-holder" >
                       {/* <div
                       className='wide-img-height'
                        style={{
                          backgroundImage: `url(${item?.image})`,
                          // height: "400px",
                          width: "100%",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div> */}
                      <img className="img-fluid w-auto"  src={item?.image} alt="" /> 
                    </div>
                  </a>
                  </div>

                    {/* <div className="card-img-overlay d-flex align-items-center justify-content-center text-center">
                  <div className="">
                    <p className="h5 card-text mb-2">
                      On your Household Products{" "}
                    </p>
                    <p className="h4 fw-bold">Upto 30% OFF</p>
                     
                  </div>
                </div>   */}

                  </div>
                ))}

              
             
                 
            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductBanners;
