import {
    FETCH_CATAGORYLIST_REQUEST,
    FETCH_CATAGORYLIST_SUCCESS,
    FETCH_CATAGORYLIST_FAILURE,

} from "../../type";
import axiosInstance from "../../../api/axiosInstance";
import {

    FETCH_CATAGORY_URL,

} from "../../../api/urls";

export const fetchCategoryList = () => {
    return (dispatch) => {
        dispatch(fetchCategoryListRequest());
        // api request
        try {
            axiosInstance
                .post(FETCH_CATAGORY_URL)
                .then((response) => {
                    let res = JSON.parse(response.data);


                    if (res.code === 200) {

                        dispatch(fetchCategoryListSucess(res.result));
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                    dispatch(fetchCategoryListFailure(error.message));
                });
        } catch (e) { }
    };
};



/* ******************************************************************************************
                                                                                            *
    Actions functions are defined below for CATAGORY Operations/Actions                    *
                                                                                            *
*********************************************************************************************
 
*/

const fetchCategoryListRequest = () => {
    return {
        type: FETCH_CATAGORYLIST_REQUEST,
    };
};

const fetchCategoryListSucess = (data) => {
    return {
        type: FETCH_CATAGORYLIST_SUCCESS,
        payload: data,
    };
};

const fetchCategoryListFailure = (error) => {
    return {
        type: FETCH_CATAGORYLIST_FAILURE,
    };
};
