import {
  GET_PRODUCT_DETAILS_REQUEST,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_FAILURE,
  // BOOKMARK_PRODUCT_REQUEST,
  BOOKMARK_PRODUCT_SUCCESS,
  BOOKMARK_PRODUCT_FAILURE,
} from "../../type";
import { GET_PRODUCT_DETAILS, BOOKMARK_PRODUCT_URL } from "../../../api/urls";

import axiosInstance from "../../../api/axiosInstance";

const fetchProductDetailsRequest = () => {
  return {
    type: GET_PRODUCT_DETAILS_REQUEST,
  };
};

const fetchProductDetailsSuccess = (productDetails) => {
  return {
    type: GET_PRODUCT_DETAILS_SUCCESS,
    payload: productDetails,
  };
};

const fetchProductDetailsFailure = (error) => {
  return {
    type: GET_PRODUCT_DETAILS_FAILURE,
    payload: error,
  };
};

// const addBookmarkRequest = () => {
//   return {
//     type: BOOKMARK_PRODUCT_REQUEST,
//   };
// };

const addBookmarkSuccess = (success) => {
  return {
    type: BOOKMARK_PRODUCT_SUCCESS,
    payload: success,
  };
};

const addBookmarkFailure = (error) => {
  return {
    type: BOOKMARK_PRODUCT_FAILURE,
    payload: error,
  };
};

// get product details to bew shown on product page

export const getProduct = (productId) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(fetchProductDetailsRequest());
      try {
        axiosInstance
          .post(GET_PRODUCT_DETAILS, productId)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(fetchProductDetailsSuccess(res.result));
              resolve();
            } else {
              dispatch(fetchProductDetailsFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            console.log(error.message);
            dispatch(fetchProductDetailsFailure(error.message));
          });
      } catch (e) {}

      // alert(error)
    });
};

export const getProductWithoutLoading = (productId) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      try {
        axiosInstance
          .post(GET_PRODUCT_DETAILS, productId)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(fetchProductDetailsSuccess(res.result));
              resolve();
            } else {
              dispatch(fetchProductDetailsFailure(res.message));
              reject();
            }
          })
          .catch((error) => {
            console.log(error.message);
            dispatch(fetchProductDetailsFailure(error.message));
          });
      } catch (e) {}

      // alert(error)
    });
};

export const bookmarkProductAction = (productId) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      //   dispatch(addBookmarkRequest());
      try {
        axiosInstance
          .post(BOOKMARK_PRODUCT_URL, productId)
          .then((response) => {
            let res = JSON.parse(response.data);

            if (response.status === 200) {
              dispatch(addBookmarkSuccess(res));
              resolve(res);
            } else {
              dispatch(addBookmarkFailure(res));
              reject();
            }
          })
          .catch((error) => {
            dispatch(addBookmarkFailure(error));
          });
      } catch (e) {}

      // alert(error)
    });
};

export const getProductDetails = (productId) => {
  return (dispatch) => {
    dispatch(fetchProductDetailsRequest());
    // Api call to get product Details
    const productDetails = "";
    dispatch(fetchProductDetailsSuccess(productDetails));
  };
};
