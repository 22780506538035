import React from 'react'

const Pagination = () => {
    return (
     
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                    <li className="page-item mx-1"><a className="btn btn-outline-light-success rounded-0 bg-none size-35px" href="#"><i className="fas fa-caret-left"></i></a></li>
                    <li className="page-item mx-1"><a className="btn btn-outline-light-success border-0 text-secondary rounded-0 bg-none" href="#">1</a></li>
                    <li className="page-item mx-1"><a className="btn btn-outline-light-success border-0 text-secondary rounded-0 bg-none" href="#">2</a></li>
                    <li className="page-item mx-1"><a className="btn btn-outline-light-success border-0 text-secondary rounded-0 bg-none" href="#">...</a></li>
                    <li className="page-item mx-1"><a className="btn btn-outline-light-success border-0 text-secondary rounded-0 bg-none" href="#">7</a></li>
                    <li className="page-item mx-1"><a className="btn btn-outline-light-success border-0 text-secondary rounded-0 bg-none" href="#">8</a></li>
                    <li className="page-item mx-1"><a className="btn btn-outline-light-success rounded-0 bg-none size-35px" href="/"><i className="fas fa-caret-right "></i></a></li>
                </ul>
            </nav>
      
    )
}

export default Pagination
