import React from "react";
import {Link} from "react-router-dom";

function Footer() {
  return (
    <footer>
      <section className="pb-0 pt-3">
        <div className="container">
          <div className="row my-2">
            <div className="col-md-3 col-12 d-flex justify-content-center my-3">
              <div>
                <div className="mb-4">
                  <div className="img-holder">
                  
                    <img style={{height: "50px", width: "150px"}}
                      src="images/eleven_logo.svg"
                      alt=""
                      className="img-fluid w-100 logo"
                    />
                  </div>
                </div>
                <div className="social-icons d-flex align-items-center">
                  <Link
                    to="https://twitter.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex text-white size-35px align-items-center justify-content-center"
                  >
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link
                    to="https://www.facebook.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex text-white size-35px align-items-center justify-content-center"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                  <Link
                    to="https://www.linkedin.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex text-white size-35px align-items-center justify-content-center"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </Link>
                  <Link
                    to="https://www.instagram.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex text-white size-35px align-items-center justify-content-center"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6 my-3">
              <div>
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <h5
                      className="nav-link h6 fw-bold text-white mb-0"
                      to="/"
                    >
                      Help
                    </h5>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/faq">
                      FAQ’s
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                                    <Link className="nav-link" to="#">Track</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="my-orders">Your Orders</Link>
                                </li> */}
                  <li className="nav-item">
                    <Link className="nav-link" to="/return">
                      Returns
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-6 my-3">
              <div>
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <h5
                      className="nav-link h6 fw-bold text-white mb-0"
                      to="/contact-us"
                    >
                      ABOUT US
                    </h5>
                  </li>
                  {/* <li className="nav-item">
                                    <Link className="nav-link" to="#">Our Story</Link>
                                </li> */}
                  <li className="nav-item">
                    <Link className="nav-link" to="/news">
                      News
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                                    <Link className="nav-link" to="#">Store Locator</Link>
                                </li> */}
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact-us">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-12 my-3">
              <div>
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <h5
                      className="nav-link h6 fw-bold text-white mb-0"
                      to="/"
                    >
                      CUSTOMER SERVICES
                    </h5>
                  </li>
                  {/* <li className="nav-item">
                                    <Link className="nav-link" to="edit-profile.html">My Account</Link>
                                </li> */}
                  <li className="nav-item">
                    <Link className="nav-link" to="/terms-of-use">
                      Terms of Use
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/terms-and-condition">
                      Terms & Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row py-3 border-top border-white text-center justify-content-center">
            <Link to="/" className="text-white text-decoration-none">
              {" "}
              Bulk Order© 2021 | All rights reserved.
            </Link>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
