import React from 'react'
import ProductRateStarRating from './ProductRateStarRating'
import ReviewProductPills from './ReviewProductPills'

const ReviewRateProductComponent = ({ title, starCount, subHeading, SmileyIcon }) => {
    return (
        <>
            <div className="h6 fw-medium">
                {title}
                <span className="sprite-icon size-20px">
                    <img className="img-fluid w-100" src={SmileyIcon} alt="" />
                </span>
            </div>
            <div className="d-inline-block">

                <ProductRateStarRating />
                <div className="d-flex align-items-end">
                    <div className="fw-medium text-dark  mb-0">{subHeading}<span className="sprite-icon size-20px"><img className="img-fluid w-100" src={SmileyIcon} alt="" /></span></div>
                </div>
                <ReviewProductPills pillsList={[1, 2, 3, 4]} title="Product" />
                <ReviewProductPills pillsList={[1, 2, 3, 4, 5, 6, 7, 8, 9]} title="Services" />
                <div className="d-flex align-items-end my-3">
                    <div className="fw-medium text-dark  mb-0">How we can improve our service? <span className="sprite-icon size-20px"><img className="img-fluid w-100" src="assets/images/icons/smily.png" alt="" /></span></div>
                </div>
            </div>


        </>
    )
}

export default ReviewRateProductComponent
