import React, { useState } from 'react'
import { useHistory,  } from "react-router-dom";
import Layout from '../layout/Layout'
import * as Yup from "yup";
 import "../assets/css/reset.css"
 
import { Formik } from 'formik';
import axiosInstance from '../api/axiosInstance';


const ResetPassword = () => {
  const history=useHistory()
    const token=new URLSearchParams(window.location.search).get("token")
    const email=new URLSearchParams(window.location.search).get("email")
    console.log("email---------",email)
  const[message,setMessage]=useState([])
  const[color,setColor]=useState(false)

    const resetHandler=async(values)=>{
 let obj={
    "userEmail":email,
    "token":token,
    "newPassword":values.password,
    "confirmPassword":values.newPassword
}
 
console.log("obj-------------",obj)
const result= await axiosInstance.post("/forgotpassword/passwordReset",obj)

const res=JSON.parse(result.data)
console.log(res)
if (res.code===200){
  setColor(true)
  // setMessage(res.message)
  setMessage("Password Change Successfully ")
  setTimeout(()=>{
    setMessage('')
   history.push("/")
  },5000)
}else(
  setMessage("Password did not match")
)


 
    }

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        resetHandler();
      }
    };

  return (
    <Layout>
 

 
<div className='row'>
    <div className='col-12 d-flex justify-content-center'>
    



 
  <div>
    <h5 className='mb-3'>Reset Password!</h5>
    <Formik
    //   initialValues={{ email: '', password: '',newPassword:'',confirmPassword: '' }}
      initialValues={{   password: '',newPassword:''  }}
    
    // validationSchema={Yup.object().shape({
        
    //       password:Yup.string().required("required").matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/),
    //        newPassword:Yup.string().required("password matched").matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)

    //   })}
    validationSchema={ Yup.object().shape({
      // currentPassword: Yup.string().required("Current Password is required"),
      password: Yup.string().required("password is required"),
      newPassword: Yup.string()
        .required("Confirm Password Field is required")
        .test("passwords-match", "Passwords must match", function (value) {
          return this.parent.password === value;
        }),
    })}
      onSubmit={(values,  ) => {
       ;
        console.log("valiue",values)
        resetHandler(values)
        
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="mb-8">
            {/* <div className='mb-3'>
            <label htmlFor="email"  >
              Email :- 
            </label>
            <input
            type="email"
            name="email"
            className={
                errors.email && touched.email
                  ? "text-input error"
                  : "text-input"
              }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
             {errors.email && touched.email && (
              <div className="input-feedback">{errors.email}</div>
            )}
            </div> */}
         
      
          <div className='mb-3'>
          <label htmlFor="password"  >
          Password :- 
            </label>
          <input
            type="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
          {/* {errors.password && touched.password && errors.password} */}
          {errors.password && touched.password && (
              <div className="input-feedback">{errors.password}</div>
            )}
          </div>
         
          <div className='mb-3'>
          <label htmlFor="newPassword"  >
      Confirm Password :- 
            </label>
          <input
            type="password"
            name="newPassword"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.newPassword}
          />
           {errors.newPassword && touched.newPassword && (
              <div className="input-feedback">{errors.newPassword}</div>
            )}
          </div>
           {/* <div className='mb-3'>
          <label htmlFor="confirmPassword"  >
        Confirm Password :- 
            </label>
          <input
            type="confirmPassword"
            name="confirmPassword"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmPassword}
          />
          {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
          </div>   */}
          <div className={`${color?"text-success":"text-danger"}`} >{message}</div>
          {/* <button type="submit" disabled={isSubmitting}> */}
          <button type="submit"    onKeyDown={handleKeyDown} >
            Submit
          </button>
        </form>
      )}
    </Formik>
  </div>


 


    </div>

</div>
 
   
 

 
</Layout>
  )
}

export default ResetPassword


// import React from "react";
 
// import { useForm } from "react-hook-form";
// import Layout from '../layout/Layout'

// import "../assets/css/reset.css";

//  const ResetPassword=()=> {
//   const {
//     register,
//     handleSubmit,
//     getValues,
//     watch,
//     formState: { errors }
//   } = useForm();
//   const onSubmit = (data) => {
//     console.log(data);
//   }; // your form submit function which will invoke after successful validation

//   console.log(watch("password")); // you can watch individual input by pass the name of the input

//   return (
//     <Layout>
//       <div className="row">
//         <div className="col-12 d-flex justify-content-center">
//         <form onSubmit={handleSubmit(onSubmit)}>
//       {/* register your input into the hook by invoking the "register" function. Here we can register the validations rules also */}
     
//      <div className="row">
//       <div className="col-12">
// <div className="row">
//   <div className="col-3">
//   <label  >
//     Password
//      </label>
//   </div>
// <div className="col-9">
// <input
//         {...register("password", {
//           required: true,
//           minLength: 8
//         })}
//         id="password"
//         type="password"
//         placeholder="********"
//       />
      
//       {errors?.password?.type === "required" && <p>This field is required</p>}
//       {errors?.password?.type === "minLength" && (
//         <p>password cannot less than 5 characters</p>
//       )}
// </div>
     
// </div>
//       </div>
      
    
//      </div>
    

// <div className="row">
//   <div className="col-12">
//     <div className="row">
//       <div className="col-3">
//         <label>Confirm Password</label>
//       </div>
// <div className="col-9">
//    {/* include validation with required or other standard HTML validation rules */}
//    <input
//         {...register("password_repeat", { required: true })}
//         id="password"
//         type="password"
//         placeholder="********"
//       />
//       {/* errors will return when field validation fails  */}
//       {/* here we watch the both password and confirm password filed and if both not match, trigger the validation */}
//       {watch("password_repeat") !== watch("password") &&
//       getValues("password_repeat") ? (
//         <p>password not match</p>
//       ) : null}

// </div>
//     </div>
//   </div>
// </div>

     

//       <input type="submit" />
//     </form>
//         </div>
//       </div>
    
//     </Layout>
    
//   );
// }
// export default ResetPassword