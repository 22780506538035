import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";



import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/home.css"
import "./assets/css/carousel.css"
import "./assets/css/cart.css"
import "./assets/css/custom.css"
import "./assets/css/orders.css"
import "./assets/css/product-details.css"
import "./assets/css/products.css"
import "./assets/css/profile.css"
import "./assets/css/review-pay.css"
import "./assets/css/shipping.css"
import "./assets/css/common.css"

import { Provider } from 'react-redux'
import store from './store/store'


ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root"));
