import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { settings, slickConfig } from "../../utils/settings";
import axiosInstance from "../../api/axiosInstance";
import { useSelector } from "react-redux";
import { isAuth } from "../../utils/auth";
import { useToasts } from "react-toast-notifications";

import {
  addToCartAction,
  removeCartItem,
} from "../../store/actions/cartAction";
import { useDispatch } from "react-redux";

const BestSellingProducts = () => {
  const [products, setProducts] = useState([]);
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const authed = isAuth();

  useEffect(() => {
    function getProductByType() {
      let obj = {
        count: 0,
        field: "max",
        filters: { type: "Best Deals" },
        limit: 9,
        sortBy: { type: "postDate", order: -1 },
      };

      try {
        axiosInstance
          .post(`/commonroutes/loadProducts`, obj)
          .then((response) => {
            let res = JSON.parse(response.data);
            if (res.code === 200) {
              setProducts(res.data.products);
            }
          })
          .catch((error) => {});
      } catch (e) {}
    }
    getProductByType();
  }, []);

  function getDiscount(product) {
    let dis =
      product?.variants?.length > 0 &&
      ((product?.variants?.[0].mrp - product?.variants?.[0].price) * 100) /
        product?.variants?.[0].mrp;
    return !isNaN(dis) ? dis : 0;
  }

  const addToCart = (product) => {
    const requestObj = {
      productId: product.productId,
      variantId: product?.variants?.length
        ? product?.variants[0].variantId
        : null,
      quantity: 1,
    };

    console.log("Click", requestObj, authed);

    if (authed && product?.variants?.length > 0) {
      if (cart?.cartIds?.includes(product.productId)) {
        dispatch(removeCartItem(requestObj)).then((result) => {
          addToast("Prodcut Removed in Cart", {
            appearance: "error",
          });
        });
      } else {
        dispatch(addToCartAction(requestObj)).then((result) => {
          addToast("Prodcut Added to Cart", {
            appearance: "success",
          });
        });
      }
    } else {
      addToast("Please Login", { appearance: "error" });
    }
  };

  return (
    <div className="contaner px-sm-5">
      <div className="row section-heading text-center">
        <div className="col-12 main-heading">
          <h3 className="fw-bold text-white">BEST SELLING PRODUCTS </h3>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-12">
          <div className="four-product-carousel">
            {/* <Slider {...slickConfig(products?.length > 4)}> */}
            <Slider {...settings}>
              {products?.length > 0 &&
                products.map((product, index) => (
                  <div key={index} className="py-1 w-100 px-2">
                    <div className="mx-2 w-100">
                      <div className="card border p-0 card-shadow   w-100  ">
                        <div className="btn btn-success rounded m-0 text-white fw-bold w-25 cost-font p-1 m-1">
                          <span className="img-text">
                            {product?.discount}% OFF
                          </span>
                        </div>
                        <div>
                          <Link
                            to={`/product-details?product=${product?.productId}&category=${product.category?.categoryid}&catname=${product?.category.id?.categoryname}&subcategory=${product?.subCategory?.categoryid}&subcatname=${product?.subCategory?.id?.categoryname}}`}
                          >
                            <img
                              src={product?.imageUrls?.[0]?.url}
                              className="card-img-top  img-fluid shadow-sm object-fit-cover mx-auto"
                              alt="..."
                              style={{ width: "230px" }}
                            />
                          </Link>
                        </div>
                        <div className="card-body pt-1">
                          <div className="card-text">
                            <p className="para-font fw-bold mb-1 text-truncate">
                              Ref No.:{product.productCode}
                              <span className="text-truncate2">
                                {" "}
                                Brand : {product?.brand?.id?.name}
                              </span>
                            </p>
                            <div className="text-truncate2 fw-bold mb-1 mt-0 text-truncate"
                            style={{
                              fontSize:'large'
                            }}
                            >
                              {" "}
                              {product.title}
                            </div>
                          </div>
                          <div className="col">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row pb-0 pt-0  ">
                              <div class="d-flex bd-highlight">
                                <div class=" flex-grow-1    ">
                                  <b>Price :-</b>
                                  <span>
                                    ₹
                                    {product.price -
                                      (product.price * product?.discount) / 100}
                                  </span>
                                </div>
                                <div class=" bd-highlight ">
                                  <b>MRP:- </b>{" "}
                                  <span className="para-font">
                                    <del>₹{product.price}</del>
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-12 mt-3">
                                <div
                                  onClick={() => addToCart(product)}
                                  className="btn btn-success rounded-0 m-0 w-100 text-white p-1"
                                >
                                  {cart?.cartIds?.includes(product.productId)
                                    ? "Already in Cart"
                                    : " Add to Cart"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>

            {/* <div className="m-2 py-1">
                <div className="card shadow">
                  <div className="card-body best-product p-0">
                    <div className="card-img">
                      <div className="d-flex justify-content-between align-items-center card-img-top card-body">
                        <span className="size-20px text-secondary">
                          <i className="fas fa-heart"></i>
                        </span>
                        <span className="badge bg-success size-35px rounded-circle d-flex justify-content-center align-items-center">
                          -%
                        </span>
                      </div>
                      <img
                        className="img-fluid w-100"
                        src="images/best-product.png"
                        alt=""
                      />
                    </div>
                    <div className="card-body bg-white">
                      <div className="row">
                        <div className="col">
                          <div className="text-success small mb-2">
                            Product Name
                          </div>
                          <div className="d-flex">
                            <div className="h6 text-success mb-0">₹7,000</div>
                            <div className="small text-light-gray text-decoration-line-through mx-2">
                              ₹14,000
                            </div>
                          </div>
                        </div>
                        <div className="col-auto d-flex align-items-center">
                          <div className="size-20px">
                            <i className="fas fa-shopping-cart"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-2 py-1">
                <div className="card shadow">
                  <div className="card-body best-product p-0">
                    <div className="card-img">
                      <div className="d-flex justify-content-between align-items-center card-img-top card-body">
                        <span className="size-20px text-secondary">
                          <i className="fas fa-heart"></i>
                        </span>
                        <span className="badge bg-success size-35px rounded-circle d-flex justify-content-center align-items-center">
                          -50%
                        </span>
                      </div>
                      <img
                        className="img-fluid w-100"
                        src="images/best-product.png"
                        alt=""
                      />
                    </div>
                    <div className="card-body bg-white">
                      <div className="row">
                        <div className="col">
                          <div className="text-success small mb-2">
                            Product Name
                          </div>
                          <div className="d-flex">
                            <div className="h6 text-success mb-0">₹7,000</div>
                            <div className="small text-light-gray text-decoration-line-through mx-2">
                              ₹14,000
                            </div>
                          </div>
                        </div>
                        <div className="col-auto d-flex align-items-center">
                          <div className="size-20px">
                            <i className="fas fa-shopping-cart"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-2 py-1">
                <div className="card shadow">
                  <div className="card-body best-product p-0">
                    <div className="card-img">
                      <div className="d-flex justify-content-between align-items-center card-img-top card-body">
                        <span className="size-20px text-danger">
                          <i className="fas fa-heart"></i>
                        </span>
                        <span className="badge bg-warning size-35px rounded-circle d-flex justify-content-center align-items-center">
                          New
                        </span>
                      </div>
                      <img
                        className="img-fluid w-100"
                        src="images/best-product.png"
                        alt=""
                      />
                    </div>
                    <div className="card-body bg-white">
                      <div className="row">
                        <div className="col">
                          <div className="text-success small mb-2">
                            Product Name
                          </div>
                          <div className="d-flex">
                            <div className="h6 text-success mb-0">₹7,000</div>
                            <div className="small text-light-gray text-decoration-line-through mx-2">
                              ₹14,000
                            </div>
                          </div>
                        </div>
                        <div className="col-auto d-flex align-items-center">
                          <div className="size-20px text-warning">
                            <i className="fas fa-shopping-cart"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-12 text-center">
          <div className="btn-holder my-3">
            <Link to="/product" className="btn btn-white">
              Explore Now
            </Link>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default BestSellingProducts;
