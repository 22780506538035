import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import { Formik, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line
import { getProfileData, updateUserEmail, updateProfileInfo } from '../../store/actions/user/userInfoAction'
import Loader from '../loader'
import Swal from "sweetalert2";

const EditProfile = ({ authUserId }) => {
  const dispatch = useDispatch()
  let userDetails = useSelector(state => state.userInfo.user)
  const [user, setUser] = useState({})

  const [isLoading, setIsLoading] = useState(true)
  const loading = useSelector(state => state.userInfo.isLoading)
  // eslint-disable-next-line
  const [selectedImage, setSelectedImage] = useState(false)
  
  const onImageSelection = (event) => {

    setSelectedImage(event.target.files[0])
  }

  const Alert = (title, icon, path) => {
    Swal.fire({
      title: title,
      text: "",
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Continue",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        dispatch(getProfileData({ userId: authUserId }))
      } else {
        dispatch(getProfileData({ userId: authUserId }))
      }
    });
  };

  useEffect(() => {
    setIsLoading(loading)
    setUser(userDetails)
  }, [loading, userDetails])

  useEffect(() => {
    dispatch(getProfileData({ userId: authUserId }))
  }, [dispatch ,authUserId])

  const editFormHandler = (values) => {

    const profileData = {
      "fullName": values.fullName,
      "gender": values.gender,
      "bio": values.bio

    }

    dispatch(updateProfileInfo(profileData)).then((resolve) => {
      Alert('Profile Successfully Updated', 'success')
    },

      (reject) => {
        Alert("Something went wrong, please try later", 'error')
      }
    )
    // dispatch(updateUserEmail({ email: values.email })).then((resolve) => {
    //   Alert('Profile Successfully Updated', 'success')
    // })
  }

  return (

    isLoading ? <Loader /> :


      <Formik

        onSubmit={(values) => {

           
          editFormHandler(values);
        }}
        // validationSchema={signupValidation}
        initialValues={{
          fullName: user.fullName,
          username: user.username,
          email: user.userEmail,
          mobile: user.mobile,
          gender: user.gender,
          bio: user.bio,
          // gst:user.gst,
          // adhaar:user.adhaar


        }}
      >
        {(formik) => {
          return (
            <>
              <div className="col-lg-8">
                {/* <div className="addit-profile-box">
                  <div className="avatar-upload mt-0">
                    <div className="avatar-edit">
                      <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" onChange={onImageSelection} />
                      <label htmlFor="imageUpload"></label>
                    </div>
                    <div className="avatar-preview">
                      <div>
                        <img
                          className="img-profile"
                          src="images/icons/user.jpg"
                          alt="profile"
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                <form className="row g-3 needs-validation" onSubmit={formik.handleSubmit}>
                  <div className="col-md-6">
                    <label htmlFor="validationCustom01" className="form-label">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full Name"
                      id="validationCustom01"
                      name="fullName"
                      value={formik.values.fullName}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {/* <div className="col-md-6">
            <label htmlFor="validationCustom02" className="form-label">
              Last name
            </label>
            <input
              type="text"
              className="form-control"
              id="validationCustom02"
              placeholder="Sharma"
              required
            />
          </div> */}
                  <div className="col-md-6">
                    <label htmlFor="validationCustom02" className="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom02"
                      placeholder="User Name"
                      name="username"
                      required
                      value={formik.values.username}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="male" className="form-label">
                      Gender
                    </label>
                    <div className="d-flex form-inline form-group mt-2">
                      <div className="form-check common-radio me-3">
                        <Field
                          className="common-radio-input"
                          type="radio"
                          name="gender"
                          id="male"
                          hidden={true}
                          onChange={formik.handleChange}
                          value="male"
                        />
                        <label
                          className="common-radio-label pl-sm-4 text-dark"
                          htmlFor="male"
                        >
                          Male
                        </label>
                      </div>
                      <div className="form-check common-radio me-3">
                        <Field
                          className="common-radio-input"
                          type="radio"
                          name="gender"
                          id="female"
                          hidden={true}
                          onChange={formik.handleChange}
                          value="female"

                        />
                        <label
                          className="common-radio-label pl-sm-4 text-dark"
                          htmlFor="female"
                        >
                          Female
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="validationCustom0email" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="validationCustom0email"
                      placeholder="User Email"
                      required
                      name="email"
                      value={formik.values.email}

                    />
                  </div>
                  {/* gst and  adhaar*/}
                  
                  <div className="col-md-12">
                    <label htmlFor="validationCustom02phone" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom02phone"
                      placeholder="Contact Number"
                      required
                      name="mobile"
                      value={formik.values.mobile}
                      disabled
                    />
                  </div>
                  {/* <div className="col-md-12">
                    <label htmlFor="validationCustom02" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="validationCustom02"
                      name="password"
                      placeholder="************"
                    />
                  </div> */}
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">Bio</label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      name="bio"
                      value={formik.values.bio}
                      onChange={formik.handleChange}
                    ></textarea>
                  </div>

                  <div className="col-12">
                    <button className="btn btn-light-success" type="submit">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </>
          );
        }}
      </Formik>
  )
};

export default EditProfile;
