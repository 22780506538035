import React, { useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import AmountFormat from "../amountFormat";

const ItemDisplay = ({
  product,
  imageUrl,
  removeItem,
  updateCartCount,
  moveToWishList,
  item,
}) => {
  const [value, setValue] = useState(product.quantity);
 const[QuantityValue, setQuantityValue]=useState()
 
  const { price, title, mrp } = item;

  console.log("product===============",product)
  // http://bulkmarket.com.s3-website.ap-south-1.amazonaws.com/product-details?product={product?.productId}&category={product?.category?.categoryid}&catname={product?.categpry?.id?.categoryname}&subcateory={product?.subCategory?.categoryid}&subcatname={product?.sub2Category?.id?.categoryname}
  // http://bulkmarket.com.s3-website.ap-south-1.amazonaws.com/product-details?product=6buzZe776dMZENtd79Vp7viQIpAZBQgP&category=6c2TBBBukXitNaco3ZLEpNP3HwIkoBrH&catname=06-09%20/sana&subcategory=P5KMOjImua7uxjzhsJyveAo4X3n6i54s&subcatname=06-%20sana


  
const [priceDisplay , setPriceDisplay] = useState({
  quantity :"",
  price :""
})

  useEffect(()=>{
    if(product.quantity === 1){
      setPriceDisplay({
        quantity:  product.quantity,
        // price : product.price
        price : (product.price)-(((product.price)*(product.discount))/100)
      })
    }else{
      for(var i=0 ; i <QuantityValue?.length ; i++){
        // console.log("rrrrrrrr---->>", QuantityValue[i].qty.split('-')[0] ,product.quantity ,  QuantityValue[i].qty.split('-')[1] >product.quantity);
        // console.log("rrrrrrrr---->>", parseInt(QuantityValue[i].qty.split('-')[0] ),product.quantity)
        if(  parseInt(QuantityValue[i].qty?.split('-')[0]) <=product.quantity 
         &&   parseInt(QuantityValue[i].qty?.split('-')[1]) >= product.quantity
          ){
  
          // console.log("Success" , product.quantity , QuantityValue[i].price )
          setPriceDisplay({
            quantity:  product.quantity,
            price :QuantityValue[i].price
          })
      }
      
      }
    }
    

  } ,[ QuantityValue , product ])
 
   

  const increment = () => {
    
    const updatedValue = value + 1;
    setValue(updatedValue);
    updateCartCount(product, updatedValue);
  };

  const decrement = () => {
    const updatedValue = value - 1;
    
    if (updatedValue > 0) {
      setValue(updatedValue);
      updateCartCount(product, updatedValue);
    }
    // dispatch action
  };

  // useEffect(() => {
  //     updateCartCount(product, value)
  // }, [value])

  function getDiscount() {
    let dis = ((item.mrp - item.price) * 100) / item.mrp;
    return !isNaN(dis) ? dis : 0;
  }

 
  useEffect(()=>{

    let arr =[]

    if(product && product.variants){
      product?.variants?.forEach((item)=>{
        arr.push({
          qty :item.name,
          price: item.price
        })
      })
      setQuantityValue(arr)
    }

  //   product?.variants?.map((items)=>{
      
  //       arr({qty :items.name})
 
  // })

  },[product])

  console.log("priceDisplay=====>>" , priceDisplay);
   

  return (
    <>
      <div className="row my-3">
        <div className="col-lg-2">
          <div className="size-100px">
            <a href={`http://bulkmarket.com.s3-website.ap-south-1.amazonaws.com/product-details?product=${product?.productId}&category=${product?.category?.categoryid}&catname=${product?.categpry?.id?.categoryname}&subcateory=${product?.subCategory?.categoryid}&subcatname=${product?.sub2Category?.id?.categoryname}`}>

            <img
              src={imageUrl}
              alt="cart-product"
              style={{ height: "5.5rem" }}
            />
            </a>
             
          </div>
        </div>
        <div className="col-lg-10">
          <div className="col-lg-12">
            <div className="d-flex justify-content-lg-between">
              <div className="">
                {/* <h3 className="pro-title mb-1 text-capitalize">{title}</h3> */}
                <h5 className="product-name text-capitalize" id="product-name">
        Product Title :- {title}
        </h5>
        <h5 className="product-name text-capitalize" id="product-name">
          Brand Name:- {product?.brand.id?.name}
        </h5>
        <h5 className="product-name text-capitalize" id="product-name">
          Ref No :- {product?.productCode }
        </h5>
                {/* <p className="pro-sub-title">Delivery in 21 Days hard code</p> */}
              </div>

              
              <div className="text-end">
                <h4 className="pro-price mb-1">
                  {/* {`${product.quantity===1 && <>MRP:- <del><AmountFormat value={price} /></del></>  }`}
                  {`${priceDisplay.quantity >1 && <>  MRP:- <del><AmountFormat value={priceDisplay.price} /></del> </>   }`} */}
                  {/* MRP:- <del><AmountFormat value={price} /></del>  */}
                 {/* MRP:- <del>{price}</del>   */}
                 Mrp:- <del>{mrp}</del>  
                 </h4>
                 <h4 className="pro-price mb-1">
                  {priceDisplay?.quantity===1 &&<>Selling Price:- <del>{price}</del></> }
                  {priceDisplay?.quantity!==1 && <>Selling Price:-<del>{priceDisplay.price}</del></>}
                  {/* MRP:- <del><AmountFormat value={priceDisplay.price} /></del> */}
              
                </h4>
                <h4 className="pro-price mb-1">
                {/* Price:- ₹ { priceDisplay.price && (value)*(product.price)-(((product.price)*(product.discount))/100)} */}
               {priceDisplay?.quantity===1 && <>Price:- ₹ {price}</>}
              {priceDisplay?.quantity!==1 && 
              <>Price:- ₹ {( priceDisplay.price*priceDisplay.quantity)}</>}  

                </h4>
                {priceDisplay?.quantity===1 &&<>Discount:- <p>{((((price)-(priceDisplay.price*priceDisplay.quantity))/(price))*100).toFixed(2)}% Off</p></> }
                {priceDisplay?.quantity!==1 && <>Discount:-<p>{((((mrp)-(priceDisplay.price))/(mrp))*100).toFixed(2)}% off</p></>}
                {/* {getDiscount() > 0 && (
                  <div>
                    <s className="pro-price-delete pe-3">₹ {mrp - price}</s>
                    <span className="off-price">{getDiscount()}% OFF</span>
                  </div>
                )} */}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="pl-4 d-flex">
              <div className="pe-2">
                <div className="control">
                  <button
                    className="btn bttn-left btn-outline-light"
                    id="minus"
                    onClick={decrement}
                    disabled={value === 1 ? true : false}
                  >
                    <span className="fa fa-minus text-secondary"></span>
                  </button>
                  {/* <input type="number" className="input" id="input" value={value} /> */}
                  <div style={{ padding: ".5rem" }}>{value}</div>

                  <button
                    className="btn bttn-right btn-outline-light"
                    id="plus"
                    onClick={increment}
                  >
                    <span className="fa fa-plus"></span>
                  </button>
                </div>
              </div>
              <div className="ps-2 align-self-center border-start text-cart">
                <span
                  className="pe-2"
                  onClick={() => removeItem(product)}
                  style={{ cursor: "pointer" }}
                >
                  REMOVE
                </span>
                <span
                  onClick={() => moveToWishList(product)}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  MOVE TO WISHLIST
                </span>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      <hr/>
    </>

  );
};

export default ItemDisplay;
