import React from "react";

const Address = ({
  name,
  address,
  city,
  state,
  contact,
  addressType,
  addressId,
  pincode,
  completeAddress,
  setActiveAddress
}) => {
  const saveAddress = () => {
    setActiveAddress(addressId)
    localStorage.setItem("deliveryAddress", JSON.stringify(completeAddress));
  };
  return (
    <>
      <div className="card-body">
        <div className="top-right pe-3 me-1 mt-2">
          <span className="badge bg-light-success">{addressType}</span>
        </div>
        <div>
          <div className="d-flex form-inline form-group">
            <div className="form-check common-radio me-3">
              <input
                className="common-radio-input"
                type="radio"
                name="userAddress"
                id={addressId}
                hidden={true}
                onChange={() => saveAddress()}
              />
              <label
                className="common-radio-label pl-sm-4 text-dark"
                htmlFor={addressId}
              >
                <div className="mb-1 fw-medium text-uppercase">{name}</div>

                <div className="mb-1 text-light-gray">
                  {address} {city} <br />
                  {state} {" - "}
                  {pincode}
                </div>
                <div className="mb-1 text-dark">{contact}</div>
              </label>
            </div>
          </div>
        </div>
        <div className="bottom-right pe-3 me-1 my-2">
          <button className="btn btn-sm text-light-gray">
            <i className="fas fa-ellipsis-v"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default Address;
