import React from 'react';
import AmountFormat from '../amountFormat'

const QuotationDisplay = ({ product, title, price, imageUrl, removeItem, updateCartCount }) => {
    // const [value, setValue] = useState(product.quantity)
    // const increment = () => {
    //     const updatedValue = value + 1;
    //     setValue(updatedValue);
    //     updateCartCount(product, updatedValue)
    // }

    // const decrement = () => {
    //     const updatedValue = value - 1;
    //     if (updatedValue > 0) {
    //         setValue(updatedValue);
    //         updateCartCount(product, updatedValue)
    //     }
    // }

    // useEffect(() => {
    //     updateCartCount(product, value)
    // }, [value])
    return (
        <>

            <div className="row my-3">
                <div className="col-lg-2">
                    <div className="size-100px">
                        <img src={imageUrl} alt="cart-product" style={{ height: '5.5rem' }} />
                    </div>
                </div>
                <div className="col-lg-10">
                    <div className="col-lg-12">
                        <div className="d-flex justify-content-lg-between">
                            <div className="">
                                <h3 className="pro-title mb-1 text-capitalize">{title}</h3>
                                <p className="pro-sub-title">Delivery in 2 Days</p>
                            </div>
                            <div className="text-end">
                                <h4 className="pro-price mb-1"><AmountFormat value={price} /></h4>
                                <div>
                                    <s className="pro-price-delete pe-3">₹ 14,000</s><span className="off-price">10% OFF</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="pl-4 d-flex">
                            <div className="pe-2">
                                <div className="control">
                                    {/* <button className="btn bttn-left btn-outline-light" id="minus" onClick={decrement} disabled={value == 1 ? true : false}>
                                        <span className="fa fa-minus text-secondary"></span>
                                    </button> */}
                                    <label htmlFor="input">Quantity: {} </label>
                                    {/* <input type="number" className="input" id="input" value={value} /> */}

                                    {/* <button className="btn bttn-right btn-outline-light" id="plus" onClick={increment}>
                                        <span className="fa fa-plus"></span>
                                    </button> */}
                                </div>
                            </div>
                            {/* <div className="ps-2 align-self-center border-start text-cart">
                                <span className="pe-2" onClick={() => removeItem(product)} style={{ cursor: "pointer" }}>REMOVE</span>
                                <span onClick={() => moveToWishList(product)} style={{ cursor: "pointer" }}> MOVE TO WISHLIST</span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default QuotationDisplay
