import {
    FETCH_BEST_DEALS_REQUEST,
    FETCH_BEST_DEALS_SUCCESS,
    FETCH_BEST_DEALS_FAILURE,
} from '../../type';


const initialState = {
    isLoading: true,
    products: [],
    error: '',
    message: ''

}






const bestDealsReducer = (state = initialState, action) => {


    switch (action.type) {

        case FETCH_BEST_DEALS_REQUEST:
            return {
                ...state,
                isLoading: true,


            }

        case FETCH_BEST_DEALS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                products: action.payload,
                message: action.payload.message

            }

        case FETCH_BEST_DEALS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,


            }
        default:
            return state


    }

}

export default bestDealsReducer