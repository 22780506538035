import{
    FETCH_SUB_SUB_CATAGORYLIST_REQUEST,
    FETCH_SUB_SUB_CATAGORYLIST_SUCCESS,
     FETCH_SUB_SUB_CATAGORYLIST_FAILURE,
} from "../../type";

 import { FETCH_SUB_SUB_CAT_URL } from "../../../api/urls";
import axiosInstance from "../../../api/axiosInstance";


export const fetchSubSubCatList=()=>{
    return(dispatch)=>{
        dispatch(fetchSubSubCatListRequest())
        try{
            axiosInstance.post(FETCH_SUB_SUB_CAT_URL).then((response)=>{
                let res=JSON.parse(response.data)
                 if(res.code===200)
                dispatch(fetchSubSubCategoryListSuccess(res.result))
            })
        }catch(error){
            console.log("erre",error)
            dispatch(fetchSubSubCatagoryListFailure(error.message))

        }
    }
}


const fetchSubSubCatListRequest=()=>{
    return{
        type:FETCH_SUB_SUB_CATAGORYLIST_REQUEST,
    }
}
const fetchSubSubCategoryListSuccess=(data)=>{
    return{
        type:FETCH_SUB_SUB_CATAGORYLIST_SUCCESS,
        payload:data,
    }
}
const fetchSubSubCatagoryListFailure=(error)=>{
    return{
        type:FETCH_SUB_SUB_CATAGORYLIST_FAILURE
    }
}
