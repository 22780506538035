import React from 'react'
import Header from '../components/homePage/Header'
import Footer from '../components/homePage/Footer'
import ReviewProductLeft from '../components/product/ReviewProductLeft'
import ProductImage from '../assets/images/products/product4.png'
import ReviewRateProductComponent from '../components/product/ReviewRateProductComponent'
import SmileyIcon from '../assets/images/icons/smily.png'
import { Formik, Field, Form } from 'formik';
const ReviewRateProduct = () => {
    return (
        <>
            <Header />

            <main class="page-wrapper">

                <section class="pt-3">
                    <div class="container px-sm-5">
                        <div class="row">
                            <div class="col-xl-4 col-lg-5 pt-3">

                                <ReviewProductLeft title={"Review & Rate Product"} Image={ProductImage} productName={"Iphone 12 Pro Max ( Gold, 512GB )"} />



                            </div>
                            <div class="col-xl-8 col-lg-7 p-4">
                                <div class="product-view-details">


                                    <ReviewRateProductComponent title="Rate the product exprience" SmileyIcon={SmileyIcon} starCount={4} subHeading="What did you like?" />



                                    <Formik
                                        initialValues={{
                                            "suggestion": "",

                                        }}



                                        onSubmit={fields => {
                                            // console.log("fields===>", fields)

                                        }}
                                        render={({ errors, status, touched }) => (
                                            <Form>
                                                <Field name="suggestion" type="text" className="form-control" placeholder="Please Suggest Us...." />

                                                <div class="row">
                                                    <div class="btn-holder my-3">
                                                        <button type="submit" class="btn px-xl-5 btn-light-success text-uppercase">Submit</button>
                                                    </div>
                                                </div>
                                            </Form>

                                        )} />

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />

        </>
    )
}

export default ReviewRateProduct
