import {
    ADD_PRODUCT_TO_QUOTATION_REQUEST,
    ADD_PRODUCT_TO_QUOTATION_SUCCESS,
    ADD_PRODUCT_TO_QUOTATION_FAILURE,
    REMOVE_PRODUCT_FROM_QUOTATION_REQUEST,
    REMOVE_PRODUCT_FROM_QUOTATION_SUCCESS,
    REMOVE_PRODUCT_FROM_QUOTATION_FAILURE,
    INCREMENET_PRODUCT_COUNT_QUOTATION_REQUEST,
    INCREMENET_PRODUCT_COUNT_QUOTATION_SUCCESS,
    INCREMENET_PRODUCT_COUNT_QUOTATION_FAILURE,
    DECREMENT_PRODUCT_COUNT_QUOTATION_REQUEST,
    DECREMENT_PRODUCT_COUNT_QUOTATION_SUCCESS,
    DECREMENT_PRODUCT_COUNT_QUOTATION_FAILURE,
    GET_QUOTATION_LIST_REQUEST,
    GET_QUOTATION_LIST_SUCCESS,
    GET_QUOTATION_LIST_FAILURE,
    GET_QUOTATIONPRICE_REQUEST,
    GET_QUOTATIONPRICE_SUCCESS,
    GET_QUOTATIONPRICE_FAILURE
}
    from '../../type'

import axiosInstance from '../../../api/axiosInstance';
import { ADD_TO_QUOTATION_URL, GET_QUOTATION_LIST_URL, GET_QUOTATION_PRICE_URL } from '../../../api/urls'


// Add items to my quotation list

export const addItemToQuotationList = (product) => {


    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(addProductToQuotationListRequest())
            try {
                axiosInstance
                    .post(ADD_TO_QUOTATION_URL, product)
                    .then((response) => {
                        let res = JSON.parse(response.data);

                        if (response.status === 200) {
                            dispatch(addProductToQuotationListSuccess(res));
                            resolve();
                        } else {
                            dispatch(addProductToQuotationListFailure(res.message));
                            reject()
                        }
                    })
                    .catch((error) => {

                        dispatch(addProductToQuotationListFailure(error.message));
                    });
            } catch (e) { }


        });

}



export const getQuotationList = () => {


    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(getQuotationProductListRequest())
            try {
                axiosInstance
                    .post(GET_QUOTATION_LIST_URL)
                    .then((response) => {
                        let res = JSON.parse(response.data);

                        if (response.status === 200) {
                            dispatch(getQuotationProductListSuccess(res));
                            resolve();
                        } else {
                            dispatch(getQuotationProductListFailure(res.message));
                            reject()
                        }
                    })
                    .catch((error) => {

                        dispatch(getQuotationProductListFailure(error.message));
                    });
            } catch (e) { }


        });








}

export const getQuotationPrice = (requestObj) => {

    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(getQuotationPriceRequest())
            try {
                axiosInstance
                    .post(GET_QUOTATION_PRICE_URL, requestObj)
                    .then((response) => {
                        let res = JSON.parse(response.data);

                        if (response.status === 200) {
                            dispatch(getQuotationPriceSuccess(res.result));
                            resolve();
                        } else {
                            dispatch(getQuotationPriceFailure(res.message));
                            reject()
                        }
                    })
                    .catch((error) => {

                        dispatch(getQuotationPriceFailure(error.message));
                    });
            } catch (e) { }


        });
};






export const removeProductFromQuotationList = (productId) => {
    return (dispatch) => {
        dispatch(removeProductFromQuotationRequest())
        // Api call
        const quotationProductList = "" // product list from quotation list
        dispatch(removeProductFromQuotationSuccess(quotationProductList))
    }
}

export const incrementProductCountQuotationList = () => {
    return (dispatch) => {
        dispatch(incrementProductCountQuotationRequest())
        // Api call
        const productList = ""  //new product list
        dispatch(incrementProductCountQuotationSuccess(productList))

    }
}

export const decrementProductCountQuotationList = () => {
    return (dispatch) => {
        dispatch(decrementProductCountQuotationRequest())
        // Api call
        const productList = ""  //new product list
        dispatch(decrementProductCountQuotationSuccess(productList))

    }
}




const getQuotationProductListRequest = () => {
    return {
        type: GET_QUOTATION_LIST_REQUEST
    }
}

const getQuotationProductListSuccess = (productList) => {
    return {
        type: GET_QUOTATION_LIST_SUCCESS,
        payload: productList
    }
}


const getQuotationProductListFailure = (error) => {
    return {
        type: GET_QUOTATION_LIST_FAILURE,
        payload: error
    }
}




const addProductToQuotationListRequest = () => {
    return {
        type: ADD_PRODUCT_TO_QUOTATION_REQUEST
    }
}

const addProductToQuotationListSuccess = (data) => {
    return {
        type: ADD_PRODUCT_TO_QUOTATION_SUCCESS,
        payload: data
    }
}

const addProductToQuotationListFailure = (error) => {
    return {
        type: ADD_PRODUCT_TO_QUOTATION_FAILURE,
        payload: error
    }
}


const removeProductFromQuotationRequest = () => {
    return {
        type: REMOVE_PRODUCT_FROM_QUOTATION_REQUEST
    }
}

const removeProductFromQuotationSuccess = (data) => {
    return {
        type: REMOVE_PRODUCT_FROM_QUOTATION_SUCCESS,
        payload: data
    }
}

// eslint-disable-next-line
const removeProductFromQuotationFailure = (error) => {
    return {
        type: REMOVE_PRODUCT_FROM_QUOTATION_FAILURE,
        payload: error
    }
}

const incrementProductCountQuotationRequest = () => {
    return {
        type: INCREMENET_PRODUCT_COUNT_QUOTATION_REQUEST
    }
}

const incrementProductCountQuotationSuccess = (data) => {
    return {
        type: INCREMENET_PRODUCT_COUNT_QUOTATION_SUCCESS,
        payload: data
    }
}

// eslint-disable-next-line
const incrementProductCountQuotationFailure = (error) => {
    return {
        type: INCREMENET_PRODUCT_COUNT_QUOTATION_FAILURE,
        payload: error
    }
}

const decrementProductCountQuotationRequest = () => {
    return {
        type: DECREMENT_PRODUCT_COUNT_QUOTATION_REQUEST
    }
}

const decrementProductCountQuotationSuccess = (data) => {
    return {
        type: DECREMENT_PRODUCT_COUNT_QUOTATION_SUCCESS,
        payload: data
    }
}

// eslint-disable-next-line
const decrementProductCountQuotationFailure = (error) => {
    return {
        type: DECREMENT_PRODUCT_COUNT_QUOTATION_FAILURE,
        payload: error
    }
}


const getQuotationPriceRequest = () => {
    return {
        type: GET_QUOTATIONPRICE_REQUEST
    }
}

const getQuotationPriceSuccess = (priceSummary) => {
    return {
        type: GET_QUOTATIONPRICE_SUCCESS,
        payload: priceSummary
    }
}

const getQuotationPriceFailure = (error) => {
    return {
        type: GET_QUOTATIONPRICE_FAILURE,
        payload: error
    }
}