import {
    FETCH_SUB_SUB_CATAGORYLIST_FAILURE,
    FETCH_SUB_SUB_CATAGORYLIST_SUCCESS,
    FETCH_SUB_SUB_CATAGORYLIST_REQUEST,
    
} from "../../type"
const initialState={
    isLoading:false,
    data: [],
    error: "",
    message: "",
    subsubCategoryByCategory: []
}


const SubSubCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SUB_SUB_CATAGORYLIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_SUB_SUB_CATAGORYLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                message: action.payload.message,
            };
        case FETCH_SUB_SUB_CATAGORYLIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };


        

        
         




        default:
            return state;
    }
};

export default SubSubCategoryReducer;