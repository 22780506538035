import {
    ADD_ADDRESS_REQUEST,
    ADD_ADDRESS_SUCCESS,
    ADD_ADDRESS_FAILURE,
    GET_SAVED_ADDRESS_REQUEST,
    GET_SAVED_ADDRESS_SUCCESS,
    GET_SAVED_ADDRESS_FAILURE,
    GET_ADDRESSBY_ID_REQUEST,
    GET_ADDRESSBY_ID_SUCCESS,
    GET_ADDRESSBY_ID_FAILURE,
    UPDATE_ADDRESS_REQUEST,
    UPDATE_ADDRESS_SUCCESS,
    UPDATE_ADDRESS_FAILURE,
    REMOVE_ADDRESS_REQUEST,
    REMOVE_ADDRESS_SUCCESS,
    REMOVE_ADDRESS_FAILURE
} from '../../type'




const initialState = {
    isLoading: false,
    address: [],
    error: '',
    message: '',
    addressDetails: {},
    success: {}


}
const AddressReducer = (state = initialState, action) => {
    switch (action.type) {

        case ADD_ADDRESS_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case ADD_ADDRESS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                address: action.payload,

            }

        case ADD_ADDRESS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case GET_SAVED_ADDRESS_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case GET_SAVED_ADDRESS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                address: action.payload,

            }

        case GET_SAVED_ADDRESS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case GET_ADDRESSBY_ID_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case GET_ADDRESSBY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                addressDetails: action.payload,

            }

        case GET_ADDRESSBY_ID_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case UPDATE_ADDRESS_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case UPDATE_ADDRESS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                address: action.payload,

            }

        case UPDATE_ADDRESS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case REMOVE_ADDRESS_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case REMOVE_ADDRESS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: action.payload,

            }

        case REMOVE_ADDRESS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        default:
            return state
    }

}

export default AddressReducer