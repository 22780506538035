import React from "react";

const ReviewPayAccountAddress = ({userAddress, name, address, contact }) => {
  const {
    firstName,
    lastName,
    area,
    city,
    state,
    country,
    pincode,
    phoneNumber,
  } = userAddress;
  return (
    <div className="card card-review-pay border-0 shadow mb-3">
      <div className="card-header bg-white d-flex align-items-center justify-content-between">
        <div className="fw-medium">Address Details</div>
        {/* <div className="">
          <button className="btn btn-sm text-light-gray text-uppercase text-underline">
            Edit Details
          </button>
        </div> */}
      </div>
      <div className="card-body">
        <div className="top-right pe-3 me-1 mt-2">
          <span className="badge bg-light-success">Home</span>
        </div>
        <div className="mb-1 fw-medium text-uppercase">
          {`${firstName} ${lastName}`}
        </div>
        <div className="mb-1 text-light-gray">
          {`${area} ${city} ${state} ${country} - ${pincode}`}
        </div>
        <div className="mb-1 text-dark">{phoneNumber}</div>
      </div>
    </div>
  );
};

export default ReviewPayAccountAddress;
