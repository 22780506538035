// import axios from "axios"
import {
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_FAILURE
}
    from "../type"
    // eslint-disable-next-line
import { SIGNUP_URL, VERIFY_EMAIL_URL, VERIFY_OTP } from '../../api/urls'
import axiosInstance from '../../api/axiosInstance'
// eslint-disable-next-line
const testData = {
    game: 'ok'
}

export const signup = (data) => {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(signupRequest())

        try {
            axiosInstance
                .post(SIGNUP_URL, data)
                .then((response) => {
                    // console.log(response)
                    let res = JSON.parse(response.data);
                    if (response.status === 200) {

                        if (res.message === 'success') {

                            // localStorage.setItem('accessToken', res.userData.sessionId)
                            dispatch(signupSuccess(res))
                            resolve()
                        }
                        else {
                            dispatch(signupFailure(res.message))
                            reject()
                        }
                        //  Promise.resolve(dispatch(signupSuccess(res)))



                    } else {
                        reject()
                        dispatch(signupFailure(res.message))
                    }
                })
                .catch((error) => {
                    // dispatch(isError(error.message));
                    // dispatch(isLoading(false));
                    dispatch(signupFailure(error.message))
                    console.log(error)
                })
                .then(() => {
                    console.log("-----always executes");
                });
        } catch (e) { }



    })
}

export const verifyEmail = (data) => {
    return (dispatch) => {
        // try {
        //     axiosInstance
        //       .post(VERIFY_EMAIL_URL)
        //       .then((response) => {
        //         let res = JSON.parse(response.data);
        //         if (res.statusCode === 200) {

        //         } else {
        //          
        //         }
        //       })
        //       .catch((error) => {
        //         dispatch(isError(error.message));
        //         dispatch(isLoading(false));
        //       })
        //       .then(() => {
        //         console.log("-----always executes");
        //       });
        //   } catch (e) {}
    }
}

export const verifyOTP = (data) => {
    return (dispatch) => {
        // try {
        //     axiosInstance
        //       .post(VERIFY_OTP)
        //       .then((response) => {
        //         let res = JSON.parse(response.data);
        //         if (res.statusCode === 200) {

        //         } else {
        //          
        //         }
        //       })
        //       .catch((error) => {
        //         dispatch(isError(error.message));
        //         dispatch(isLoading(false));
        //       })
        //       .then(() => {
        //         console.log("-----always executes");
        //       });
        //   } catch (e) {}
    }
}




// signup state functions

const signupRequest = () => {
    return {
        type: SIGNUP_REQUEST
    }
}

const signupSuccess = (response) => {
    return {
        type: SIGNUP_SUCCESS,
        payload: response
    }
}

const signupFailure = (error) => {
    return {
        type: SIGNUP_FAILURE,
        payload: error
    }
}
