import React from 'react'
import { Link } from "react-router-dom";
import '../../assets/css/product-custom.css'
const LargeCardComponent = ({ Image, productName, price, rating }) => {
    return (
        <div className="col-lg-12 col-md-12 col-sm-12 ms-4 my-2 products-box d-flex ps-0 mt-5 flex-wrap"
            style={{ boxShadow: '-12px 7px 22px -11px rgba(0,0,0,0.44)' }} >



            <div className="col-3 col-md-4 col-sm-5">
                <img src={Image} alt="pro-img" className="shadow" />

            </div>

            <div className="col-9 col-md-8 col-sm-7 container p-3" >
                <div className="container d-flex flex-column justify-content-between">
                    <div className=" d-flex justify-content-between">
                        <div>
                            <div className="pro-title-large">{productName}</div>
                            <div className="pro-price-large">{price}</div>
                            <div className="pro-rating-icon-large d-flex justify-content-between">
                                <ul className="d-flex list-unstyled">
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fas fa-star-half-alt"></i></li>
                                    <li className="px-2 text-light-gray">{rating}</li>
                                </ul>

                            </div>

                        </div>
                        <div className="heart-icon-large">
                            <i className="far fa-heart"></i>
                        </div>

                    </div>
                    <div className="d-flex justify-content-end mb-4 mx-3">

                        <Link to="/cart" className="btn btn-success">
                            Add to Cart
                        </Link>
                    </div>

                </div>


            </div>


        </div>
    )
}

export default LargeCardComponent
