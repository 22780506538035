import {
    ADD_PRODUCT_TO_QUOTATION_REQUEST,
    ADD_PRODUCT_TO_QUOTATION_SUCCESS,
    ADD_PRODUCT_TO_QUOTATION_FAILURE,
    REMOVE_PRODUCT_FROM_QUOTATION_REQUEST,
    REMOVE_PRODUCT_FROM_QUOTATION_SUCCESS,
    REMOVE_PRODUCT_FROM_QUOTATION_FAILURE,
    INCREMENET_PRODUCT_COUNT_QUOTATION_REQUEST,
    INCREMENET_PRODUCT_COUNT_QUOTATION_SUCCESS,
    INCREMENET_PRODUCT_COUNT_QUOTATION_FAILURE,
    DECREMENT_PRODUCT_COUNT_QUOTATION_REQUEST,
    DECREMENT_PRODUCT_COUNT_QUOTATION_SUCCESS,
    DECREMENT_PRODUCT_COUNT_QUOTATION_FAILURE,
    GET_QUOTATION_LIST_REQUEST,
    GET_QUOTATION_LIST_SUCCESS,
    GET_QUOTATION_LIST_FAILURE,
    GET_QUOTATIONPRICE_REQUEST,
    GET_QUOTATIONPRICE_SUCCESS,
    GET_QUOTATIONPRICE_FAILURE
}
    from '../../type'


const initialState = {
    isLoading: false,
    quotationProductList: [],
    error: '',
    message: '',
    quotationPrice: {}

}


const quotationReducer = (state = initialState, action) => {
    switch (action.type) {

        case ADD_PRODUCT_TO_QUOTATION_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case ADD_PRODUCT_TO_QUOTATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.payload
            }

        case ADD_PRODUCT_TO_QUOTATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case REMOVE_PRODUCT_FROM_QUOTATION_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case REMOVE_PRODUCT_FROM_QUOTATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                quotationProductList: action.payload,
                message: action.payload.message
            }

        case REMOVE_PRODUCT_FROM_QUOTATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case INCREMENET_PRODUCT_COUNT_QUOTATION_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case INCREMENET_PRODUCT_COUNT_QUOTATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                quotationProductList: action.payload,
                message: action.payload.message
            }

        case INCREMENET_PRODUCT_COUNT_QUOTATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case DECREMENT_PRODUCT_COUNT_QUOTATION_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case DECREMENT_PRODUCT_COUNT_QUOTATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                quotationProductList: action.payload,
                message: action.payload.message
            }

        case DECREMENT_PRODUCT_COUNT_QUOTATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case GET_QUOTATION_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case GET_QUOTATION_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                quotationProductList: action.payload,
                message: action.payload.message
            }

        case GET_QUOTATION_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case GET_QUOTATIONPRICE_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case GET_QUOTATIONPRICE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                quotationPrice: action.payload,

            }

        case GET_QUOTATIONPRICE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }

        default:
            return state
    }

}

export default quotationReducer