import React, { useEffect } from "react";
import FilterComponent from "../components/product/FilterComponent";
import { useState } from "react";
// import { productListBySubCategory } from '../store/actions/product/productListAction'
// eslint-disable-next-line
import { useDispatch, useSelector } from "react-redux";
import "../assets/css/pagination-custom.css";
import Loader from "../components/loader";
import { useLocation } from "react-router";

import ProductListComponent from "../components/product/ProductListComponent";
import Layout from "../layout/Layout";
import { fetchCategoryList } from "../store/actions/category/categoryAction";
import ShopByBrandFilter from "../components/homePage/ShopByBrandFilter";
import ShopBySubCategoryFilter from "../components/homePage/ShopBySubCategoryFilter";
import ShopBySubSubCategoryFilter from "../components/homePage/shopBySubSubCategoryFilter";
const ProductScreen = () => {
 
  const [showLargeComponent, setShowLargeComponent] = useState(false);
  const [showSmallComponent, setShowSmallComponent] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [priceSorter, setPriceSorter] = useState(1);
  
  const brandList = useSelector((state) => state.brands.brands);
  const products = useSelector((state) => state.products);
  const location = useLocation();
  const [selectedBrand,setSelectedBrand] = useState([])
   // const[selectedBrandFilter,setSelectedBrandFilter]=useState([])
  const[selectedCategory,setSelectedCategory]=useState([])
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const[selectedSubCategory,setSelectedSubCategory]=useState([])
  const[specificFilterList,setSpecificFilterList]=useState([])
  const[selectedSubSubCategory,setSelectedSubSubCategory]=useState([])
  const[selectedBrandFilter,setSelectedBrandFilter]=useState([])
  const[selectedSubCatFilter,setSelectedSubCatBrandFilter]=useState([])
  const[selectedHeaderValue,setSelectedHeaderValue]=useState([])
  const[selectedHeaderTitle,setSelectedHeaderTitle]=useState([])
  

  // eslint-disable-next-line
  const onClickHandler = () => {
    // console.log("clikcer"); 
    setShowSmallComponent(!showSmallComponent);
    setShowLargeComponent(!showLargeComponent);
  };

  // eslint-disable-next-line
  const onSortChange = (event) => {
    setPriceSorter(parseInt(event.target.value));
  };
  const query = new URLSearchParams(location.search);
  const subCategoryName = query.get("subcatname");
  const subCategory2Name=query.get("Sub2catname")
   console.log(" subCategory2Name===============",subCategory2Name)
  console.log(" subCategoryName===============",subCategoryName)
  console.log(" specificFilterList===============",specificFilterList)



  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Layout>
        <main className="page-wrapper">
          <div className="banner filter-all-products">
            <div className="container px-xxl-5 px-xl-4">
              <div className="row">
              <div className="col-12" >
                  {!subCategoryName && !subCategory2Name &&
                <>
                <ShopByBrandFilter   specificFilterList={specificFilterList} selectedBrandFilter={selectedBrandFilter} setSelectedBrandFilter={setSelectedBrandFilter}/> 
                <ShopBySubCategoryFilter specificFilterList={specificFilterList} selectedSubCatFilter={selectedSubCatFilter} setSelectedSubCatBrandFilter={setSelectedSubCatBrandFilter}/>
                </>
                } 
                {subCategoryName  && subCategory2Name===null &&
                <>
                 <ShopByBrandFilter   specificFilterList={specificFilterList} selectedBrandFilter={selectedBrandFilter} setSelectedBrandFilter={setSelectedBrandFilter}/> 
<ShopBySubSubCategoryFilter specificFilterList={specificFilterList} selectedSubSubCategory={selectedSubSubCategory} setSelectedSubSubCategory={setSelectedSubSubCategory}/>
                </>
                } 
                { subCategory2Name && subCategoryName===null && 
              
                                 <ShopByBrandFilter   specificFilterList={specificFilterList} selectedBrandFilter={selectedBrandFilter} setSelectedBrandFilter={setSelectedBrandFilter}/> 
}
               
               </div>
               
                <div className="col-xl-3">
                  
                  <FilterComponent
                 
                    productCount={products?.count}
                    brandList={brandList}
                    selectedBrand={selectedBrand}
                    setSelectedBrand={setSelectedBrand}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    priceRange={priceRange}
                     setPriceRange={setPriceRange}
                     setSelectedSubCategory={setSelectedSubCategory}
                     selectedSubCategory={selectedSubCategory}
                     setSelectedSubSubCategory={setSelectedSubSubCategory}
                     selectedSubSubCategory={selectedSubSubCategory}
                     specificFilterList={specificFilterList}
                     setSpecificFilterList={setSpecificFilterList}
                     selectedHeaderValue={selectedHeaderValue}
                     setSelectedHeaderValue={setSelectedHeaderValue}
                     selectedHeaderTitle={selectedHeaderTitle}
                     setSelectedHeaderTitle={setSelectedHeaderTitle}
                
                  />
                </div>
               
               
             
                <ProductListComponent selectedBrand={selectedBrand}
                    setSelectedBrand={setSelectedBrand}
                    priceRange={priceRange}
                    setSelectedSubCategory={setSelectedSubCategory}
                    selectedSubCategory={selectedSubCategory}
                    setSelectedSubSubCategory={setSelectedSubSubCategory}
                     selectedSubSubCategory={selectedSubSubCategory}
                     specificFilterList={specificFilterList}
                     setSpecificFilterList={setSpecificFilterList}
                     selectedBrandFilter={selectedBrandFilter}
                     setSelectedBrandFilter={setSelectedBrandFilter}
                     selectedSubCatFilter={selectedSubCatFilter} 
                     setSelectedSubCatBrandFilter={setSelectedSubCatBrandFilter}
                     selectedHeaderValue={selectedHeaderValue}
                     setSelectedHeaderValue={setSelectedHeaderValue}
                     selectedHeaderTitle={selectedHeaderTitle}
                     setSelectedHeaderTitle={setSelectedHeaderTitle}
                   
                    />
                   
                     
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};
export default ProductScreen;
