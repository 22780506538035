import React from 'react'
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
const RangleSliderComponent = ({ min, max, step, defaultValue, priceRange, setPriceRange, onPriceRangeChange }) => {
    // const [rangeValue, setRangeValue] = useState([10, 1080])

    // const onChange = (value) => {

    //     setPriceRange(value)
    // }


    return (
        <>
            <Range min={min} max={max} step={step} onChange={(value) => onPriceRangeChange(value)} defaultValue={defaultValue}
                railStyle={{ border: '1px solid #F3F3F3' }}
                handleStyle={[
                    {
                        position: "absolute",
                        transform: 'translate(-80%, -30%)',
                        bottom: -4,
                        width: "6px",
                        height: "20px",
                        cursor: "pointer",
                        borderRadius: "0",
                        border: "solid 2px #8DD883",
                        backgroundColor: "#fff"
                    },
                    {
                        position: "absolute",
                        transform: 'translate(-80%, -30%)',
                        bottom: -4,
                        width: "6px",
                        height: "20px",
                        cursor: "pointer",
                        borderRadius: "0",
                        border: "solid 2px #8DD883",
                        backgroundColor: "#fff"
                    }
                ]} trackStyle={[{ border: '2px solid #8DD883' }]} />
            <div className="mt-3">
                <span className="price-range text-light-gray"><span>RANGE:</span> ₹<span id="price-range-slider-text1">
                    {priceRange[0]}</span> - ₹<span id="price-range-slider-text2">{priceRange[1]}</span></span>
            </div>
        </>
    )
}

export default RangleSliderComponent
