// import * as types from "../../type"
import axiosInstance from "../../../api/axiosInstance";

export const getBanner = async (obj,SetTopBanner,SetLeftBanner,setRightBanner,setBottomBanner) => {
  try {
    const response = await axiosInstance.post("/banner/load-banner", obj);
    const res = JSON.parse(response?.data);
    const banners = res.data?.banners;
    // console.log("banners---------",banners)
     // banners.filter
    if(res.success){
        let topBannerList = banners.filter((item) => item.typeId === "top");
        let leftBannerList=banners.filter((item)=>item.typeId==="leftMiddle") 
        let rightBannerList=banners.filter((item)=>item.typeId==="rightMiddle")
        let bottomBannerList=banners.filter((item)=>item.typeId==="bottom")

        SetTopBanner(topBannerList)
        SetLeftBanner(leftBannerList)
        setRightBanner(rightBannerList)
        setBottomBanner(bottomBannerList)
    // console.log("topBannerList==>", topBannerList);
    }
    
  } catch (e) {
    console.log(e.message);
  }
};
