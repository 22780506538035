import React, { useEffect, useState } from "react";
import ItemDisplay from "../components/order/ItemDisplay";
import PriceSummary from "../components/order/PriceSummary";
import SelfTransport from "../components/order/SelfTransport";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCartAction,
  fetchCartActionV2,
  getCartPriceAction,
  removeCartItem,
  updateCartItemCount,
} from "../store/actions/cartAction";
import { bookmarkProductAction } from "../store/actions/product/productAction";
import Loader from "../components/loader";
import { placeOrderAction } from "../store/actions/order/orderAction";
import Swal from "sweetalert2";
import Layout from "../layout/Layout";
import { noImageFound } from "../components/reuse/notFound";

const CartScreen = () => {
  const loading = useSelector((state) => state.cart.isLoading);
  const placeOrderLoading = useSelector((state) => state.order.isLoading);
  const productActionLoading = useSelector(
    (state) => state.productDetails.isLoading
  );
  const cart = useSelector((state) => state.cart.cart);
  const [cartItems, setCartItems] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const cartDetails = useSelector((state) => state.cart.cartDetails);
  const [cartOrderSummary, setCartOrderSummary] = useState([]);

  const [isChecked, setIsChecked] = useState(false);
  // console.log("isSelfTransport",isChecked)

  // eslint-disable-next-line
  const Alert = (title, icon, path) => {
    Swal.fire({
      title: title,
      text: "",
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Continue",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
      } else {
      }
    });
  };

  useEffect(() => {
    setIsLoading(loading || productActionLoading || placeOrderLoading);
    setCartItems(cart);

    setCartOrderSummary(cartDetails);
  }, [cart, loading, cartDetails, productActionLoading, placeOrderLoading]);

  const removeItem = (product) => {
    const requestObj = {
      productId: product.productId,
      variantId: product.variantId,
    };
    dispatch(removeCartItem(requestObj));
  };

  const updateCartCount = (product, quantity) => {
    const requestObj = {
      productId: product.productId,
      variantId: product.variantId,
      quantity: quantity,
    };

    dispatch(updateCartItemCount(requestObj)).then(
      (resolve) => {
        dispatch(fetchCartActionV2());
        // dispatch(fetchCartAction());
        dispatch(
          getCartPriceAction({
            type: "cod",
            voucherCode: "",
          })
        );
      },
      (reject) => {
        dispatch(fetchCartAction());
      }
    );
  };

  // eslint-disable-next-line
  const placeOrder = () => {
    const requestObj = {
      type: "cod",
      voucherCode: "",
    };
    dispatch(placeOrderAction(requestObj));
  };

  const moveItemToWishList = (product) => {
    const requestObj = {
      productId: product.productId,
      variantId: product.variantId,
    };
    dispatch(bookmarkProductAction(requestObj)).then((resolve) =>
      dispatch(removeCartItem(requestObj)).then((resolve) =>
        dispatch(fetchCartAction())
      )
    );
  };

  useEffect(() => {
    dispatch(fetchCartAction());
    dispatch(
      getCartPriceAction({
        type: "cod",
        voucherCode: "",
      })
    );
  }, [dispatch]);

  const proceBlur = {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255,255,255, 0.5)",
    zIndex: 99,
    cursor: "pointer",
  };

  console.log("======cartItems======", cartItems);
  console.log("======cartOrderSummary======", cartOrderSummary);
  return (
    <Layout>
      {isLoading ? (
        <Loader />
      ) : cartItems.length === 0 ||
        (cartOrderSummary?.order &&
          cartOrderSummary.order.total === undefined) ? (
        <div className="text-center">
          <div className="">
            <img
              className="mx-auto"
              height="300"
              src="../images/Empty-Cart.jpeg"
              alt="cart-empty-img "
            />
            {/* Sorry nothing here, try adding some products to your cart first !! */}
          </div>
          <div className="col-md-3 py-4 mx-auto">
            <Link to="/" className="btn btn-success w-100">Continue Shopping</Link>
          </div>
        </div>
      ) : (
        <>
          <main className="page-wrapper">
            <div className="cart">
              <div className="container px-xxl-5 px-xl-4">
                <div className="h4 fw-medium pt-3">
                  Shopping Cart ({cartItems?.length})
                </div>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="cart-box my-4">
                      {cartItems?.length &&
                        cartItems.map((item, index) => (
                          <ItemDisplay
                            key={index}
                            product={item}
                            item={item}
                            title={item.title}
                            price={item.price}
                            imageUrl={
                              item.imageUrls?.length > 0
                                ? item.imageUrls
                                : noImageFound()
                            }
                            removeItem={removeItem}
                            updateCartCount={updateCartCount}
                            moveToWishList={moveItemToWishList}
                            getCartPriceAction={getCartPriceAction}
                          />
                        ))}
                    </div>
                    {/* <SelfTransport isChecked={isChecked}/> */}
                  </div>

                  <div className={`col-lg-4 pt-4 position-relative`}>
                    {isLoading && <div style={proceBlur}></div>}
                    <div className="card shadow border-0 cart-box py-4 px-2">
                      <PriceSummary
                        cartDetails={cartOrderSummary?.order}
                        completeCart={cartItems}
                      />
                      <div className="d-grid">
                        <Link
                          to="/shipping"
                          className="btn btn-light-success py-2 rounded-0"
                        >
                          Proceed to Checkout
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )
      }
      <div className="modal" id="self-transport-modal" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body px-4 pb-4">
              <div className="top-right">
                <button
                  type="button"
                  className="btn-close size-50px"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="my-3 text-center">
                <h6 className="fw-medium text-light-success my-3">
                  Opt For Self-Transport
                </h6>
                <p>
                  {isChecked
                    ? "Are you sure, You don't want transportation facilities."
                    : "Are you sure, You want to arrange your own transportation."}
                </p>
              </div>
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button
                    data-bs-dismiss="modal"
                    className="btn btn-light-gray px-3 rounded-0 bg-lighter-gray text-gray border-lighter-gray"
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-auto">
                  <Link
                    to="/add-address"
                    onClick={() => setIsChecked(!isChecked)}
                    data-bs-dismiss="modal"
                    className="btn btn-light-success px-3 rounded-0 bg-lighter-success text-success border-lighter-success"
                  >
                    Agree
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CartScreen;
