import React from 'react'

function ImageView(props) {

    return (
        <div className="card card-offer">
        <div className="card-body row align-items-center">
            <div className="col-xs-6 order-xs-2">
                <img src={props.image} alt="" className="img-fluid w-100" />
            </div>
            <div className="col-xs-6 order-xs-1">
                <p className="text-white mb-2">{props.tagLine}</p>
                <h3 className="text-white">{props.priceInfo}</h3>
                <div className="btn-holder my-3"><a href="/" className="btn btn-white">{props.btnText}</a></div>
            </div>
        </div>
    </div>
    )
}

export default ImageView
