import React from 'react';
import NumberFormat from 'react-number-format';

const AmountFormat = ({ value, ...rest }) => {
    return (
        <>
            <span style={{ fontSize: '1.1rem', marginLeft: '.3rem' }}>₹</span>
            <NumberFormat value={value} displayType={'text'} thousandSeparator={true} decimalScale={0}  {...rest} />
        </>
    )
}
export default AmountFormat