import {
    GET_FEATURED_PRODUCTLIST_REQUEST,
    GET_FEATURED_PRODUCTLIST_SUCCESS,
    GET_FEATURED_PRODUCTLIST_FAILURE,
    GET_FEATURED_PRODUCTS_CATEGORY_REQUEST,
    GET_FEATURED_PRODUCTS_CATEGORY_SUCCESS,
    GET_FEATURED_PRODUCTS_CATEGORY_FAILURE,

}

    from '../../type'



const initialState = {
    isLoading: true,
    products: [],
    error: '',
    message: '',
    featuredProductsCategory: []


}
const featuredProductListReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_FEATURED_PRODUCTLIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case GET_FEATURED_PRODUCTLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                products: action.payload,
                message: action.payload.message
            }

        case GET_FEATURED_PRODUCTLIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case GET_FEATURED_PRODUCTS_CATEGORY_REQUEST:
            return {
                ...state,
                isLoading: true,
                featuredProductsCategory: []
            }

        case GET_FEATURED_PRODUCTS_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                featuredProductsCategory: action.payload,
                message: action.payload.message
            }

        case GET_FEATURED_PRODUCTS_CATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }



        default:
            return state
    }

}

export default featuredProductListReducer