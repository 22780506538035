import React from 'react'
import Footer from '../components/homePage/Footer'
import Header from '../components/homePage/Header'
import CartBox from '../components/order/CartBox'
import { Link } from "react-router-dom";

const OrderConfirmScreen = () => {
    return (
        <div>
            <Header />
            <main className="page-wrapper">
        <div className="my-orders-title">
            <div className="container px-xxl-5 px-xl-4">
                <div className="row justify-content-between">
                    <div className="col-lg-6 align-self-lg-start">
                        <div className="h2 pt-5 fw-bold fs-3">Order Confirmed!</div>
                    </div>
                </div>
            </div>
        </div>

        <section className="order-details cart pt-3">
            <div className="container px-xxl-5 px-xl-4">
                <div className="row">
                    <div className="accordion accordion-flush my-3" id="accordionFlushExample">

                      <CartBox />
</div>
                    <div className="col-12 justify-content-center d-flex pt-4">
                        <Link  to="/" className="btn btn-light-success py-3 px-5">Continue Shopping</Link>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <Footer />
        </div>
    )
}

export default OrderConfirmScreen
