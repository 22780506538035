import React, { useEffect } from "react";
import { isAuth } from "../../utils/auth";
import {
  addToCartAction,
  removeCartItem,
} from "../../store/actions/cartAction";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import { useState } from "react";

const ProductCarouselCard = ({ product }) => {
  const cart = useSelector((state) => state.cart);
  const authed = isAuth();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [maxQuantity, setMaxQuantity] = useState([]);
  // console.log("pppppppppppppppp== 1111========",product)
  var quantityIntoApi=maxQuantity[maxQuantity.length-1]
  // var quantityIntoApi=200
  // console.log("ooooooooo",quantityIntoApi)


  useEffect(() => {
    let arr = [];
    product.variants.map((items) => {
      if (items) {
        arr.push(items?.name?.split("-")[1]);
      }
      setMaxQuantity(arr)
    });
  }, [product]);

  // const addToCart = () => {
   
    // var quantityIntoApi=maxQuantity[maxQuantity.length-1]
    // let arr = [];
    // product.variants.map((items) => {
    //   if (items) {
    //     arr.push(items?.name?.split("-")[1]);
    //   }
    //   setMaxQuantity(arr)
     
    // });
  //   console.log("outside",quantityIntoApi)
  //   if(quantityIntoApi){
  //     console.log("inside",quantityIntoApi)
  //     addToCartApi(quantityIntoApi)
     
    
  // };
  // }
  function addToCart(){
    const requestObj = {
      productId: product.productId,
      variantId: product?.variants?.length
        ? product?.variants[0].variantId
        : null,
      quantity:quantityIntoApi,
    };

    // console.log("Click", requestObj, authed);

    if (authed && product?.variants?.length > 0) {
      if (cart?.cartIds?.includes(product.productId)) {
        dispatch(removeCartItem(requestObj)).then((result) => {
          addToast("Prodcut Removed in Cart", {
            appearance: "error",
          });
        });
      } else {
        dispatch(addToCartAction(requestObj)).then((result) => {
          addToast("Prodcut Added to Cart", {
            appearance: "success",
          });
        });
      }
    } else {
      addToast("Please Login", { appearance: "error" });
    }
  }
  function getDiscount() {
    let dis =
      product?.variants?.length > 0 &&
      ((product?.variants?.[0].mrp - product?.variants?.[0].price) * 100) /
        product?.variants?.[0].mrp;
    return !isNaN(dis) ? dis : 0;
  }

  useEffect(() => {}, [cart]);

  return (
    <div className="py-1 w-100 px-2">
      <div className="mx-2 w-100">
        <div className="card border p-0 card-shadow w-100">
          <div className="btn btn-success rounded m-0 text-white fw-bold w-25 cost-font p-1 m-1">
            <span className="img-text">{product?.discount}% OFF</span>
          </div>
          <div>
            <Link
              to={`/product-details?product=${product?.productId}&category=${product.category?.categoryid}&catname=${product?.category.id?.categoryname}&subcategory=${product?.subCategory?.categoryid}&subcatname=${product?.subCategory?.id?.categoryname}}`}
            >
              <img
                src={product?.imageUrls?.[0]?.url}
                className="card-img-top  img-fluid shadow-sm object-fit-cover mx-auto pb-o"
                alt="..."
                style={{ width: "230px" }}
              />
            </Link>
          </div>
          <div className="card-body pt-1">
            <div className="card-text">
              <p className="para-font fw-bold mb-1 text-truncate">
                Ref No.:{product.productCode}
                <span className="text-truncate2">
                  {" "}
                  Brand Name: {product?.brand?.id?.name}
                </span>
              </p>
              <div className="text-truncate2 fw-bold mb-1 mt-0 text-truncate" style={{
                fontSize: "large"
              }}> {product.title}</div>
            </div>
            <div className="col">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row pb-0 pt-0  ">
                              <div class="d-flex bd-highlight">
                                <div class=" flex-grow-1    ">
                                  <b>Price :-</b>
                                  <span>
                                    ₹
                                    {product.price -
                                      (product.price * product?.discount) / 100}
                                  </span>
                                </div>
                                <div class=" bd-highlight ">
                                  <b>MRP:- </b>{" "}
                                  <span className="para-font">
                                    <del>₹{product.price}</del>
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-12 mt-3">
                                <div
                                  onClick={() => addToCart(product)}
                                  className="btn btn-success rounded-0 m-0 w-100 text-white p-1"
                                >
                                  {cart?.cartIds?.includes(product.productId)
                                    ? "Already in Cart"
                                    : " Add to Cart"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="card shadow mb-3 text-center">
    //   <div className="card-body bg-light">
    //     <div className="d-flex justify-content-end">
    //       {getDiscount() > 0 && (
    //         <span className="badge bg-success py-1">-{getDiscount()}%</span>
    //       )}
    //     </div>
    //     <div className="card-img mt-3">
    //       <img
    //         className="img-fluid w-100"
    //         src={product?.imageUrls?.length > 0 && product?.imageUrls[0].url}
    //         style={{ height: "16rem" }}
    //         alt=""
    //       />
    //     </div>
    //     <div className="d-flex justify-content-between">
    //       <span className="text-dark">{product?.title}</span>{" "}
    //       <span>₹{product?.price}</span>
    //     </div>
    //     <div
    //       onClick={addToCart}
    //       className="d-grid px-3 btn btn-outline-success rounded-pill my-3"
    //     >
    //       {cart?.cartIds?.includes(product.productId)
    //         ? "Already in Cart"
    //         : " Add to Cart"}
    //     </div>
    //   </div>
    // </div>
    // <div className="">
    //   {/* {console.log("product in berst deal======",product)} */}

    //   <div className="py-1 w-100 px-2">
    //     {/* {product?.length > 0 &&
    //          product.map((product, index) => ( */}

    //     {/*
    //           <div  className="m-1 py-1 ">

    //           <div className="card-img mt-3   ">
    //            <div className="card border p-0 card-shadow  ">
    //              <div className="btn btn-success rounded m-0 text-white fw-bold w-25 cost-font p-1 m-1">
    //                <span className="img-text">{product?.discount}% OFF</span>
    //              </div>
    //              <div>
    //                <img

    //                  src={product?.imageUrls?.[0]?.url}
    //                  className="card-img-top  img-fluid shadow-sm object-fit-cover"
    //                  alt="..."
    //                  style={{ width: "236px" }}
    //                />
    //              </div>
    //              <div className="card-body">
    //                <div className="card-text mx-3 ">
    //                  <p className="para-font fw-bold">
    //                    Ref No.:{product.productCode}
    //                    <br />
    //                    Brand Name: {product?.brand?.id?.name}
    //                  </p>
    //                  <div className="text-truncate2">
    //                    {" "}

    //                    {product.title}
    //                  </div>
    //                </div>
    //                <div className="col">
    //                  <div className="row">
    //                    <div className="col-md-12">
    //                      <div className="row pb-0 pt-0 p-1 py-2">
    //                        <div className="col-md-7 fs-0 pt-4">
    //                          <p>
    //                            <b>₹{((product.price)-((product.price)*(product?.discount))/100)}</b>
    //                            <div className="p-0">
    //                              <span className="cost-font">Each</span>
    //                            </div>
    //                          </p>
    //                        </div>
    //                        <br />
    //                        <div className="col-md-5 fs-0 pt-4">
    //                          <p>
    //                            <span className="para-font">
    //                              <del>₹{product.price}</del>
    //                            </span>
    //                          </p>
    //                        </div>

    //                        <div className="col-md-12 ">
    //                        <div
    //        onClick={addToCart}
    //        className="btn btn-success rounded-0 m-0 w-100 text-white p-1"

    //       //  className="d-grid px-3 btn btn-outline-success rounded-pill my-3"
    //      >
    //        {cart?.cartIds?.includes(product.productId)
    //          ? "Already in Cart"
    //        : " Add to Cart"}
    //    </div>
    //                        </div>
    //                      </div>
    //                    </div>
    //                  </div>
    //                </div>
    //              </div>
    //            </div>

    //          </div>
    //        </div> */}

    //   </div>
    // </div>
  );
};

export default ProductCarouselCard;
