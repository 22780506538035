import {
    GET_USER_DETAILS_REQUEST,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILURE,
    UPDATE_PROFILE_IMAGE_REQUEST,
    UPDATE_PROFILE_IMAGE_SUCCESS,
    UPDATE_PROFILE_IMAGE_FAILURE,
    UPDATE_PROFILE_INFO_REQUEST,
    UPDATE_PROFILE_INFO_SUCCESS,
    UPDATE_PROFILE_INFO_FAILURE,
    UPDATE_USER_EMAIL_REQUEST,
    UPDATE_USER_EMAIL_SUCCESS,
    UPDATE_USER_EMAIL_FAILURE,
} from "../../type";
const initialState = {
    isLoading: false,
    error: "",
    message: "",
    user: {},
    success: {}
};

const UserDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: action.payload,
                message: action.payload.message,
            };
        case GET_USER_DETAILS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case UPDATE_PROFILE_IMAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case UPDATE_PROFILE_IMAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: action.payload,
                message: action.payload.message,
            };
        case UPDATE_PROFILE_IMAGE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case UPDATE_USER_EMAIL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case UPDATE_USER_EMAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: action.payload,
                message: action.payload.message,
            };
        case UPDATE_USER_EMAIL_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case UPDATE_PROFILE_INFO_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case UPDATE_PROFILE_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: action.payload,
                message: action.payload.message,
            };
        case UPDATE_PROFILE_INFO_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };



        default:
            return state;
    }
};

export default UserDetailsReducer;
