import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import CardComponent from "./cardComponent";
import {
  productListBySubCategory,
  productListBySubCategoryWithoutLoader,
} from "../../store/actions/product/productListAction";
import Loader from "../../components/loader";
import { Link } from "react-router-dom";
import SortLowToHigh from "./sortLowToHigh";
import ReactPaginate from "react-paginate";
import LargeCardComponent from "./LargeCardComponent";
import { isAuth, isUser } from "../../utils/auth";
import { bookmarkProductAction } from "../../store/actions/product/productAction";
import { useToasts } from "react-toast-notifications";

const COUNT_PER_PAGE = 9;

const BrandProductListComponent = ({selectedBrand,priceRange,specificCatFilter, specificFilterList,setSpecificFilterList, selectedCategory,}) => {
  const authed = isAuth();
  const dispatch = useDispatch();
  // console.log("------23----",specificCatFilter)
  const location = useLocation();
  const loading = useSelector((state) => state.products.isLoading);
  const [isLoading, setIsLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const products = useSelector((state) => state.products.products);
  const [searchQuery, setSearchQuery] = useState(location.search);
  const query = new URLSearchParams(location.search);
  // const categoryId = query.get('category')
  const categoryName = query.get("catname");
  // const subCategoryId = query.get('subcategory')
  const subCategoryName = query.get("subcatname");
  let brandId = query.get("brand");
  const brandName = query.get("brandName");
  const priceStart = query.get("priceStart");
  const priceLimit = query.get("priceLimit");
  const [priceSorter, setPriceSorter] = useState(1);
  const [showLargeComponent, setShowLargeComponent] = useState(false);
  const [showSmallComponent, setShowSmallComponent] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const totalRecords = useSelector((state) => state.products.count);
  const { addToast } = useToasts();
  const onClickHandler = () => {
    setShowSmallComponent(!showSmallComponent);
    setShowLargeComponent(!showLargeComponent);
  };
const[allProList,setAllProList]=useState([])
   
  var newarr=[];
 
  // useEffect(()=>(
   
   
  // ),[specificFilterList])
  //
   
  useEffect(()=>{
    if(specificFilterList){
      var arr =[]
      specificFilterList.map((data) =>(

         arr.push(data.category?.categoryid  )
      ))
setAllProList(arr)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificFilterList])

  useEffect(() => {

    setIsLoading(loading);
    setProductList(products);
    setTotalPages(Math.ceil(totalRecords / COUNT_PER_PAGE));
    setSearchQuery(location.search);
  }, [loading, products, totalRecords, productList, searchQuery, location]);

  let requestObj = {};

  // if (priceRange  && selectedCategory ) {

    // -------------------------
    // requestObj = {
    //   count: pageNumber * COUNT_PER_PAGE,
    //   limit: COUNT_PER_PAGE,
    //   field: "max",
    //   filters: {
    //     "brand.brandid": selectedBrand.length>0?selectedBrand:brandId,
    //     ...(priceRange &&
    //       {
    //        range: { price: { min: priceRange[0], max: priceRange[1] } },
    //      }),
    //      "category.categoryid":selectedCategory.length>0?selectedCategory:""         
    //   },
    //   sortBy: {
    //     type: "price",
    //     order: priceSorter,
    //   },
    // };
  // }
 
  
  requestObj={
    count: pageNumber * COUNT_PER_PAGE,
    limit: COUNT_PER_PAGE,
    field: "max",
    
    
   
      brand: selectedBrand.length>0?selectedBrand:[brandId],
      
      // category:selectedCategory.length>0?selectedCategory:""  ,
      // category:newarr,
      
      
      ...(allProList && allProList.length>0 &&  {category:allProList}),
      ...(specificCatFilter.length===0?null:{category:specificCatFilter}),
      ...(selectedCategory.length===0?null:{category:selectedCategory}),
      
      // "subCategory": ["ueLzdc65HY1FSnhfQTm1ywzZBT6T4aqf"],
      // "sub2Category": ["2BJ7ZhicpJ5AAvncjU1VLQZKZKOVNWGX"],
      price: {
          "min":priceRange[0],
          "max": priceRange[1]
      
  },
  sortBy: {
        type: "price",
        order: priceSorter,
      },
  }
  // -----------------------------------
  //  else {
  //   requestObj = {
  //     count: pageNumber * COUNT_PER_PAGE,
  //     limit: COUNT_PER_PAGE,
  //     field: "max",
  //     filters: {
  //       "brand.brandid": selectedBrand.length>0?selectedBrand:brandId,
       
  //     },

  //     sortBy: {
  //       type: "price",
  //       order: priceSorter,
  //     },
  //   };
  // }

  const onSortChange = (event) => {
    setPriceSorter(parseInt(event.target.value));
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    dispatch(productListBySubCategory(requestObj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber, priceSorter, searchQuery,selectedBrand,selectedCategory,specificCatFilter,priceRange ,allProList]);

  return isLoading ? (
    <Loader />
  ) : (


    <div className="col-xl-9 filter-pro-right">
  
      <div className="row">
        <div className="col-lg-6 align-items-center">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              {categoryName &&
              categoryName.length &&
              categoryName !== "null" ? (
                <li className="breadcrumb-item text-capitalize">
                  <Link to="">{categoryName}</Link>
                </li>
              ) : null}

              {subCategoryName &&
              subCategoryName.length > 0 &&
              subCategoryName !== "null" ? (
                <li
                  className="breadcrumb-item active text-capitalize"
                  aria-current="page"
                >
                  {subCategoryName}
                </li>
              ) : null}

              {brandName && brandName.length ? (
                <li
                  className="breadcrumb-item active text-capitalize"
                  aria-current="page"
                >
                  {brandName}
                </li>
              ) : null}
            </ol>
          </nav>
        </div>

        <div className="col-lg-6 justify-content-end d-flex align-self-center">
          <SortLowToHigh
            setShowSmallComponent={setShowSmallComponent}
            setShowLargeComponent={setShowLargeComponent}
            showLargeComponent={showLargeComponent}
            showSmallComponent={showSmallComponent}
            onClickHandler={onClickHandler}
            onSortChange={onSortChange}
            priceSorterValue={priceSorter}
          />
        </div>
      </div>
     
 {/* { specificFilterList.map((Id)=>(
       setAllProList
       setAllProList( newarr.push(Id.category?.categoryid))
    ))} */}
  
      <div
        className="row"
        style={{ display: showSmallComponent ? true : "none" }}
      >
        {productList.length === 0 ? (
          <div
            className="text-info mt-5 text-center"
            style={{ fontSize: "1rem" }}
          >
            Sorry, No products found Under your search , try changing your
            Search Query
          </div>
        ) : (
          productList.map((product, index) => {
            function isWishlist() {
              const user = isUser();
              return authed
                ? product?.bookmarkedBy?.includes(user?.userId)
                : authed;
            }

            function addToWishlist() {
              const obj = {
                productId: product.productId,
                variantId: product.variants[0].variantId,
              };
              if (authed) {
                dispatch(bookmarkProductAction(obj)).then((result) => {
                   if (result.message === "bookmarked") {
                    addToast("Prodcut Added in Save for Later", {
                      appearance: "success",
                    });
                  } else {
                    addToast("Prodcut Removed from Save for Later", {
                      appearance: "error",
                    });
                  }
                  dispatch(productListBySubCategoryWithoutLoader(requestObj));
                });
              } else {
                addToast("Please Login", { appearance: "error" });
              }
            }
            return (
              <CardComponent
                key={index}
                Image={
                  product.imageUrls?.length > 0
                    ? product.imageUrls[0].url
                    : "https://content.optimumnutrition.com/i/on/on-on-naturally-flavored-100-whey_Image_01?locale=en-us,en-gb,*&layer0=$PDP_003$"
                }
                productName={product.title}
                price={product.variants[0]?.price}
                rating={4.2}
                product={product}
                isWishlist={isWishlist}
                addToWishlist={addToWishlist}
              />
            );
          })
        )}
      </div>
       {productList.length > 30 ? (
        <ReactPaginate
          onPageChange={changePage}
          pageCount={totalPages}
          containerClassName={"paginationButtons"}
          activeClassName={"paginationActive"}
          forcePage={pageNumber}
        />
      ) : null}

      {showLargeComponent ? (
        <div style={{ display: showLargeComponent ? true : "none" }}>
          {productList.map((product) => (
            <LargeCardComponent
              Image={
                product.imageUrls &&
                product.imageUrls[0] &&
                product.imageUrls[0].url
              }
              productName={product.title}
              price={product.price}
              rating={4.2}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default BrandProductListComponent;
