import React from 'react'

const Benefits = () => {
    return (
        <div className="container">
        <div className="row">
            <div className="col-md-11 col-lg-10 col-xl-9 mx-auto col-12">
                <img src="images/benifits.png" alt="" className="img-fluid w-100" />
            </div>
        </div>
    </div>
    )
}

export default Benefits
