import React, { useEffect, useState } from "react";
import ReactStars from "react-stars";
import axiosInstance from "../../api/axiosInstance";
import moment from 'moment'

const Reviews = ({ product }) => {
  const [reviewList, setReviewList] = useState([]);
  const loadReviews = async () => {
    try {
      const response = await axiosInstance.get(
        `/review/product/${product._id}?page=1&limit=10`
      );
      const result = JSON.parse(response.data);
      // console.log("result", result);
      if (result.data.code === 200) {
        setReviewList(result.data.data);
        
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // console.log("reviewList=>", reviewList);

  useEffect(() => {
    loadReviews();
  }, []);
  return (
    <>
      <div className="col-md-6 my-3">
        <h5 className="fw-bold">Customer reviews</h5>
        <div className="card card-rating border-0 col-lg-6">
          <div className="card-body p-0">
            <div>
              <div className="mb-2">
                <span className="badge bg-success px-2 py-1">
                  <span className="">4.2</span>
                  <i className="fas fa-star mr-1"></i>
                </span>
              </div>
              <p className="text-light-gray">96 Ratings & 7 Reviews</p>
            </div>
            <div className="d-flex progress-bars align-items-center">
              <div className="col-auto px-0">
                <span className="d-flex align-items-center me-2">
                  <span className="text-dark size-20px">5</span>
                  <span>Star</span>
                </span>
              </div>
              <div className="col px-0">
                <div className="progress rounded-0">
                  <div
                    className="progress-bar bg-light-success rounded-0"
                    role="progressbar"
                    style={{ width: "85%" }}
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div className="d-flex progress-bars align-items-center">
              <div className="col-auto px-0">
                <span className="d-flex align-items-center me-2">
                  <span className="text-dark size-20px">4</span>
                  <span>Star</span>
                </span>
              </div>
              <div className="col px-0">
                <div className="progress rounded-0">
                  <div
                    className="progress-bar bg-light-success rounded-0"
                    role="progressbar"
                    style={{ width: "60%" }}
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div className="d-flex progress-bars align-items-center">
              <div className="col-auto px-0">
                <span className="d-flex align-items-center me-2">
                  <span className="text-dark size-20px">3</span>
                  <span>Star</span>
                </span>
              </div>
              <div className="col px-0">
                <div className="progress rounded-0">
                  <div
                    className="progress-bar bg-light-success rounded-0"
                    role="progressbar"
                    style={{ width: "50%" }}
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div className="d-flex progress-bars align-items-center">
              <div className="col-auto px-0">
                <span className="d-flex align-items-center me-2">
                  <span className="text-dark size-20px">2</span>
                  <span>Star</span>
                </span>
              </div>
              <div className="col px-0">
                <div className="progress rounded-0">
                  <div
                    className="progress-bar bg-light-success rounded-0"
                    role="progressbar"
                    style={{ width: "30%" }}
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div className="d-flex progress-bars align-items-center">
              <div className="col-auto px-0">
                <span className="d-flex align-items-center me-2">
                  <span className="text-dark size-20px">1</span>
                  <span>Star</span>
                </span>
              </div>
              <div className="col px-0">
                <div className="progress rounded-0">
                  <div
                    className="progress-bar bg-light-success rounded-0"
                    role="progressbar"
                    style={{ width: "10%" }}
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="mt-3">
          <button className="btn btn-light bg-white border">
            Write a review
          </button>
        </div> */}
        <div className="reviews-content pt-3">
          {reviewList.map((review, index) => (
            <div className="reviews-card py-4" key={index}>
              <div className="text-light-success mb-2">
                <ReactStars
                  count={5}
                  value={review?.rating}
                  edit={false}
                  size={24}
                  isHalf={false}
                  emptyIcon={<i className="far fa-star"></i>}
                  // halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor="#FFD700"
                />
              </div>
              <div className="card-title fw-medium">{review?.reviewHeading}</div>
              <div className="card-text">
                {review?.reviewContent}
              </div>
              <div className="d-flex align-items-center mt-3">
                <div className="user sprite-icon size-50px me-1">
                  <img
                    className="img-fluid rounded-circle w-100"
                    src="images/icons/user.jpg"
                    alt=""
                  />
                </div>
                <div className="text-light-gray">
                  <div className="mb-1">Gaurav Kumar</div>
                  <div>{moment(review?.createdTime).format('DD MMMM YYYY')}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="col-md-6 my-3">
        <h5 className="fw-bold">Service reviews</h5>
        <div className="card card-rating border-0 col-lg-6">
          <div className="card-body p-0">
            <div>
              <div className="mb-2">
                <span className="badge bg-success px-2 py-1">
                  <span className="">4.2</span>
                  <i className="fas fa-star mr-1"></i>
                </span>
              </div>
              <p className="text-light-gray">96 Ratings & 7 Reviews</p>
            </div>
            <div className="d-flex progress-bars align-items-center">
              <div className="col-auto px-0">
                <span className="d-flex align-items-center me-2">
                  <span className="text-dark size-20px">5</span>
                  <span>Star</span>
                </span>
              </div>
              <div className="col px-0">
                <div className="progress rounded-0">
                  <div
                    className="progress-bar bg-light-success rounded-0"
                    role="progressbar"
                    style={{width: "85%"}}
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div className="d-flex progress-bars align-items-center">
              <div className="col-auto px-0">
                <span className="d-flex align-items-center me-2">
                  <span className="text-dark size-20px">4</span>
                  <span>Star</span>
                </span>
              </div>
              <div className="col px-0">
                <div className="progress rounded-0">
                  <div
                    className="progress-bar bg-light-success rounded-0"
                    role="progressbar"
                    style={{width: "60%"}}
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div className="d-flex progress-bars align-items-center">
              <div className="col-auto px-0">
                <span className="d-flex align-items-center me-2">
                  <span className="text-dark size-20px">3</span>
                  <span>Star</span>
                </span>
              </div>
              <div className="col px-0">
                <div className="progress rounded-0">
                  <div
                    className="progress-bar bg-light-success rounded-0"
                    role="progressbar"
                    style={{width: "50%"}}
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div className="d-flex progress-bars align-items-center">
              <div className="col-auto px-0">
                <span className="d-flex align-items-center me-2">
                  <span className="text-dark size-20px">2</span>
                  <span>Star</span>
                </span>
              </div>
              <div className="col px-0">
                <div className="progress rounded-0">
                  <div
                    className="progress-bar bg-light-success rounded-0"
                    role="progressbar"
                    style={{width: "30%"}}
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div className="d-flex progress-bars align-items-center">
              <div className="col-auto px-0">
                <span className="d-flex align-items-center me-2">
                  <span className="text-dark size-20px">1</span>
                  <span>Star</span>
                </span>
              </div>
              <div className="col px-0">
                <div className="progress rounded-0">
                  <div
                    className="progress-bar bg-light-success rounded-0"
                    role="progressbar"
                    style={{width: "10%"}}
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <button className="btn btn-light bg-white border">
            Write a review
          </button>
        </div>
        <div className="reviews-content pt-3">
          <div className="reviews-card py-4">
            <div className="text-light-success mb-2">
              <span className="mr-1 text-warning">
                <i className="fas fa-star"></i>
              </span>
              <span className="mr-1 text-warning">
                <i className="fas fa-star"></i>
              </span>
              <span className="mr-1 text-warning">
                <i className="fas fa-star"></i>
              </span>
              <span className="mr-1 text-warning">
                <i className="fas fa-star"></i>
              </span>
              <span className="mr-1 text-warning">
                <i className="fas fa-star"></i>
              </span>
            </div>
            <div className="card-title fw-medium">Best Quality</div>
            <div className="card-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et
            </div>
            <div className="d-flex align-items-center mt-3">
              <div className="user sprite-icon size-50px me-1">
                <img
                  className="img-fluid rounded-circle w-100"
                  src="images/icons/user.jpg"
                  alt=""
                />
              </div>
              <div className="text-light-gray">
                <div className="mb-1">Gaurav Kumar</div>
                <div>23 April 2021</div>
              </div>
            </div>
          </div>
          <div className="reviews-card py-4">
            <div className="text-light-success mb-2">
              <span className="mr-1 text-warning">
                <i className="fas fa-star"></i>
              </span>
              <span className="mr-1 text-warning">
                <i className="fas fa-star"></i>
              </span>
              <span className="mr-1 text-warning">
                <i className="fas fa-star"></i>
              </span>
              <span className="mr-1 text-warning">
                <i className="fas fa-star"></i>
              </span>
              <span className="mr-1 text-warning">
                <i className="fas fa-star"></i>
              </span>
            </div>
            <div className="card-title fw-medium">Best Quality</div>
            <div className="card-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et
            </div>
            <div className="d-flex align-items-center mt-3">
              <div className="user sprite-icon size-50px me-1">
                <img
                  className="img-fluid rounded-circle w-100"
                  src="images/icons/user.jpg"
                  alt=""
                />
              </div>
              <div className="text-light-gray">
                <div className="mb-1">Gaurav Kumar</div>
                <div>23 April 2021</div>
              </div>
            </div>
          </div>
          <div className="reviews-card py-4">
            <div className="text-light-success mb-2">
              <span className="mr-1 text-warning">
                <i className="fas fa-star"></i>
              </span>
              <span className="mr-1 text-warning">
                <i className="fas fa-star"></i>
              </span>
              <span className="mr-1 text-warning">
                <i className="fas fa-star"></i>
              </span>
              <span className="mr-1 text-warning">
                <i className="fas fa-star"></i>
              </span>
              <span className="mr-1 text-warning">
                <i className="fas fa-star"></i>
              </span>
            </div>
            <div className="card-title fw-medium">Best Quality</div>
            <div className="card-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et
            </div>
            <div className="d-flex align-items-center mt-3">
              <div className="user sprite-icon size-50px me-1">
                <img
                  className="img-fluid rounded-circle w-100"
                  src="images/icons/user.jpg"
                  alt=""
                />
              </div>
              <div className="text-light-gray">
                <div className="mb-1">Gaurav Kumar</div>
                <div>23 April 2021</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Reviews;
